<template>
    <div class="pop-up-container" v-show="open">
        <div>
            <div class="content-wrapper">
                <div class="header">
                    <label v-if="title">{{ title }}</label>
                    <button class="button passive" @click="handleCancel">
                        <fa-icon icon="fa-solid fa-xmark"/>
                    </button>
                </div>

                <div class="content">
                    <slot>CONTENT</slot>
                </div>

                <slot name="actions">
                    <div class="actions">
                        <button v-if="showSubmit" class="button highlight" @click="handleSubmit" :disabled="intervalCounter || disabledSubmit">
                            <label>{{ intervalCounter ? intervalCounter : submitLabel }}</label>
                        </button>
                        <button v-if="showCancel" class="button" @click="handleCancel">
                            <label>{{ cancelLabel }}</label>
                        </button>
                    </div>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
const EMIT_CLOSE = 'close'

export default {
    name: 'pop-up',
    emits: [ EMIT_CLOSE ],
    data() {
        return {
            intervalId: 0,
            intervalCounter: 0
        }
    },
    props: {
        open: {
            type: Boolean,
            required: true,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        submitLabel: {
            type: String,
            default: 'Submit'
        },
        submitCountdown: {
            type: Number,
            default: 0
        },
        disabledSubmit: {
            type: Boolean,
            required: false,
            default: false,
        },
        cancelLabel: {
            type: String,
            default: 'Cancel'
        },
        showSubmit:{
            type:Boolean,
            default:true
        },
        showCancel:{
            type: Boolean,
            default: false
        }
    },
    watch: {
        open: function (newVal) {
            if (newVal && this.submitCountdown) {
                this.intervalCounter = this.submitCountdown
                this.intervalId = setInterval(() => {
                    this.intervalCounter--
                    if (this.intervalCounter <= 0) {
                        clearInterval(this.intervalId)
                    }
                }, 1000)
            }
        }
    },
    methods: {
        handleSubmit() {
            this.$emit(EMIT_CLOSE, true)
        },
        handleCancel() {
            if (this.intervalId) {
                clearInterval(this.intervalId)
            }
            this.$emit(EMIT_CLOSE, false)
        }
    }

}
</script>

<style lang="scss" scoped>
.pop-up-container {
    z-index: 50;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000aa;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;

    .content-wrapper {
        background: #fff;
        border-radius: 8px;
        padding: 16px;

        > .header {
            width: 100%;
            display: flex;
            align-items: center;
            height: 30px;

            > label {
                font-size: 1.2em;
                font-weight: bold;
            }

            > button {
                height: 60%;
                max-width: 30px;
                margin-left: auto;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        > .content {
            margin-top: 20px;
        }

        > .actions {
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;

            > button {
                margin-left: 8px;
            }
        }
    }
}
</style>