<template>
    <div class="change-calculator-container">
        <div class="calculator-container">
            <h2>Bitte geben Sie den erhaltenen Betrag ein:</h2>

            <div class="calculator">
                <TextInput input-id="cash-input" class="cash-input" v-model="givenInput" @focus="$event.target.select()"/>
                <div class="row">
                    <button @click="addInputToGivenCash(7)">7</button>
                    <button @click="addInputToGivenCash(8)">8</button>
                    <button @click="addInputToGivenCash(9)">9</button>
                </div>
                <div class="row">
                    <button @click="addInputToGivenCash(4)">4</button>
                    <button @click="addInputToGivenCash(5)">5</button>
                    <button @click="addInputToGivenCash(6)">6</button>
                </div>
                <div class="row">
                    <button @click="addInputToGivenCash(1)">1</button>
                    <button @click="addInputToGivenCash(2)">2</button>
                    <button @click="addInputToGivenCash(3)">3</button>
                </div>
                <div class="row">
                    <button @click="removeInputFromGivenCash">Entf</button>
                    <button @click="addInputToGivenCash(0)">0</button>
                    <button @click="addInputToGivenCash(',')">,</button>
                </div>
            </div>

            <h2 class="calculator-change">
                Rückgeld: <span class="change-value">{{ changeDisplay }} €</span>
            </h2>
        </div>
    </div>
</template>

<script>
import TextInput from './elements/TextInput.vue'

const EMIT_VALID = 'valid'

export default {
    name: 'change-calculator',
    inject: [ 'util' ],
    props: {
        sumInCents: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            givenInput: '',
            change: 0
        }
    },
    mounted() {
        this.givenInput = this.util.formatCurrency(this.sumInCents)
        this.initInput()
    },
    computed: {
        changeDisplay() {
            const displayValue = this.changeInCents >= 0 ? this.changeInCents : 0
            return this.util.formatCurrency(displayValue)
        },
        changeInCents() {
            return this.givenInCents - this.sumInCents
        },
        inputEl() {
            return document.getElementById('input-cash-input')
        },
        givenInCents() {
            const valueWithDecimalPoint = this.givenInput.replace(',', '.')
            return (+valueWithDecimalPoint).toFixed(2) * 100
        }
    },
    methods: {
        initInput() {
            this.inputEl.addEventListener('keyup', (event) => {
                const key = event.key

                // allowed keys other than numbers and comma
                if (['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(key)) return

                // states to prevent
                const alreadyWithDelimiter = key === ',' && this.givenInput.includes(',')
                const delimiterOnly        = key === ',' && this.givenInput.trim() === ''
                const match                = event.key.match(/[0-9,]/g)

                if (!match || alreadyWithDelimiter || delimiterOnly) {
                    event.preventDefault()
                }
            })

            this.$nextTick(() => this.inputEl.select())
        },
        addInputToGivenCash(char) {
            this.givenInput += char
        },
        removeInputFromGivenCash() {
            this.givenInput = this.givenInput.slice(0, -1)
        }
    },
    watch: {
        changeInCents(newValue) {
            this.$emit(EMIT_VALID, newValue >= 0)
        },
        sumInCents(newValue) {
            this.givenInput = this.util.formatCurrency(newValue)
            this.$emit(EMIT_VALID, newValue >= 0)
        }
    },
    components: {
        TextInput
    }
}
</script>

<style lang="scss" scoped>
.change-calculator-container {
    background-color: #fff;
    // box-shadow: 0 2px 8px #00000022;
    border-radius: 8px;

    > .calculator-header {
        display: block;
        width: 100%;
        font-size: 2em;
    }

    > .calculator-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > h2 {
            font-weight: normal;
        }

        > .calculator {
            width: 250px;
            margin-bottom: 10px;

            > .cash-input {
                font-size: 1.5em;
                :deep(input) {
                    text-align: center;
                }
            }

            > .row {
                font-size: 1.5em;
                width: 100%;
                height: 60px;
                display: flex;
                justify-content: space-between;

                > button {
                    font-weight: bold;
                    width: 100%;
                    height: 100%;
                    border: 2px solid rgba(0, 0, 0, 0.1333333333);
                }

                > .calculator-label {
                    display: block;
                    width: 100%;
                    padding-top: 5px;
                }
            }
        }

        > .calculator-change {
            margin-top: 20px;
            font-weight: normal;

            > .change-value {
                font-weight: bold;
            }
        }
    }
}
</style>