<template>
    <div>
        <div class="logo-container">
        <div class="logo">
                <img src="@img/shuttle-logo.svg" />
            </div>
        </div>

        <div class="header-container">
            <nav id="nav" v-show="session.hasOpenShift">
                <router-link :to="route.path" v-show="isAllowed(route.meta.rights)" v-for="route in pageList" :key="route.path">{{ route.meta.display || `NO-DISPLAY:
                    ${route.name}` }}</router-link>
            </nav>

            <div class="vehicle">
                <div class="dimensions" v-show="session.hasOpenShift">
                    <label>Höhe: {{ vehicle?.dimensions?.height }} {{vehicle?.dimensions ? 'cm':''}}</label>
                    <label>Länge: {{ vehicle?.dimensions?.length }} {{vehicle?.dimensions ? 'cm':''}}</label>
                    <label>Typ: {{ vehicle?.dimensions?.vehicleType }}</label>
                </div>
            </div>

            <div class="license-plate-container" v-show="session.hasOpenShift">
                <license-plate-display class="license-plate" :content="hwsLicensePlate" :inputOption="true" @plateInput="plate => handleLicensePlateInput(plate)"/>
                <div class="recent-plates">
                    <fa-icon icon="fa-solid fa-angle-down" @click="openRecentLicensePlates"/>
                    <ul class="option-list" v-show="showRecentLicensePlates" @click="$event.stopPropagation()">
                        <li class="option" @click="handleLicensePlateSelection(plate)" v-for="(plate, idx) in recentLicensePlates" :key="idx">{{ plate }}</li>
                    </ul>
                </div>
            </div>

            <button class="highlight cart" @click="goToCart" v-show="session.hasOpenShift">
                <label>{{ this.util.formatCurrency(tweenedTotal) }} €</label>
                <fa-icon icon="fa-solid fa-cart-shopping"/>
                <transition name="update-amount">
                    <div class="amount" v-if="cartTotalAmount > 0" :key="cartTotalAmount">
                        <label>{{ cartTotalAmount }}</label>
                    </div>
                </transition>
            </button>

            <div class="account" v-show="session.hasOpenShift">
                <div class="account-container" @click="toggleAccountDropdown">
                    <fa-icon icon="fa-regular fa-id-badge"/>
                    <label>{{ userName }}</label>
                    <fa-icon icon="fa-solid fa-angle-down"/>
                </div>
                <div class="dropdown-container" v-show="accountDropdownOpen" @click="$event.stopPropagation()">
                    <label @click="logout">Abmelden</label>
                    <label v-if="systemInfo.show" @click="showInfoPopup = true">Info</label>
                </div>
            </div>
            <div class="shift">
                <div class="information" v-show="session.hasOpenShift">
                    <label class="shift-nr">Schicht: {{ shift.id }}</label>
                    <label class="workstation">{{ locationName }} / {{ deviceName }}</label>
                    <label>Personalnummer {{ shift.staffNr }}</label>
                </div>
            </div>

            <div class="hwsStatus" v-show="session.hasOpenShift">
                <label>Verkaufssystem:</label>
                <label v-if="this.hwsData.status == 0" style="color: green;">Verbunden</label>
                <label v-if="this.hwsData.status == 1" style="color: rgb(252, 214, 0);">Wird verbunden</label>
                <label v-if="this.hwsData.status == 2" style="color: red;">Nicht verbunden</label>
            </div>
        </div>
    </div>
    <PopUp class="info-popup" :open="showInfoPopup" title="Info" @close="showInfoPopup = false" :showSubmit="false" :showCancel="false">
        <p class="version-text">Frontend Version: {{ applicationVersion }}</p>
        <p v-if="middlewareVersion" class="version-text">Middleware Version: {{ middlewareVersion }}</p>
    </PopUp>
</template>

<script>
import gsap from 'gsap'
import axios from 'axios'
import LicensePlateDisplay from './elements/license-plate-display/LicensePlateDisplay.vue'
import PopUp from '@elements/PopUp.vue'

export default {
    name: 'navigation-header',
    inject: ['keycloak', 'util', 'session', 'hws'],
    props: {
        systemInfo: {
            type: Object
        },
    },
    data() {
        return {
            accountDropdownOpen: false,
            tweenedTotal: 0,
            showRecentLicensePlates: false,
            middlewareVersion: undefined,
            showInfoPopup: false,
        }
    },
    mounted() {
        if(this.systemInfo.show===true){
            this.setMiddlewareVersion()
        }
    },
    computed: {
        applicationVersion () {
            return this.util.version()
        },
        kc() {
            return this.keycloak?.instance
        },
        hwsData() {
            return this.hws.data;
        },
        shift() {
            return this.session.shift;
        },
        locationName() {
            return this.shift.location?.displayname;
        },
        deviceName() {
            return this.shift.device?.displayname;
        },
        pageList() {
            return this.$router.getRoutes().filter(route => {
                if (route.meta.nav instanceof Function) {
                    return route.meta.nav()
                }
                return route.meta.nav
            })
        },
        userName() {
            const keycloakUser = this.keycloak.instance.tokenParsed;
            const email = keycloakUser.email;
            const name = keycloakUser.given_name && keycloakUser.family_name ? `${keycloakUser.given_name || ''} ${keycloakUser.family_name || ''}` : undefined;
            return name || email;
        },
        cartTotal() {
            const cartItems = this.session.shoppingCart
            let totalSum = 0

            if (cartItems) {
                cartItems.forEach(item => {
                    totalSum += item.price * item.amount
                })
            }

            return totalSum
        },
        cartTotalAmount() {
            let totalAmount = 0;
            const amountList = this.session.shoppingCart.map(entry => entry.amount);
            if (amountList && amountList.length > 0) {
                totalAmount = amountList.reduce((a, b) => a + b);
            }
            return totalAmount;
        },
        hwsLicensePlate: {
            get() {
                return this.hwsData.licensePlate
            },
            set(value) {
                this.hwsData.licensePlate = value
            }
        },
        recentLicensePlates() {
            const plates = this.hws.data.lastLicensePlates
            return plates.filter((plate, index) => plates.indexOf(plate) === index).slice(-5).reverse()
        },
        vehicle() {
            return this.hws.data.lastVehicles.find(v=>v.licensePlate===this.hwsLicensePlate)
        }
    },
    methods: {
        setMiddlewareVersion(){
            let url = this.util.middleware()
            url += '/info/version'
            return axios.get(url)
                .then(response => {
                    this.middlewareVersion = response.data
                })
                .catch(err => {
                    this.middlewareVersion = undefined;
                    this.$toast.error(err.response?.data?.userMessage || err.message)
                })
        },
        isAllowed(listOfRights) {
            if (!listOfRights) return true
            return this.kc.permissions.find(permission => listOfRights.includes(permission))
        },
        openRecentLicensePlates(event) {
            if (this.showRecentLicensePlates) {
                return this.closeRecentLicensePlates()
            }

            if (event) {
                event.stopPropagation()
            }

            // register click-outside event on body
            document.addEventListener('click', () => {
                this.closeRecentLicensePlates()
            })

            this.showRecentLicensePlates = true
        },
        goToCart() {
            this.$router.push({ name: 'shopping-cart' })
        },
        toggleAccountDropdown(event) {
            if (this.accountDropdownOpen) {
                return this.closeAccountDropdown()
            }
            if (event) {
                event.stopPropagation();
            }
            // register click-outside event on body
            document.addEventListener('click', () => {
                this.closeAccountDropdown()
            })
            this.accountDropdownOpen = true;
        },
        closeAccountDropdown() {
            document.removeEventListener('click', null);
            this.accountDropdownOpen = false;
        },
        closeRecentLicensePlates() {
            document.removeEventListener('click', null);
            this.showRecentLicensePlates = false;
        },
        logout() {
            this.keycloak.instance.logout();
        },
        handleLicensePlateSelection(plate) {
            this.closeRecentLicensePlates()
            this.hwsLicensePlate = plate
        },
        handleLicensePlateInput(plate) {
            this.hwsLicensePlate = plate
        }
    },
    watch: {
        cartTotal: {
            handler: function (total) {
                gsap.to(this, { duration: 0.5, tweenedTotal: total || 0 });
            },
            immediate: true
        },
        hwsLicensePlate() {
            this.recentLicensePlates.pop
        }
    },
    components: { LicensePlateDisplay, PopUp }
}
</script>

<style lang="scss" scoped>

.logo-container {
    position: relative;
    height: 50px;
    width: 100%;
    display: flex;
    background: #fff;

    > .logo {
        height: 100%;
        background-color: #fff;

        > img {
            height: 100%;
            padding: 10px;
            object-fit: contain;
        }
    }
}

.header-container {
    position: relative;
    height: 50px;
    width: 100%;
    display: flex;
    background: #f01414;
    box-shadow: 0 2px 8px #00000022;

    > #nav {
        height: 100%;
        font-size: 1.25em;
        display: flex;
        margin-left: 20px;
        margin-right: auto;

        &:deep(a) {
            text-decoration: none;
            color: #fff;
        }

        > a {
            display: flex;
            align-items: center;
            height: 100%;
            margin: 0 8px;
            padding: 0 16px;

            &.router-link-exact-active {
                padding-top: 4px;
                font-weight: bold;
                border-bottom: 4px solid #fff;
            }

            &:hover:not(.router-link-exact-active) {
                padding-top: 4px;
                border-bottom: 4px solid #fff;
            }
        }
    }

    > .license-plate-container {
        margin-right: 12px;
        display: flex;
        align-items: center;

        > .license-plate {
            &:hover {
                &,
                :deep(*) {
                    cursor: pointer;
                }
            }
        }

        > .recent-plates {
            margin-left: 8px;
            display: flex;
            align-items: center;
            position: relative;

            > svg {
                color: #fff;
                height: 24px;

                &:hover {
                    cursor: pointer;
                }
            }

            > .option-list {
                position: absolute;
                top: 37px;
                right: 0;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                margin: 0;
                background: #fff;
                width: fit-content;
                min-width: 200px;
                box-sizing: border-box;
                padding: 0;
                list-style-type: none;
                overflow: hidden;
                box-shadow: 0 2px 8px #00000022;
                z-index: 10;

                > .option {
                    text-align: left;
                    text-decoration: none;
                    padding: 10px 0;
                    padding-left: 10px;
                    user-select: none;

                    &:hover {
                        background: rgba(0, 0, 0, 0.2);
                        cursor: pointer;
                    }
                }
            }
        }
    }

    > .cart {
        margin-right: 12px;
        color: #fff;
        position: relative;
        font-size: 1.5em;

        > .amount {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -6px;
            right: -6px;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background-color: #63a615;
            padding: 4px;
            font-weight: bold;
            font-size: 0.8em;
            color: #fff;
        }

        > svg {
            height: 70%;
        }
    }

    > .account {
        position: relative;

        > .account-container {
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 8px;
            border-left: 2px solid #ddd;
            color: #fff;

            &:hover {
                background-color: #00000022;

                &,
                :deep(*) {
                    cursor: pointer;
                }
            }

            > label {
                margin: 0 8px;
            }

            > svg {
                width: 15px;

                &.dropdown {
                    width: 12px;
                }
            }
        }

        > .dropdown-container {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #fff;
            box-shadow: 0 4px 8px #00000022;
            min-width: 170px;

            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;

            top: 50px;
            left: 0;
            z-index: 10;

            > label {
                padding: 12px 0;
                width: 100%;

                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;

                &:hover {
                    background: rgba(0, 0, 0, 0.2);
                    cursor: pointer;
                }
            }

            > .logout {
                font-weight: bold;
                background-color: red;

                &:hover {
                    background-color: #ff000099;
                }
            }
        }
    }

    .vehicle {
        display: flex;
        align-items: center;
        padding: 0 12px;
        color: #fff;

        > .dimensions {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-right: 8px;
        }
    }

    .shift {
        display: flex;
        align-items: center;
        padding: 0 8px;
        color: #fff;

        > .information {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-right: 8px;
        }

        > svg {
            height: 40%;
        }
    }

    > .hwsStatus {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-left: 2px solid #ddd;
        width: 120px;
        background-color: #fff;

        > label {
            font-weight: bold;
        }
    }
}

.info-popup {
     .version-text {
        font-size: 1.4em;
    }
}


// vue transitions
.update-amount-enter-active,
.update-amount-leave-active {
    transition: transform 0.3s ease;
}

.update-amount-enter-from {
    transform: scale(1.5);
}

.update-amount-leave-to {
    transform: scale(0.5);
}
</style>