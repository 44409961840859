<template>
    <div class="shopping-cart-container" >
        <div class="shopping-cart-wrapper">
            <div class="customer-container">
                <div v-if="!withCustomer && !mixedDevaluation" class="customer-wrapper no-customer">
                    <div class="header">
                        <label class="title">Kunde</label>
                    </div>

                    <div class="options">
                        <div class="search-customer">
                            <a class="action" @click="handleSearchCustomer">
                                <fa-icon icon="fa-solid fa-search"/>
                                <label>Kunde suchen</label>
                            </a>
                        </div>

                        <div class="add-customer">
                            <a class="action" @click="handleCreateCustomer">
                                <fa-icon icon="fa-solid fa-plus"/>
                                <label>Kunde neu anlegen</label>
                            </a>
                        </div>
                    </div>
                </div>
                <div v-if="withCustomer && !mixedDevaluation" class="customer-wrapper">
                    <CustomerDisplay :customer="this.globalState.customer" @close="handleCloseCustomer" @save="this.globalState.customer = $event"/>
                </div>
            </div>

            <div>
                <div class="product-container">
                    <div class="tickets-container">
                        <div class="ticket" v-for="(product, idx) in products" :key="idx">
                            <svg class="separator">
                                <line x1="6px" y1="2" x2="100%" y2="2" stroke-dasharray="16,16"></line>
                            </svg>

                            <div class="content-wrapper">
                                <div class="left">
                                    <div class="main">
                                        <div class="title">
                                            <label class="name">{{ product.productName }} <span class="immediate-use" v-if="product.immediateUse">DIREKTE ENTWERTUNG</span></label>
                                            <label>{{ product.vehicleType }}</label>
                                            <label>{{ product.befoerderter }}</label>
                                            <div class="connection" v-if="product.direction.from || product.direction.to">
                                                <label v-if="product.direction.from" class="from"><span>Von</span> {{ product.direction.from }}</label>
                                                <label v-if="product.direction.to" class="to"><span>Nach</span> {{ product.direction.to }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="info">
                                        <label>{{ product.info }}</label>
                                    </div>
                                </div>
                                <div class="right">
                                    <div class="remove">
                                        <button @click="removeCartItem(product)">
                                            <fa-icon icon="fa-regular fa-trash-can"/>
                                            <!-- <label>Berechtigung Entfernen</label> -->
                                        </button>
                                    </div>

                                    <div class="price-info">
                                        <label><span>{{ product.amount }}x</span> {{ getItemPriceDisplay(product) }}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="price">
                                <label>{{ util.formatCurrency(product.price * product.amount) }} €</label>
                            </div>
                        </div>
                    </div>

                    <div class="price-container">
                        <label>Gesamtpreis</label>
                        <label>{{ util.formatCurrency(tweenedTotal) }} €</label>
                    </div>
                </div>
            </div>

            <div class="payment-container">
                <div class="payment-wrapper" v-if="!mixedDevaluation">
                    <div class="payment-content">
                        <div class="payment-method-selector-wrapper">
                            <input type="radio" id="direct-payment" name="payment-type" :value="PAYMENT_METHOD.CASH" v-model="selectedPaymentMethod"/>
                            <label for="direct-payment" :class="{ 'selected' : selectedPaymentMethod === PAYMENT_METHOD.CASH }">
                                <fa-icon icon="fa-solid fa-money-bill-1-wave" />
                                <span>Barzahlung</span>
                            </label>

                            <input type="radio" id="ec-payment" name="payment-type" :value="PAYMENT_METHOD.EC" v-model="selectedPaymentMethod"/>
                            <label for="ec-payment" :class="{ 'selected' : selectedPaymentMethod === PAYMENT_METHOD.EC }">
                                <fa-icon icon="fa-solid fa-credit-card" />
                                <span>Karte</span>
                            </label>

                            <input :disabled="!savedMethodAllowed" type="radio" id="saved-payment" name="payment-type" :value="PAYMENT_METHOD.SAVED" v-model="selectedPaymentMethod"/>
                            <label title="Im Kunden hinterlegte Bezahlart. Nur verfügabr wenn ein Kunde entweder 'Lastschrift' oder 'Rechnung' als Bezahlart gesetzt hat." :disabled="!savedMethodAllowed" for="saved-payment" :class="{ 'selected' : selectedPaymentMethod === PAYMENT_METHOD.SAVED }">
                                <fa-icon icon="fa-solid fa-receipt" />
                                <span>{{ withCustomer ? getBackendPaymentMethodDisplay(this.customerBackendPaymentMethod) : 'Hinterlegte Bezahlart' }}</span>
                            </label>
                        </div>

                        <div class="cash-payment-container" v-if="selectedPaymentMethod === PAYMENT_METHOD.CASH">
                            <ChangeCalculator :sumInCents="paymentSum" @valid="cashPaymentValid = $event"/>
                            <CheckboxInput class="change-confirmation" v-model="confirmChange" text="Rückgeld gegeben"/>
                        </div>

                        <div class="ec-payment-container" v-if="selectedPaymentMethod === PAYMENT_METHOD.EC">
                            <label class="info">Bezahlung mit EC-Karte gestartet</label>
                            <label class="info">Bitte beachten Sie die Anweisungen auf dem EC-Terminal</label>

                            <label class="status">Aktueller Status:</label>
                            <h2>{{ hws.data.currentPaymentStatus }}</h2>
                        </div>

                        <div class="saved-payment-container" v-if="selectedPaymentMethod === PAYMENT_METHOD.SAVED">
                            <div class="saved-payment-wrapper">
                                <label>Im Kunden hinterlegte Bezahlart:</label>
                                <h2>{{ this.getBackendPaymentMethodDisplay(this.customerBackendPaymentMethod) }}</h2>
                                <label v-if="!hasIBAN" class="warning">nicht möglich, da keine IBAN hinterlegt ist</label>
                            </div>
                        </div>
                    </div>

                    <div class="actions">
                        <button class="highlight pay" v-if="selectedPaymentMethod !== PAYMENT_METHOD.EC" :disabled="!canPay" @click="pay()">
                            <fa-icon icon="fa-regular fa-money-bill-1"/>
                            <label>Bezahlen</label>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <PopUp :open="showSearch" title="Kundensuche" @close="showSearch = false">
            <CustomerSearch ref="customerSearch" @result="handleCustomerSearchResult"/>

            <template v-slot:actions>
                <!-- no buttons placeholder -->
                <label></label>
            </template>
        </PopUp>
    </div>
</template>

<script>
import PopUp from '@elements/PopUp.vue'
import CheckboxInput from '@components/elements/CheckboxInput.vue'
import CustomerSearch from '@components/CustomerSearch.vue'
import CustomerDisplay from '@components/CustomerDisplay.vue'
import { getBankAccountHolder } from '@lib/middleware-connector'
import gsap from 'gsap'
import axios from 'axios'
import ChangeCalculator from '@components/ChangeCalculator.vue'


const PAYMENT_METHOD = {
    CASH:  0,
    EC:    1,
    SAVED: 2
}

const BACKEND_PAYMENT_METHOD = {
    TERMINAL:     11,
    CASH:         22,
    INVOICE:      25,
    DIRECT_DEBIT: 26
}

const TOWS_PAYMENT_METHODS = {
    UNDEFINED:                 'UNDEFINED',
    DIRECT_DEBIT:              'DIRECT_DEBIT',
    INVOICE:                   'INVOICE',
    CASH:                      'CASH',
    EXTERNAL_SERVICE_PROVIDER: 'EXTERNAL_SERVICE_PROVIDER'
}

export default {
    name: "shopping-cart-page",
    inject: [ 'hws', 'util', 'session', 'globalState' ],
    data() {
        return {
            PAYMENT_METHOD,
            BACKEND_PAYMENT_METHOD,
            TOWS_PAYMENT_METHODS,
            withCustomer: false,
            showSearch: false,
            paymentSum: 0,
            paymentData: undefined,
            tweenedTotal: 0,
            selectedPaymentMethod: 0,
            cashPaymentValid: true,
            confirmChange: false,
            hasIBAN: false,
        }
    },
    mounted() {
        if (!this.products || this.products.length === 0) {
            // go back if there are no cart items
            this.$router.push('/')
        }

        if (this.mixedDevaluation) {
            this.$toast.error('Es kann nur ein Ticket mit direkter Entwertung gleichzeitig gekauft werden')
        }

        if (this.globalState.customer) {
            this.withCustomer = true
        }

        this.handleCustomerPaymentMethod(this.globalState.customer)
    },
    computed: {
        mixedDevaluation() {
            // we only allow to buy exactly one ticket with immediate usage!
            const items = this.session.shoppingCart
            const itemCount = items.length
            const itemCountWithDevaluation = items.filter(entry => entry.immediateUse).length
            if ((itemCount > 1) && (itemCountWithDevaluation > 0)) {
                return itemCountWithDevaluation !== itemCount
            } else {
                return false
            }

        },
        savedMethodAllowed() {
            // saved method is only allowed if there is a customer and the customer
            // has either invoice or direct-debit configured
            const paymentMethodAllowed = [ BACKEND_PAYMENT_METHOD.DIRECT_DEBIT, BACKEND_PAYMENT_METHOD.INVOICE ].includes(this.customerBackendPaymentMethod)
            return this.withCustomer && paymentMethodAllowed
        },
        customerBackendPaymentMethod() {
            switch(this.globalState.customer?.paymentMethod) {
                case TOWS_PAYMENT_METHODS.DIRECT_DEBIT:
                    return BACKEND_PAYMENT_METHOD.DIRECT_DEBIT
                case TOWS_PAYMENT_METHODS.INVOICE:
                    return BACKEND_PAYMENT_METHOD.INVOICE
                case TOWS_PAYMENT_METHODS.CASH:
                    return BACKEND_PAYMENT_METHOD.CASH
                case TOWS_PAYMENT_METHODS.EXTERNAL_SERVICE_PROVIDER:
                case TOWS_PAYMENT_METHODS.UNDEFINED:
                default:
                    return undefined
            }
        },
        paymentMethod() {
            switch(this.selectedPaymentMethod) {
                case PAYMENT_METHOD.CASH:
                    return BACKEND_PAYMENT_METHOD.CASH
                case PAYMENT_METHOD.EC:
                    return BACKEND_PAYMENT_METHOD.TERMINAL
                case PAYMENT_METHOD.SAVED:
                    return this.customerBackendPaymentMethod
                default:
                    return undefined
            }
        },
        canPay() {
            if (this.paymentMethod === BACKEND_PAYMENT_METHOD.CASH) {
                const emptyCart = this.session.shoppingCart.length > 0 ? false : true
                if ( emptyCart ) { return false }

                const total = this.session.shoppingCart.reduce((sum, item) => sum + item.price * item.amount, 0)
                const nothingToPay = total === 0 //handle numeric diffusion

                return this.cashPaymentValid && (this.confirmChange || nothingToPay)
            }
            if((this.paymentMethod === BACKEND_PAYMENT_METHOD.INVOICE) || (this.paymentMethod === BACKEND_PAYMENT_METHOD.DIRECT_DEBIT)){
                return this.hasIBAN
            }

            return true
        },
        hwsData() {
            return this.hws.data
        },
        fullPrice() {
            let result = 0

            this.products?.forEach(product => {
                result += parseInt(product.price * product.amount, 10)
            })

            return result
        },
        products() {
            return this.session.shoppingCart
        },
        shift() {
            return this.session.shift
        },
        locationId() {
            return this.shift.location?.displayid
        },
        deviceId() {
            return this.shift.device?.displayid
        },
        directionId() {
            return this.shift.location?.direction
        },
    },
    methods: {
        getItemPriceDisplay(product) {
            if (!product.saveSylt) {
                return `${this.util.formatCurrency(product.price)} €`
            } else {
                return `${this.util.formatCurrency(product.price - 100)} € \n( +1 € Syltschützer )`
            }
        },
        handleCustomerPaymentMethod(customer) {
            if (customer && this.savedMethodAllowed) {
                this.selectedPaymentMethod = PAYMENT_METHOD.SAVED
                this.checkIfIbanExists()
            } else {
                this.selectedPaymentMethod = PAYMENT_METHOD.CASH
            }
        },
        getBackendPaymentMethodDisplay(method) {
            switch(method) {
                case BACKEND_PAYMENT_METHOD.DIRECT_DEBIT:
                    return 'Lastschrift'
                case BACKEND_PAYMENT_METHOD.INVOICE:
                    return 'Rechnung'
                default:
                    return undefined
            }
        },
        async handlePrintTickets(backendPaymentMethod, items) {
            this.globalState.isLoading = true

            try {
                const tickets = await this.completeOrder(backendPaymentMethod, items)

                this.globalState.isLoading = false
                this.session.clearShoppingCart()

                tickets.forEach(async ticket => {
                    await this.hwsPrint(ticket, backendPaymentMethod)
                })

                if (tickets.length === 1 && items.find(item => item.immediateUse)) {
                    const ticketToDevalue = tickets[0]
                    this.createUsage(ticketToDevalue)
                    .then(() => {
                        this.$toast.info('Ticket entwertet: ' + ticketToDevalue.orderId)
                        // try to open the barrier
                        this.hws.sendActionCodeTicketOK()
                    })
                    .catch(err => {
                        if (err?.response?.data?.detail === 'could-not-create-usage') {
                            this.$toast.error(err.response.data.userMessage + ': ' + ticketToDevalue.orderId)
                        }
                    })
                }

                this.$router.push(this.$route.meta.parentPath)
            } catch (err) {
                this.globalState.isLoading = false
                this.$toast.error(err.response?.data?.userMessage || err.message)
            }
        },
        async createUsage(ticket) {
            let ticketUrl = this.util.middleware()
            ticketUrl += '/ticketing/ticket/' + ticket.orderId
            ticketUrl += '/usage/create'

            const body = {
                direction: this.directionId,
                deviceId: this.deviceId,
                outwardTrip: (ticket.direction === this.directionId)
            }

            return axios.post(ticketUrl, body)
        },
        async hwsPrint(ticket, backendPaymentMethod) {
            let ticketImageUrl = this.util.middleware()
            ticketImageUrl += '/ticketing/createTicketImage'

            let body = {
                ticket: ticket,
                payment: this.paymentData?.paymentData?.receipts[1]?.content || '',
                paymentMethod: this.util.formatPaymentMethodString(backendPaymentMethod),
                deviceId: this.deviceId
            }

            return axios.post(ticketImageUrl, body, { responseType: 'arraybuffer' })
            .then(response => {

                if (this.hws.data.status === 0 && process.env.NODE_ENV === 'production') {
                    const img = new Uint8Array(response.data)
                    return this.hws.printImage(img)
                } else {
                    // if HWS is not working or we are in dev - just show the image in the browser
                    const file    = new File([response.data], 'ticket' + ticket.orderId + '.png', {type: 'image/png'})
                    const url     = URL.createObjectURL(file)
                    window.open(url)
                }
            })
            .catch(err => {
                this.showPrintError = true
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async completeOrder(backendPaymentMethod, items) {
            let checkoutUrl = this.util.middleware()
            checkoutUrl += '/ticketing/checkout'

            let shoppingCart = []

            items.forEach(item => {
                for (let it = 0; it < item.amount; it++) {
                    shoppingCart.push(item)
                }
            })

            let body = {
                shoppingCart,
                anonymous:         !this.withCustomer,
                customerReference: this.globalState.customer?.customerReference,
                staffNr:           this.shift.staffNr,
                deviceId:          this.deviceId,
                locationId:        this.locationId,
                paymentMethod:     backendPaymentMethod
            }

            return axios.post(checkoutUrl, body)
            .then(response => {
                return response.data
            })
        },
        async initEcPayment() {
            return this.hws.initPayment(this.paymentSum)
            .then(data => {
                this.paymentData       = data
                const items = this.session.shoppingCart
                this.handlePrintTickets(BACKEND_PAYMENT_METHOD.TERMINAL, items)
            })
            .catch(err => {
                this.$toast.error(err.message || err.errorMessage || err)
            })
        },
        pay() {
            const items = this.session.shoppingCart
            if (this.selectedPaymentMethod === PAYMENT_METHOD.CASH) {
                this.handlePrintTickets(BACKEND_PAYMENT_METHOD.CASH, items)
            } else if (this.selectedPaymentMethod === PAYMENT_METHOD.SAVED) {
                this.handlePrintTickets(this.customerBackendPaymentMethod, items)
            }
        },
        cancelEcPayment() {
            this.hws.cancelCurrentPayment()
        },
        handleSearchCustomer() {
            this.showSearch = true
        },
        handleCreateCustomer() {
            this.withCustomer = true
        },
        handleCustomerSearchResult(customer) {
            Object.assign(this.globalState, {customer})
            this.showSearch = false
        },
        handleCloseCustomer() {
            this.withCustomer = false
            this.globalState.customer = undefined
        },
        removeCartItem(item) {
            this.session.removeFromShoppingCart(item)
        },
        async checkIfIbanExists(){
            if(!this.withCustomer){
                this.hasIBAN = false
                return
            }
            const bankAccountHolder = await this.loadBankAccountHolder()
            this.hasIBAN = bankAccountHolder.iban

        },
        async loadBankAccountHolder(){
            return await getBankAccountHolder(this.globalState.customer?.uuidBankAccountHolder)
                .catch(err => {
                    this.$toast.error(err.response?.data?.userMessage || err.message)
                    return null
                })
        },
    },
    watch: {
        selectedPaymentMethod(newValue) {
            if (newValue === PAYMENT_METHOD.EC) {
                this.initEcPayment()
            } else {
                this.cancelEcPayment()
            }
        },
        fullPrice: {
            handler: function(amount) {
                gsap.to(this, { duration: 0.5, tweenedTotal: amount || 0 })
                this.paymentSum = amount
            },
            immediate: true
        },
        'globalState.customer'(customer) {
            this.withCustomer = !!this.globalState.customer
            this.handleCustomerPaymentMethod(customer)
        },
    },
    components: {
        PopUp,
        CustomerDisplay,
        CustomerSearch,
        ChangeCalculator,
        CheckboxInput
    }
}
</script>

<style lang="scss" scoped>

.shopping-cart-container {
    width: 100%;

    > .shopping-cart-wrapper {
        height: 100%;
        display: flex;
        justify-content: space-between;

        > div {
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            max-height: 100%;
            padding: 0 8px;

            &.payment-container,
            &.customer-container {
                max-width: 520px;
            }

            > div {
                background-color: #fff;
                border-radius: 8px;
                box-shadow: 0 2px 8px #00000022;
                height: 100%;
                padding: 16px;
            }
        }
    }
}

.payment-wrapper {
    display: flex;
    flex-direction: column;

    > .payment-content {
        flex: 1;
        display: flex;
        flex-direction: column;

        > .payment-method-selector-wrapper {
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            > label {
                flex: 1;
                margin: 0 4px;
                box-shadow: 0 2px 8px #00000022;
                padding: 8px 4px;
                border-radius: 8px;
                width: 120px;
                height: 65px;
                display: flex;
                // flex-direction: column;
                justify-content: center;
                align-items: center;

                &.selected {
                    background-color: #63a615;
                    color: #fff;
                }

                &:hover:not([disabled="true"]) {
                    &:not(.selected) {
                        background-color: #00000022;
                    }

                    &, :deep(*) {
                        cursor: pointer;
                    }
                }

                &[disabled="true"] {
                    opacity: 0.5;
                }

                > svg {
                    height: 20px;
                }

                > span {
                    margin-left: 8px;
                    font-size: 1.5em;
                    font-weight: bold;
                }
            }

            > input {
                display: none;
            }
        }

        > .cash-payment-container {
            margin-top: 20px;

            > .change-confirmation {
                width: 50%;
                margin: 0 auto;
            }
        }

        > .ec-payment-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            > .info,
            > .status {
                font-size: 1.5em;

                &.status {
                    margin-top: 20px;
                }
            }

            > h2 {
                margin: 0;
            }
        }

        > .saved-payment-container {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            > .saved-payment-wrapper {
                font-size: 1.5em;
                > .warning {
                    color: red;
                }
            }
        }
    }

    > .actions {
        > .pay {
            width: 100%;
            height: 50px;
            margin: 0;
        }
    }
}

.customer-wrapper {
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 0 !important;

    &.no-customer {
        padding: 16px !important;
    }

    > .header {
        padding-bottom: 0;
        display: flex;

        > .title {
            margin: 8px;
            font-weight: bold;
            font-size: 2em;
        }
    }

    > .actions {
        margin-top: auto;
        display: flex;
        justify-content: flex-end;

        > button {
            margin-left: 16px;
        }
    }

    > .options {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > .search-customer,
        > .add-customer {
            width: 75%;
            height: 65px;
            margin: 0 auto;
            margin-top: 20px;
            box-shadow: 0 2px 8px #00000022;
            border-radius: 8px;

            &.search-customer {
                color: #fff;
                //background: #26a;
                background: #ec0016;
            }

            &:hover {
                &,
                * {
                    cursor: pointer;
                }

                background-color: #00000022;

                &.search-customer {
                    //background-color: #2266aacc;
                    background-color: #5a6268;
                }

            }

            > .action {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                > svg {
                    height: 30px;
                    width: 30px;
                }

                > label {
                    font-size: 1.5em;
                    font-weight: bold;
                    margin-left: 12px;
                }
            }
        }
    }
}

.product-container {
    display: flex;
    flex-direction: column;

    > .tickets-container {
        border-radius: 8px;
        overflow: hidden;
        overflow-y: auto;
        padding-bottom: 12px;

        > .ticket {
            position: relative;
            padding: 16px;
            background-color: #fff;
            box-shadow: 0 2px 8px #00000022;
            border-radius: 8px;

            &:not(:first-of-type) {
                > .separator {
                    display: block;
                }

                &::before,
                &::after {
                    position: absolute;
                    border-radius: 15px;
                    content: '';
                    width: 30px;
                    height: 30px;
                    top: -15px;
                    background-color: #fff;
                    box-shadow: inset 0 0 4px #00000022;
                }

                &::before {
                    left: -15px;
                    clip-path: inset(0 0 0 15px);
                }

                &::after {
                    right: -15px;
                    clip-path: inset(0 15px 0 0);
                }
            }

            > .separator {
                display: none;
                position: absolute;
                top: 0;
                width: 98%;
                height: 2px;
                stroke-width: 4;
                stroke: #ddd;
            }

            > .content-wrapper {
                display: flex;
                padding: 0 4px;

                > .left {
                    flex: 4;

                    > .main {
                        margin-top: 8px;
                        display: flex;

                        > .title {
                            text-align: left;

                            > label:first-of-type {
                                font-size: 2em;
                                display: block;
                            }

                            > label:not(:first-of-type) {
                                margin-right: 4px;
                                font-size: 1.2em;
                                color: #666;
                            }

                            > .name {
                                display: flex !important;
                                align-items: center;

                                > .immediate-use {
                                    margin-left: 8px;
                                    color: red;
                                    font-size: 16px;
                                    font-weight: bold;
                                }
                            }

                            > .connection {
                                margin-top: 8px;
                                padding-left: 20px;
                                font-size: 1.5em;
                                display: flex;

                                > label {
                                    display: block;

                                    &:first-of-type {
                                        margin-right: 8px;
                                    }

                                    >span {
                                        font-size: 0.8em;
                                        color: #666;
                                    }
                                }
                            }
                        }
                    }

                    > .info {
                        margin-top: 12px;
                        text-align: left;

                        > label {
                            display: block;
                        }
                    }
                }

                > .right {
                    flex: 2;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    > .remove {
                        margin-left: auto;

                        > svg {
                            height: 25px;
                            width: 25px;
                        }
                    }

                    > .price-info {
                        margin-top: auto;
                        width: 100%;
                        text-align: right;
                        font-size: 1.5em;
                        font-weight: bold;
                        text-align: right;

                        > label {
                            white-space: pre-wrap;

                            > span {
                                font-weight: normal;
                                color: #666;
                                font-size: 0.9em;
                            }
                        }
                    }
                }
            }
            > .price {
                margin-top: 8px;
                padding: 2px 4px;
                border-top: 3px double #012345;
                font-size: 1.5em;
                font-weight: bold;
                text-align: right;
            }
        }
    }

    > .price-container {
        border-top: 6px double #012345;
        padding: 8px 4px;
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        font-size: 2em;
        font-weight: bold;
    }

    > .actions {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;

        > button {
            margin-left: 16px;
        }
    }
}

</style>