<template>
    <div class="input-wrapper" :class="[{ 'error':this.error }, {'display':this.display}]">
        <DatePicker :min-date="minDate" :prevent-min-max-navigation="preventMinMaxNavigation" @update-month-year="updateMonthYear" :allowed-dates="allowedDates" :enable-time-picker="false" :format="dateFormat" :auto-apply="true" :clearable="clearable" :readonly="display" autocomplete="off" :placeholder="placeholder" :disabled="disabled" id="computedId" :class="{ 'edited' : edited }" v-model="value"/>

        <label :class="{ 'float' : modelValue }" class="input-label" v-if="label" :style="backgroundColor ? 'background-color: ' + backgroundColor + ' !important;' : ''">{{ this.label }} {{ required ? '*' : '' }}</label>

        <div class="error-container">
            <label class="error-label" :class="{ 'show' : error }">{{ this.errorText }}</label>
        </div>
    </div>
</template>

<script>
import DatePicker from '@vuepic/vue-datepicker'

import '@vuepic/vue-datepicker/dist/main.css'

const EMIT_MODEL_VALUE = 'update:modelValue'
const EMIT_UPDATE_MONTH_YEAR = 'update-month-year'

export default {
    name: 'date-input',
    emits: [ EMIT_MODEL_VALUE, EMIT_UPDATE_MONTH_YEAR ],
    props: {
        modelValue: {
            type: [ Date, String ]
        },
        placeholder: {
            type: String,
            default: ' ' // is used to trigger the floating label so it can not be empty
        },
        edited: {
            type: Boolean,
            default: false
        },
        dateFormat: {
            type: String,
            default: 'dd.MM.yyyy'
        },
        clearable: {
            type: Boolean,
            default: true
        },
        display: Boolean,
        isLoading: Boolean,
        disabled: Boolean,
        label: String,
        required: Boolean,
        error: Boolean,
        errorText: String,
        backgroundColor: String,
        inputId: String,
        allowedDates: Array,
        preventMinMaxNavigation: {
            type: Boolean,
            default: true
        },
        minDate: Date
    },
    computed: {
        value: {
            get() {
                // value is null if the dateInput gets cleared
                if (this.modelValue !== null) {
                    return new Date(this.modelValue)
                }
                return this.modelValue
            },
            set(value) {
                // value is null if the dateInput gets cleared
                if (value !== null) {
                    value = new Date(new Date(value).toISOString().substring(0, 10)) // remove time from and return as ISO string
                }
                this.$emit(EMIT_MODEL_VALUE, value)
            }
        }
    },
    methods: {
        updateMonthYear(event) {
            this.$emit(EMIT_UPDATE_MONTH_YEAR, event)
        }
    },
    components: {
        DatePicker
    }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
    position: relative;
    padding-top: 15px;

    &.error :deep(*) {
        color: red;
        border-color: red;
    }

    > .input-label {
        color: #999;
        position: absolute;
        font-size: 1em;
        left: 10px;
        background: #fff;
        top: 7px;
        padding: 0px 5px;
        transition: .1s all;
        height: 10px;
        line-height: 10px;

        &.float {
            top: 0px;
            left: 0px;
        }
    }
}

.edited :deep(input) {
    border-color: green;
    border-width: 2px;
}

:deep(input) {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    font-size: 1.2em;
    overflow: hidden;
    background-color: #fff;
    border: unset;
    border-radius: 0;
    border-bottom: 1px solid #064A6C;

    &::placeholder {
        font-size: 1em;
    }

    &:disabled {
        border-color: #ddd;
        cursor: not-allowed;
    }

    &:not(:disabled):hover {
        border-color: #064A6C;
    }
}

.error-container {
    min-height: 15px;
    margin-top: 3px;
    padding-left: 10px;
    color: red;
    text-align: left;

    > .error-label {
        visibility: hidden;

        &.show {
            visibility: visible;
        }
    }
}
</style>