<template>
    <div class="input-wrapper">
        <div :ref="REF_INPUT_CONTAINER" class="input-container" :class="{ 'disabled' : disabled }" @click="openOptions" >
            <span class="open-options" :disabled="!availableOptions || availableOptions.length === 0">
                <fa-icon icon="fa-solid fa-plus"/>
            </span>
            <span class="selected-option" v-for="option in modelValue" :key="option.displayid" @click="removeOption($event, option)">
                {{ option.displayname }} 
                <fa-icon icon="fa-solid fa-xmark"/>
            </span>

            <ul :ref="REF_OPTIONS_LIST" class="options-list" v-show="optionsOpen">
                <li class="option" v-for="option in availableOptions" :key="option.displayid" @click="selectOption(option)">{{ option.displayname }}</li>
            </ul>
        </div>

        <label class="input-label" :class="{ 'float' : this.modelValue.length > 0 }" v-if="label">{{ this.label }} {{ required ? '*' : '' }}</label>
    </div>
</template>

<script>
const REF_INPUT_CONTAINER = 'input-container'
const REF_OPTIONS_LIST    = 'options-list'

const EMIT_UPDATE_MODEL_VALUE = 'update:modelValue'
const EMIT_SELECT_ELEMENT     = 'select'
const EMIT_REMOVE_ELEMENT     = 'remove'
const EMIT_OPEN_ACTIONS       = 'open-actions'

export default {
    name: 'MultiSelectInput',
    inject: [ 'util' ],
    emits: [ EMIT_UPDATE_MODEL_VALUE, EMIT_SELECT_ELEMENT, EMIT_REMOVE_ELEMENT, EMIT_OPEN_ACTIONS],
    props: {
        modelValue: {
            type: Array,
            required: true
        },
        options: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            default: ' ' // is used to trigger the floating label so it can not be empty
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: String,
        required: Boolean,
        min: Number,
        // if set, the model-value will NOT be updated automaitcally
        // but the add and remove events will still fire
        eventOnly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            REF_INPUT_CONTAINER,
            REF_OPTIONS_LIST,
            optionsOpen: false
        }
    },
    mounted() {
        // add horizontal scroll event for overflowing values
        this.containerEl.addEventListener("wheel", (event) => {
            this.containerEl.scrollLeft += event.deltaY
            event.preventDefault()
        })

        // add option-list to body, so it is not impacted by its direct parent (overflow: hidden)
        // additionaly get an initial position for the list
        document.body.append(this.optionsEl)
    },
    beforeUnmount() {
        // remove the options from the body to avoid duplicates
        this.optionsEl.remove()
    },
    computed: {
        availableOptions() {
            return this.options?.filter(option => {
                return -1 === this.modelValue?.findIndex(item => item.displayid === option.displayid)
            })
        },
        optionsEl() {
            return this.$refs[REF_OPTIONS_LIST]
        },
        containerEl() {
            return this.$refs[REF_INPUT_CONTAINER]
        }
    },
    methods: {
        openOptions(event) {
            if (this.disabled) return

            this.$emit(EMIT_OPEN_ACTIONS)

            if (event) {
                event.stopPropagation()
            }

            // register click-outside event on body
            document.addEventListener('click', () => {
                this.closeOptions()
            })

            this.optionsOpen = true
            this.util.positionPopupElement(this.containerEl, this.optionsEl)
        },
        closeOptions() {
            document.removeEventListener('click', null)
            this.optionsOpen = false
        },
        selectOption(option) {
            if (this.disabled) return

            if (this.eventOnly) {
                this.$emit(EMIT_SELECT_ELEMENT, option)
            } else {
                const updatedList = [...this.modelValue]
                updatedList.push(option)
                this.$emit(EMIT_UPDATE_MODEL_VALUE, updatedList)
            }

            this.optionsOpen = false
        },
        removeOption(event, option) {
            if (this.disabled) return
            
            // dont open options automatically
            if (event) {
                event.stopPropagation()
            }

            if (this.eventOnly) {
                this.$emit(EMIT_REMOVE_ELEMENT, option)
            } else {
                // const itemIdx = this.modelValue.findIndex(item => item.displayid === option.displayid)
                // const updatedList = [...this.modelValue]
                // updatedList.splice(itemIdx, 1)
                const updatedList = this.modelValue.filter(group => group.displayid !== option.displayid)
                this.$emit(EMIT_UPDATE_MODEL_VALUE, updatedList)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
    position: relative;

    > .input-label {
        position: absolute;
        font-size: 1.25em;
        top: 24px;
        left: 10px;
        color: #999;
        padding: 0px 5px;
        transition: .1s all;
        height: 10px;
        line-height: 10px;
        font-size: 1.1em;

        &.float {
            top: 0px;
            left: 0px;
        }
    }

    > .input-container {
        display: flex;
        align-items: center;
        overflow: hidden;
        overflow-x: auto;
        width: 100%;
        height: 30px;
        box-sizing: border-box;
        font-size: 1.2em;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 10px;
        border-bottom: 1px solid #064A6C;

        &:hover {
            cursor: pointer;
        }

        &.disabled {
            &:hover {
                cursor: default;
            }

            > .selected-option {
                background-color: #5a6268;

                &:hover {
                    cursor: default;
                }
            }
        }

        > .selected-option {
            background-color: #f01414;
            color: #fff;
            fill: #fff;
            height: fit-content;
            border-radius: 8px;
            padding: 0 10px;
            margin-right: 5px;
            display: flex;
            align-items: center;
            flex-basis: auto;
            user-select: none;

            &:hover {
                background-color: #5a6268;
                cursor: pointer;
            }

            > svg {
                margin-left: 8px;
                padding: 1px;
                height: 20px;
            }
        }

        > .open-options {
            min-width: 16px;
            width: 16px;
            height: 16px;
            margin-right: 12px;

            &[disabled=true]{
                > svg {
                    opacity: 0.25;
                    &:hover {
                        cursor: initial;
                    }
                }
            }
        }
    }
}

// specified at root because we move the options-list
// outside of the components DOM representation
// -> but it stays scoped, since we dont lose the app scope!
.options-list {
    position: absolute;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin: 0;
    background: #fff;
    width: fit-content;
    min-width: 200px;
    box-sizing: border-box;
    padding: 0;
    list-style-type: none;
    overflow: hidden;
    box-shadow: 0px 1px 10px rgb(100 100 150 / 30%);
    z-index: 10;

    > .option {
        text-align: left;
        text-decoration: none;
        padding: 10px 0;
        padding-left: 10px;
        user-select: none;

        &:hover {
            background: rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }
}
</style>