<template>
    <div class="input-wrapper" :class="{ 'error':this.error }">
        <input :id="computedId" :ref="REF_INPUT" :value="modelValue" @input="handleInput" :autocomplete="type === 'password' ? 'new-password' : 'off'" :min="min" @click="clicked" :type="type" :placeholder="placeholder" :disabled="disabled" v-bind="$attrs"/>
        <label :for="computedId" @click="handleLabelClick" class="input-label"  v-if="label">{{ this.label }} {{ required ? '*' : '' }}</label>

        <div class="error-container">
            <label class="error-label" :class="{ 'show' : error }">{{ this.errorText }}</label>
        </div>
    </div>
</template>

<script>
const REF_INPUT = 'input'

export default {
    name: 'text-input',
    props: {
        modelValue: {
            type: [ String, Date ]
        },
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String,
            default: ' ' // is used to trigger the floating label so it can not be empty
        },
        disabled: Boolean,
        label: String,
        required: Boolean,
        error: Boolean,
        errorText: String,
        min: Number,
        inputId: String,
        idStub: String
    },
    data() {
        return {
            REF_INPUT
        }
    },
    computed: {
        computedId: function() {
            const externalId = this.inputId || ''
            const stub = this.idStub ? '-' + this.idStub : ''
            const id = `input-${externalId}${stub}`
            // only set the id if an externalId and/or sub was given
            // else there will be inputs with the same id and the label
            // will only respond to the first input with the given id!
            return id !== 'input-'? id :  undefined
        }
    },
    methods: {
        handleInput: function(event) {
            this.$emit('update:modelValue', event.target.value)
        },
        clicked: function(event) {
            this.$emit('click', event)
        },
        handleLabelClick() {
            this.$refs[REF_INPUT].focus()
        }
    },
}
</script>

<style scoped>
.input-wrapper {
    position: relative;
}

.input-wrapper {
    padding-top: 15px;
}

.input-wrapper > .input-label {
    color: #999;
    position: absolute;
    font-size: 1.25em;
    left: 10px;
    top: 24px;
    padding: 0px 5px;
    transition: .2s all;
    height: 10px;
    line-height: 10px;
}

.input-wrapper > .input-label:hover {
    cursor: text;
}

/* https://zellwk.com/blog/check-empty-input-css/ */
input:not(:placeholder-shown) + .input-label,
input:focus:not(:read-only) + .input-label {
    font-size: 1em;
    top: 4px;
    left: 0px;
}

input {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    font-size: 1.2em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: unset;
    padding-left: 10px;
    border: none;
    border-bottom: 1px solid #064A6C;
}

input::placeholder {
    font-size: 1em;
}

input:disabled {
    border-color: #ddd;
    cursor: not-allowed;
}

.textinput-container > label {
    color: #fff;
}

.input-label {
    font-size: 1.1em;
    color: #2c2e2f;
}

.error-container {
    min-height: 15px;
    margin-top: 3px;
    padding-left: 10px;
    color: red;
    text-align: left;
}

.error-container > .error-label {
    visibility: hidden;
}

.error-container > .error-label.show {
    visibility: visible;
}

.input-wrapper.error :deep(*) {
    color: red;
}

.input-wrapper.error > input {
    border: 1px solid red;
}
</style>