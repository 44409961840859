import protobuf from 'protobufjs'
//import util from '@plugins/util.js'

const zvtErrorMessages = [
    /*  0*/ "no error",

    /*1-99 = 99 entries for errorcodes from network-operator system/authorisation-system, 5 each line, last only 4*/
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",
          "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode", "network or authorization system errorcode",

    /*100*/ "card not readable (LRC-/parity-error)",
    /*101*/ "card-data not present (neither track-data nor chip found)",
    /*102*/ "processing-error (also for problems with card-reader mechanism)",
    /*103*/ "function not permitted for ec- and Maestro-cards",
    /*104*/ "function not permitted for credit- and tank-cards",

    /*105*/ "undefined",

    /*106*/ "turnover-file full",
    /*107*/ "function deactivated (PT not registered)",
    /*108*/ "abort via timeout or abort-key",

    /*109*/ "undefined",

    /*110*/ "card in blocked-list (response to command 06 E4)",
    /*111*/ "wrong currency",

    /*112*/ "undefined",

    /*113*/ "credit not sufficient (chip-card)",
    /*114*/ "chip error",
    /*115*/ "card-data incorrect (e.g. country-key check, checksum-error)",

    /*116,117,118*/ "undefined", "undefined", "undefined",

    /*119*/ "end-of-day batch not possible",
    /*120*/ "card expired",
    /*121*/ "card not yet valid",
    /*122*/ "card unknown",
    /*123*/ "fallback to magnetic stripe for girocard not possible",
    /*124*/ "fallback to magnetic stripe not possible (used for non girocard cards)",
    /*125*/ "communication error (communication module does not answer or is not present)",
    /*126*/ "fallback to magnetic stripe not possible, debit advice possible (used only for girocard)",

    /*127,128,129,130*/ "undefined", "undefined", "undefined", "undefined",

    /*131*/ "function not possible",

    /*132*/ "undefined",

    /*133*/ "key missing",

    /*134,135,136*/ "undefined", "undefined", "undefined",

    /*137*/ "PIN-pad defective",

    /*138,139,140,141,142,143,144,145,146,147,148,149,150,151,152,153*/ "undefined", "undefined", "undefined", "undefined", "undefined", "undefined", "undefined", "undefined", "undefined", "undefined", "undefined", "undefined", "undefined", "undefined", "undefined", "undefined",

    /*154*/ "ZVT protocol error. e. g. parsing error, mandatory message element missing",
    /*155*/ "error from dial-up/communication fault",
    /*156*/ "please wait",

    /*157,158,159*/ "undefined", "undefined", "undefined",

    /*160*/ "receiver not ready",
    /*161*/ "remote station does not respond",

    /*162*/ "undefined",

    /*163*/ "no connection",
    /*164*/ "submission of Geldkarte not possible",

    /*165,166,167,168,169,170,171,172,173,174,175,176*/ "undefined", "undefined", "undefined", "undefined", "undefined", "undefined", "undefined", "undefined", "undefined", "undefined", "undefined", "undefined",

    /*177*/ "memory full",
    /*178*/ "merchant-journal full",

    /*179*/ "undefined",

    /*180*/ "already reversed",
    /*181*/ "reversal not possible",

    /*182*/ "undefined",

    /*183*/ "pre-authorisation incorrect (amount too high) or amount wrong",
    /*184*/ "error pre-authorisation",

    /*185,186,187,188,189,190*/ "undefined", "undefined", "undefined", "undefined", "undefined", "undefined",

    /*191*/ "voltage supply too low (external power supply)",
    /*192*/ "card locking mechanism defective",
    /*193*/ "merchant-card locked",
    /*194*/ "diagnosis required",
    /*195*/ "maximum amount exceeded",
    /*196*/ "card-profile invalid. New card-profiles must be loaded.",
    /*197*/ "payment method not supported",
    /*198*/ "currency not applicable",

    /*199*/ "undefined",

    /*200*/ "amount too small",
    /*201*/ "max. transaction-amount too small",

    /*202*/ "undefined",

    /*203*/ "function only allowed in EURO",
    /*204*/ "printer not ready",
    /*205*/ "Cashback not possible",

    /*206,207,208,209*/ "undefined", "undefined", "undefined", "undefined",

    /*210*/ "function not permitted for service-cards/bank-customer-cards",

    /*211,212,213,214,215,216,217,218,219*/ "undefined", "undefined", "undefined", "undefined", "undefined", "undefined", "undefined", "undefined", "undefined",

    /*220*/ "card inserted",
    /*221*/ "error during card-eject (for motor-insertion reader)",
    /*222*/ "error during card-insertion (for motor-insertion reader)",

    /*223*/ "undefined",

    /*224*/ "remote-maintenance activated",

    /*225*/ "undefined",

    /*226*/ "card-reader does not answer / card-reader defective",
    /*227*/ "shutter closed",
    /*228*/ "Terminal activation required",

    /*229,230*/ "undefined", "undefined",

    /*231*/ "min. one goods-group not found",
    /*232*/ "no goods-groups-table loaded",
    /*233*/ "restriction-code not permitted",
    /*234*/ "card-code not permitted (e.g. card not activated via Diagnosis)",
    /*235*/ "function not executable (PIN-algorithm unknown)",
    /*236*/ "PIN-processing not possible",
    /*237*/ "PIN-pad defective",

    /*238,239*/ "undefined", "undefined",

    /*240*/ "open end-of-day batch present",
    /*241*/ "ec-cash/Maestro offline error",

    /*242,243,244*/ "undefined", "undefined", "undefined",

    /*245*/ "OPT-error",
    /*246*/ "OPT-data not available (= OPT personalisation required)",

    /*247,248,249*/ "undefined", "undefined", "undefined",

    /*250*/ "error transmitting offline-transactions (clearing error)",
    /*251*/ "turnover data-set defective",
    /*252*/ "necessary device not present or defective",
    /*253*/ "baudrate not supported",
    /*254*/ "register unknown",
    /*255*/ "system error (= other/unknown error), See TLV tags 1F16 and 1F17"
]

const LISTENER = {
    BARCODE:          26,
    LICENSE_PLATE:    1205,
    SAM:              45,
    USER_MEDIA:       47,
    BROADCAST_STATUS: 1206,
    VEHICLE:          1215
}

const HWS_STATUS = {
    OPEN:       0,
    CONNECTING: 1,
    CLOSED:     2
}

const REGEX_FORBIDDEN_LICENSE_PLATE_CHARACTERS = /[?!/\\]/


class Hws {
    data = {
        status: HWS_STATUS.CLOSED,
        licensePlate: '',
        barcode: [],
        lastLicensePlates: [],
        currentPaymentStatus: '',
        lastVehicles: []
    }

    _url
    _socket
    _GRPC
    _isInitialized
    _device
    _listeners
    _handleCtr
    _callbacks = {}
    _enabled

    get device() {
        return this._device
    }

    set device(device) {
        this._device = device
    }

    get enabled() {
        return this._enabled
    }

    set enabled(enabled) {
        this._enabled = enabled
    }

    constructor(listeners = []) {
        this._socket = null
        this._handleCtr = 0
        this._listeners = listeners
    }

    _newHandle() {
        return ++this._handleCtr
    }

    async init(device, sitePath) {
        if (this._isInitialized || !this._enabled || !device) {
            return false
        }

        if (!device.configuration?.hws?.host || !device.configuration?.hws?.port) {
            return false
        }

        try {
            this._device = device
            this._url    = `ws://${this._device.configuration.hws.host}:${this._device.configuration.hws.port}/DispatchMessage`

            await this._loadSchema(sitePath)

            this._tryConnect()
            setInterval(() => {
                this._tryConnect()
            }, 10000)

            this._isInitialized = true
        } catch (err) {
            console.error(err)
        }

        return true
    }

    async _loadSchema(sitePath) {
        return protobuf.load(sitePath + '/ServiceConnection.proto')
        .then((root) => {
            this._GRPC = root.lookupType('GrpcMessage')
        })
    }

    _tryConnect() {
        if (this._socket !== null) {
            switch(this._socket.readyState) {
                case WebSocket.OPEN:
                case WebSocket.CONNECTING:
                    return
                case WebSocket.CLOSING:
                case WebSocket.CLOSED:
                    break
            }
        }

        this.data.status = HWS_STATUS.CONNECTING

        this._socket = new WebSocket(this._url)

        this._socket.binaryType = 'arraybuffer'

        this._socket.addEventListener('open', () => {
            this.data.status = HWS_STATUS.OPEN

            this._callbacks = {}

            this._startProvider(-1)

            if (this._listeners.includes(LISTENER.BARCODE)) {
                this._registerBarcodeListener()
            }

            if (this._listeners.includes(LISTENER.BROADCAST_STATUS)) {
                this._registerBroadcastStatusListener()
                this._sendStatusBroadcastRequest()
            }

            if (this._listeners.includes(LISTENER.LICENSE_PLATE)) {
                this._registerLicensePlateListener()
            }

            if (this._listeners.includes(LISTENER.SAM)) {
                this._registerSamListener()
            }

            if (this._listeners.includes(LISTENER.USER_MEDIA)) {
                this._registerNmListener()
            }
            if (this._listeners.includes(LISTENER.VEHICLE)) {
                this._registerVehicleListener()
            }            
        })

        this._socket.addEventListener('error', (err) => {
            console.log('Error: ', err)
            this._socket = null
            this.data.status = HWS_STATUS.CLOSED
        })

        this._socket.addEventListener('close', () => {
            this._socket = null
            this.data.status = HWS_STATUS.CLOSED
        })

        this._socket.addEventListener('message', (event) => {
            const buffer = new Uint8Array(event.data)

            const message = this._GRPC.decode(buffer)

            const func = this._callbacks[message.handle]
            if (func) func(message)
        })
    }

    cancelCurrentPayment() {
        return this._send(this._buildPacket({ what: 1208, handle: 8877, dataId: -1 }))
    }

    async initPayment(sum) {
        this.data.currentPaymentStatus = 'Zahlung wird initialisiert'
        const handle = this._newHandle()
        return new Promise((res, rej) => {
            this._registerCallback(handle, async (message) => {
                let dataExtended
                if (Number(message.response) >= 0 ) {
                    dataExtended = JSON.parse(message.dataExtended)
                }
                const result = {
                    zvtCode: message.dataID,
                    response: message.response,
                    message: message.responseMessage,
                    errorMessage: undefined,
                    extendedErrorMessage: undefined,
                    paymentData: dataExtended,
                    receiptPrintData: message.dataAsBytes,
                    completeObject: message
                }
                dataExtended?.status?.tlv?.tlv?.forEach((element) => {
                    if (element.t.toUpperCase === '1F17') {
                        result.extendedErrorMessage = atob(element.v)
                    }
                })

                if (message.response === '93') {
                    this.data.currentPaymentStatus = message.data
                } else if (message.response !== '0') {
                    this._unregisterCallback(message.handle)
                    result.errorMessage = zvtErrorMessages[dataExtended?.status?.resultCode]
                    rej(result)
                } else if ((dataExtended?.status?.resultCode === 0) && (dataExtended?.status?.control.toUpperCase() == '040F')) {
                    this._unregisterCallback(message.handle)
                    result.errorMessage = zvtErrorMessages[dataExtended?.status]
                    res(result)
                } else {
                    this._unregisterCallback(message.handle)
                    result.errorMessage = zvtErrorMessages[dataExtended?.status?.resultCode]
                    rej(result)
                }
            })

            if (!this._send(this._buildPacket({ what: 1208, handle: handle, dataId: sum }))) {
                rej({errorMessage: 'Keine Verbindung zum Verkaufssystem'})
            }
        })
    }

    async initRefund(sum) {
        this.data.currentPaymentStatus = 'Erstattung wird initialisiert'
        const handle = this._newHandle()
        return new Promise((res, rej) => {
            this._registerCallback(handle, async (message) => {
                let dataExtended
                if (Number(message.response) >= 0 ) {
                    dataExtended = JSON.parse(message.dataExtended)
                }
                const result = {
                    zvtCode: message.dataID,
                    response: message.response,
                    message: message.responseMessage,
                    errorMessage: undefined,
                    extendedErrorMessage: undefined,
                    paymentData: dataExtended,
                    receiptPrintData: message.dataAsBytes,
                    completeObject: message
                }
                dataExtended?.status?.tlv?.tlv?.forEach((element) => {
                    if (element.t.toUpperCase === '1F17') {
                        result.extendedErrorMessage = atob(element.v)
                    }
                })

                if (message.response === '93') {
                    this.data.currentPaymentStatus = message.data
                } else if (message.response !== '0') {
                    this._unregisterCallback(message.handle)
                    result.errorMessage = zvtErrorMessages[dataExtended?.status?.resultCode]
                    rej(result)
                } else if ((dataExtended?.status?.resultCode === 0) && (dataExtended?.status?.control.toUpperCase() == '040F')) {
                    this._unregisterCallback(message.handle)
                    result.errorMessage = zvtErrorMessages[dataExtended?.status]
                    res(result)
                } else {
                    this._unregisterCallback(message.handle)
                    result.errorMessage = zvtErrorMessages[dataExtended?.status?.resultCode]
                    rej(result)
                }
            })

            if (!this._send(this._buildPacket({ what: 1217, handle: handle, dataId: sum }))) {
                rej({errorMessage: 'Keine Verbindung zum Verkaufssystem'})
            }
        })
    }
    
    async initEndOfDay() {
        this._send(this._buildPacket({ what: 1212 }))
    }

    async printImage(img) {
        const handle = this._newHandle()
        return new Promise((res, rej) => {
            this._registerCallback(handle, async (message) => {
                this._unregisterCallback(message.handle)
                const result = {
                    response: message.response,
                    message: message.responseMessage,
                    errorMessage: undefined,
                    completeObject: message
                }
                if (message.response !== '1') {
                    result.errorMessage = message.responseMessage
                    rej(result)
                } else {
                    res(result)
                }
            })

            if (!this._send(this._buildPacket({ what: 2, handle: handle, dataAsBytes: img }))) {
                rej({errorMessage: 'Keine Verbindung zum Verkaufssystem'})
            }
            if (!this._send(this._buildPacket({ what: 3, handle: -1 }))) {
                rej({errorMessage: 'Keine Verbindung zum Verkaufssystem'})
            }
        })
    }

    _isConnected() {
        return (this._socket != null) && (this._socket.readyState === WebSocket.OPEN)
    }

    _buildPacket(packetData) {
        if (this._GRPC) {
            return this._GRPC.create(packetData)
        } else {
            return undefined
        }
    }

    _send(grpcMessage) {
        const result = this._isConnected()
        if (result) {
            if (this._device?.id) {
                grpcMessage.responseMessage = 'Servicestelle:' + this._device.id
            } else {
                grpcMessage.responseMessage = 'Servicestelle:?'
            }

            var buffer = this._GRPC.encode(grpcMessage).finish()

            this._socket.send(buffer.buffer.slice(buffer.byteOffset, buffer.byteOffset + buffer.length))
        } else {
            console.log('Nicht verbunden!')
        }
        return result
    }

    _sendStatusBroadcastRequest() {
        this._send(this._buildPacket({ what: 1207, handle: -1, data2AsString: '#STATUS', dataExtended: 'ACTION.STATUS.REQUEST', data: '' }))
    }

    _onStatusBroadcast(message) {
        this.data.lastLicensePlates = []
        if (message.data) {
            const dataParsed = JSON.parse(message.data)
            dataParsed.provider?.modules?.numberPlate?.status?.lastNumberPlates?.forEach(element => {
                this.data.lastLicensePlates.push(element.kennzeichen)
            })   
        }
    }

    _containsForbiddenCharacters(licensePlate) {
        return REGEX_FORBIDDEN_LICENSE_PLATE_CHARACTERS.test(licensePlate)
    }

    _onLicensePlate(message) {
        if (message.data) {
            if(this._containsForbiddenCharacters(message.data)){
                return
            }
            this.data.licensePlate = message.data
            this.data.lastLicensePlates.push(message.data)
        }
    }

    
    _onVehicle(message) {
        if (message.data) {
            try {
                const vehicle = JSON.parse(message.data)
                if(this._containsForbiddenCharacters(message.data2AsString)){
                    return
                }
                vehicle['licensePlate'] = message.data2AsString
                this.data.lastVehicles.push(vehicle)
            } catch (error)
            { return }
        }
    }    

    _onBarcode(message) {
        if (message.response == 0) {
            this.data.barcode = message.responseMessageAsBytes
        }
    }

    _registerCallback(handle, func) {
        this._callbacks[handle] = func
    }

    _unregisterCallback(handle) {
        this._callbacks[handle] = undefined
    }

    _registerBarcodeListener() {
        const handle = this._newHandle()
        this._registerCallback(handle, (message) => this._onBarcode(message))
        this._send(this._buildPacket({ what: 26, handle: handle, dataId: 3 }))
        this._switchBarcodeScannerOn()
    }

    _switchBarcodeScannerOn() {
        this._send(this._buildPacket({ what: 30, handle: -1, dataId: 1 }))
    }

    _switchBarcodeScannerOff() {
        this._send(this._buildPacket({ what: 30, handle: -1, dataId: 2 }))
    }

    _openBarrier() {
        this._send(this._buildPacket({ what: 1210, handle: -1, data: 'open_barrier' }))
    }

    _openBarrierOnce() {
        this._send(this._buildPacket({ what: 1210, handle: -1, data: 'open_barrier_once' }))
    }

    _closeBarrier() {
        this._send(this._buildPacket({ what: 1210, handle: -1, data: 'close_barrier' }))
    }

    _switchLightsGreen() {
        this._send(this._buildPacket({ what: 1210, handle: -1, data: 'green_light' }))
    }

    _switchLightsRed() {
        this._send(this._buildPacket({ what: 1210, handle: -1, data: 'red_light' }))
    }

    sendActionCodeTicketOK() {
        this._send(this._buildPacket({ what: 44, handle: -1, dataId: 5, sessionId: -1}))
    }

    _registerLicensePlateListener() {
        const handle = this._newHandle()
        this._registerCallback(handle, (message) => this._onLicensePlate(message))
        this._send(this._buildPacket({ what: 1205, handle: handle }))
    }

    _registerSamListener() {
        const handle = this._newHandle()
        this._send(this._buildPacket({ what: 45, handle: handle }))
    }

    _registerNmListener() {
        const handle = this._newHandle()
        this._send(this._buildPacket({ what: 47, handle: handle }))
    }

    _registerVehicleListener() {
        const handle = this._newHandle()
        this._registerCallback(handle, (message) => this._onVehicle(message))
        this._send(this._buildPacket({ what: 1215, handle: handle }))
    }

    _registerBroadcastStatusListener() {
        const handle = this._newHandle()
        this._registerCallback(handle, (message) => this._onStatusBroadcast(message))
        if (this._send(this._buildPacket({ what: 1206, handle: handle, data2AsString: '#STATUS' }))) {
            this._send(this._buildPacket({ what: 30, handle: 321, dataId: 1 }))
        }
    }

    isAlive() {
        const handle = this._newHandle()
        this._send(this._buildPacket({ what: 5, handle: handle }))
    }

    _startProvider(handle) {
        if (!this._device.configuration?.hws?.config) {
            return false
        }

        let identifier

        if (this._device?.id) {
            identifier = 'HWS:' + this._device.id
        } else {
            identifier = 'HWS:?'
        }

        return this._send(this._buildPacket({ what: 1211, handle: handle, data: 'svt', dataExtended: this._device.configuration?.hws?.config, response: identifier, data2: 1 }))
    }

    async restartProvider() {
        const handle = this._newHandle()
        return new Promise((res, rej) => {
            this._registerCallback(handle, async (message) => {
                this._unregisterCallback(message.handle)
                const result = {
                    response: message.response,
                    message: message.responseMessage,
                    errorMessage: undefined,
                    completeObject: message
                }
                if (message.response !== '1') {
                    result.errorMessage = message.responseMessage
                    rej(result)
                } else {
                    res(result)
                }
            })

            if (!this._device.configuration?.hws?.config) {
                rej({errorMessage: 'Keine Konfiguration hinterlegt'})
            } else if (!this._startProvider(handle)) {
                rej({errorMessage: 'Keine Verbindung zum Verkaufssystem'})
            }
        })
    }
}

export {
    LISTENER,
    Hws
}