<template>
    <div class="input-wrapper" :class="{ 'error' : error }">
        <select class="input" :required="required" :placeholder="placeholder" :disabled="disabled" v-model="inputValue">
            <option v-if="defaultOption" :value="defaultItem" >{{ defaultItem.displayname }}</option>
            <option v-for="option in optionList" :key="option.displayid" :value="option">{{ option.displayname }}</option>
        </select>

        <label class="input-label" :class="{ 'with-content' : inputValue }" v-if="label">{{ this.label }} {{ required ? '*' : '' }}</label>

        <div class="error-container">
            <label class="error-label" :class="{ 'show' : error }">{{ this.errortext }}</label>
        </div>
    </div>
</template>

<script>
const EMIT_UPDATE_MODEL_VALUE = 'update:modelValue'
const EMIT_INPUT = 'input'

export default {
    name: 'dropdown-input',
    emits: [ EMIT_UPDATE_MODEL_VALUE, EMIT_INPUT ],
    props: {
        modelValue: {
            type: Object // { displayid: 0, displayname: 'abc' } || undefined
        },
        defaultOptionId: {
            type: Number,
            default: -1
        },
        placeholder: {
            type: String,
            default: ' ' // is used to trigger the floating label so it can not be empty
        },
        label: String,
        required: Boolean,
        disabled: Boolean,
        defaultOption: {
            type: Boolean,
            default: true
        },
        defaultLabel: {
            type: String,
            default: 'Bitte auswählen'
        },
        optionList: Array, // [] of "{displayid: 0, displayname: 'name', original: {object} }"
        error: Boolean,
        errortext: String,
    },
    computed: {
        defaultItem() {
            const name = this.disabled ? '' : '--- ' + this.defaultLabel + ' ---'			
            return { displayid: this.defaultOptionId, displayname: name}
        },
        inputValue: {
            get() {
                // handle defaultOption
                if (!this.modelValue && this.defaultOption) {
                    return this.defaultItem
                }

                return this.modelValue
            },
            set(value) {
                // handle default option as undefined
                if (value.displayid === this.defaultOptionId) {
                    value = undefined
                }

                this.$emit(EMIT_UPDATE_MODEL_VALUE, value)
                this.$emit(EMIT_INPUT, value)
            }
        },
    }
}
</script>

<style scoped>
.input-wrapper {
    position: relative;
}

.input-wrapper {
    padding-top: 15px;
}

.input-wrapper > .input-label {
    color: #999;
    position: absolute;
    font-size: 1.25em;
    left: 10px;
    top: 24px;
    padding: 0px 5px;
    transition: .2s all;
    height: 10px;
    line-height: 10px;
}

.input-wrapper > .input-label.with-content {
    font-size: 1em;
    top: 4px;
    left: 0px;
}

.input-wrapper > .search {
    position: absolute;
    border: 1px solid red;
    max-width: calc(100% - 20px);
    width: 150px;
    height: 30px;
}

select {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    font-size: 1.2em;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: unset;
    padding-left: 10px;
    border: none;
    border-bottom: 1px solid #064A6C;
}

select::placeholder {
    font-size: 1em;
}

select:disabled {
    border-color: #ddd;
    cursor: not-allowed;
}

.textinput-container > label {
    color: #fff;
}

.input-label {
    font-size: 1.1em;
    color: #2c2e2f;
}

.error-container {
    min-height: 15px;
    margin-top: 3px;
    padding-left: 10px;
    color: red;
    text-align: left;
}

.error-container > .error-label {
    visibility: hidden;
}

.error-container > .error-label.show {
    visibility: visible;
}

.input-wrapper.error :deep(*) {
    color: red;
    border-color: red;
}

.input-wrapper.error > input {
    border: 1px solid red;
}
</style>