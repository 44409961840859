<template>
    <div class="license-plate-display-wrapper">
        <div class="license-plate">
            <div class="eu-section">
                <img src="./EU-section.png"/>
            </div>
            <div class="content">
                <input v-if="inputMode" @mouseleave="disableInputMode" @blur="disableInputMode" @keyup.enter="handlePlateInput" v-model="plateInput" placeholder="Suche..." type="text" class="plateInput" ref="plateInputField">
                <label v-else @mouseenter="enableInputMode">{{ formattedContent }}</label>
            </div>
        </div>
    </div>
</template>

<script>
// https://www.autokennzeichen.info/kennzeichen-schriftart.htm#:~:text=Die%20auf%20deutschen%20Kfz%2DKennzeichen,Mittelschrift)%20%E2%80%9Ef%C3%A4lschungserschwerend%E2%80%9C%20gestaltet.

const EMIT_PLATE_INPUT = "plateInput"

export default {
    name: 'license-plate-display',
    emits: [EMIT_PLATE_INPUT],
    props: {
        content: {
            type: String,
            default: undefined
        },
        inputOption: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            inputMode: false,
            plateInput: "",
            plateInputHasFocus: false,
            EMIT_PLATE_INPUT
        }
    },
    computed: {
        formattedContent() {
            return this.content?.replaceAll('-', ' ');
        }
    },
    methods: {
        enableInputMode() {
            if(this.inputOption) {
              this.inputMode = true
            }
        },
        disableInputMode() {
            if(this.inputOption && this.$refs.plateInputField !== document.activeElement) {
                this.inputMode = false
            }
        },
        handlePlateInput() {
            this.$emit(EMIT_PLATE_INPUT, this.plateInput)
            this.plateInput = ""
            this.inputMode = false
        }
    },
}
</script>

<style lang="scss" scoped>
@font-face {
    font-family: 'EuroPlate';
    src: url('./EuroPlate.ttf') format('truetype');
}
.license-plate-display-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    > .license-plate {
        height: 3.3em;
        aspect-ratio: 52/11;
        background-color: #fff;
        border: 0.2em solid #000;
        border-radius: 6px;
        display: flex;
        align-items: center;

        > .eu-section {
            height: 100%;

            > img {
                height: 100%;
                object-fit: contain;
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }
        }

        > .content {
            flex: 1;
            color: #000;
            font-family: 'EuroPlate';
            white-space: pre;
            font-size: 2.8em;
            text-align: center;
            height: 100%;

            > label {
              display: block;
              width: 100%;
              height: 100%;
            }

            > .plateInput {
              display: block;
              width: 100%;
              height: 100%;
              color: #000;
              font-family: 'EuroPlate';
              font-size: 1em;
              text-align: center;
              border: none;
              border-radius: 6px;

              &::placeholder {
                font-family: "Istok Web", sans-serif;
                font-weight: bold;
                font-size: 0.7em;
                opacity: 0;
              }

              &:hover::placeholder {
                opacity: 1;
              }

              &:focus::placeholder {
                opacity: 0;
              }
            }
        }
    }
}
</style>