<template>
    <div class="landing-container">
        <div class="left">
            <div v-if="!withCustomer && !newCustomer" class="landing-entry customer-container">
                <div class="entry-header">
                    <label class="entry-title">Kunde</label>
                </div>

                <div class="options entry-content">
                    <div class="search-customer">
                        <a class="action" @click="handleSearchCustomer">
                            <fa-icon icon="fa-solid fa-search"/>
                            <label>Kunde suchen</label>
                        </a>
                    </div>

                    <div class="add-customer">
                        <a class="action" @click="handleCreateCustomer">
                            <fa-icon icon="fa-solid fa-plus"/>
                            <label>Kunde neu anlegen</label>
                        </a>
                    </div>
                </div>
            </div>
            <div v-else class="landing-entry customer-container">
                <CustomerDisplay 
                    :customer="this.globalState.customer" 
                    :show-cost-coverages="!newCustomer"
                    :selectableCostCoverages="true"
                    @close="handleCloseCustomer" 
                    @save="this.globalState.customer = $event"
                    @select-cost-coverage="handleSelectCostCoverage"
                />
            </div>
        </div>

        <div class="middle">
            <div class="landing-entry catalogue">
                <div class="entry-header">
                    <label class="entry-title">Tickets</label>
                </div>

                <OnSiteSales class="entry-content" :model-value="ticketSelection?.product" @update:model-value="ticketSelection = $event"/>
            </div>
        </div>

        <div class="right">
            <div class="overview landing-entry">
                <div class="content-header">
                    <label class="content-title">Zusammenfassung</label>
                </div>

                <div class="content">
                    <div class="entry">
                        <label class="description">Angebots ID</label>
                        <label class="value">{{ ticketSelection?.product ? 'XYZ2356974B-' + ticketSelection.product.id : '' }}</label>
                    </div>

                    <div class="entry paragraph">
                        <label class="description">Richtung</label>
                        <label class="value">{{ ticketSelection?.direction ? ticketSelection.direction.from + ' -> ' + ticketSelection.direction.to : '' }}</label>
                    </div>

                    <div class="entry">
                        <label class="description">Fahrzeug</label>
                        <label class="value">{{ ticketSelection?.vehicle.name || '' }}</label>
                    </div>

                    <div class="entry">
                        <label class="description">Produkt</label>
                        <label class="value">{{ ticketSelection?.product.name || '' }}</label>
                    </div>

                    <div class="entry paragraph">
                        <!-- <label class="description">Informationen</label> -->
                        <label class="value left info">{{ ticketSelection?.product?.info || '' }}</label>
                    </div>

                    <div class="entry paragraph header">
                        <label>KFZ-Kennzeichen</label>
                    </div>
                    <div class="entry license-plates">
                        <TextInput label="[AB-CD 1234]" v-model="licensePlate1Uppercase" />
                        <TextInput label="[AB-CD 1234]" v-model="licensePlate2Uppercase" />
                        <TextInput label="[AB-CD 1234]" v-model="licensePlate3Uppercase" />
                    </div>

                    <div class="entry paragraph header">
                        <label>Zusatzinformationen</label>
                    </div>
                    <div class="entry optional-info">
                        <TextInput label="Subunternehmen" v-model="subCompany"/>
                    </div>
                    <div class="entry optional-info">
                        <TextInput label="Fahrername"     v-model="driverName"/>
                        <TextInput label="Sonstiges"      v-model="misc"/>
                    </div>

                    <div class="options">
                        <CheckboxInput text="Küstenschutz zubuchen ( + 1 € )" v-model="saveTheCoast" :disabled="!ticketSelection?.product?.saveTheCoast" />
                        <CheckboxInput text="Direkt entwerten*" v-model="immediateUse" />
                    </div>
                    <span>* Nur möglich wenn ausschließlich dieses Ticket gekauft wird</span>

                    <div class="entry paragraph price">
                        <label class="description">Preis</label>
                        <label class="value">{{ getFullPrice(ticketSelection, saveTheCoast) }} €</label>
                    </div>

                    <div class="amount">
                        <label>Anzahl</label>
                        <div class="picker">
                            <fa-icon icon="fa-solid fa-minus" @click="() => { if (ticketSelection && ticketSelection.product.amount > 1) ticketSelection.product.amount-- }"/>
                            <label>{{ ('0' + (ticketSelection?.product?.amount || 1)).slice(-2) }}</label>
                            <fa-icon icon="fa-solid fa-plus" @click="() => { if (ticketSelection && ticketSelection.product.amount < 99) ticketSelection.product.amount++ }"/>
                        </div>
                    </div>

                    <div class="actions">
                        <button class="add-to-cart" :disabled="!ticketSelection" @click="addToCart()">
                            <fa-icon icon="fa-solid fa-cart-shopping" />
                            <label>Zum Warenkorb hinzufügen</label>
                        </button>
                        <button class="highlight" :disabled="!ticketSelection" @click="goToCart()">
                            <fa-icon icon="fa-regular fa-money-bill-1" />
                            <label>Direkt kaufen</label>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <PopUp :open="showSearch" title="Kundensuche" @close="showSearch = false">
            <CustomerSearch ref="customerSearch" @result="handleCustomerSearchResult"/>

            <template v-slot:actions>
                <!-- no buttons placeholder -->
                <label></label>
            </template>
        </PopUp>
    </div>
</template>

<script>
import PopUp from '@elements/PopUp.vue'
import TextInput from '@elements/TextInput.vue'
import OnSiteSales from '@components/OnSiteSales.vue'
import { CartItem } from '@lib/session.js'
import CheckboxInput from '@components/elements/CheckboxInput.vue'
import CustomerSearch from '@components/CustomerSearch.vue'
import CustomerDisplay from '@components/CustomerDisplay.vue'
import { useVuelidate } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'
import { ToastOptions } from '@plugins/toast/index.js'

export default {
    name: 'landing-page',
    inject: [ 'util', 'session', 'globalState', 'hws' ],
    setup() {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            ticketSelection: undefined,
            showSearch:      false,
            newCustomer:     false,
            licensePlate1:   undefined,
            licensePlate2:   undefined,
            licensePlate3:   undefined,
            misc:            undefined,
            subCompany:      undefined,
            driverName:      undefined,
            saveTheCoast:    false,
            immediateUse:    false,
        }
    },
    validations() {
        return {
            licensePlateEntered: {
                requiredIfRef: requiredIf(()=> {
                    return !this.licensePlate1 && !this.licensePlate2 && !this.licensePlate3 
                })
            }
        }
    },
    mounted() {
        this.handleCustomerDefaults()
    },
    computed: {
        done() {
            return !!this.ticketSelection
        },
        licensePlate1Uppercase: {
            get() {
                return this.licensePlate1
            },
            set(newValue) {
                this.licensePlate1 = newValue.toUpperCase()
            }
        },
        licensePlate2Uppercase: {
            get() {
                return this.licensePlate2
            },
            set(newValue) {
                this.licensePlate2 = newValue.toUpperCase()
            }
        },
        licensePlate3Uppercase: {
            get() {
                return this.licensePlate3
            },
            set(newValue) {
                this.licensePlate3 = newValue.toUpperCase()
            }
        },
        customerLicensePlates() {
            return this.globalState.customer?.licensePlates
        },
        hwsLicensePlate() {
            return this.hws.data.licensePlate
        },
        withCustomer() {
            return !!this.globalState.customer
        }      
    },
    methods: {
        getFullPrice(ticket, saveTheCoast) {
            const ticketPrice           = ticket?.product?.price
            const saveTheCoastSurcharge = saveTheCoast ? 100 : 0
            
            return this.util.formatCurrency(ticketPrice + saveTheCoastSurcharge || 0)
        },
        handleTicketSelection() {
            this.$router.push({ name: 'authorization-creation' })
        },
        addToCart() {
            this.v$.licensePlateEntered.$touch()
            if (this.v$.$invalid) {
                const toastOptions = new ToastOptions({ duration: 5000 })
                this.$toast.info('Mindestens ein Kennzeichen eingeben.', toastOptions)
                return false
            }
            this.session.addToShoppingCart(this.getCartItemFromSelection())
            this.saveTheCoast = false
            this.immediateUse = false
            return true
        },
        goToCart() {
            if (this.addToCart()) {
                this.$router.push({ name: 'shopping-cart' })
            }
        },
        handleCustomerDefaults() {
            this.licensePlate1 = this.customerLicensePlates?.at(0)?.value
            this.licensePlate2 = this.customerLicensePlates?.at(1)?.value
            this.licensePlate3 = this.customerLicensePlates?.at(2)?.value

        },
        handleSearchCustomer() {
            this.showSearch = true
        },
        handleCreateCustomer() {
            this.newCustomer = true
        },
        handleCustomerSearchResult(customer) {
            Object.assign(this.globalState, {customer})
            this.showSearch   = false

        },
        handleCloseCustomer() {
            this.newCustomer = false
            this.globalState.customer = undefined
        },
        handleSelectCostCoverage(costCoverage){
            this.subCompany = costCoverage.authorisedCompany
            this.driverName = costCoverage.driver
        },
        getCartItemFromSelection() {
            const item = new CartItem()

            item.amount          = this.ticketSelection.product.amount,
            item.productId       = this.ticketSelection.product.id,
            item.productOrgId    = this.ticketSelection.product.orgId,
            item.productName     = this.ticketSelection.product.name,
            item.vehicleType     = this.ticketSelection.product.vehicleType,
            item.vehicleId       = this.ticketSelection.product.vehicleId,
            item.price           = this.ticketSelection.product.price,
            item.info            = this.ticketSelection.product.info,
            item.originalProduct = this.ticketSelection.product,
            item.direction       = this.ticketSelection.direction,
            item.licensePlates   = [ this.licensePlate1Uppercase, this.licensePlate2Uppercase, this.licensePlate3Uppercase ],
            item.subCompany      = this.subCompany,
            item.driverName      = this.driverName
            item.miscellaneous   = this.misc
            item.immediateUse    = this.immediateUse
            item.saveSylt        = this.saveTheCoast

            // locally add 1€ for the save sylt option...
            if (item.saveSylt) {
                item.price += 100
            }

            // remove undefined entries from the list
            item.licensePlates = item.licensePlates.filter(entry => !!entry)

            return item
        },
        handleLicensePlateHws(value) {
            this.handleCloseCustomer()
            // if we assign this value without $nextTick it is overwritten with undefined by the watchers handleCustomerDefaults imediately after
            this.$nextTick(() => this.licensePlate1Uppercase = value)
        }
    },
    watch: {
        ticketSelection() {
            this.saveTheCoast = false
            this.immediateUse = false
        },
        hwsLicensePlate(value) {
            this.handleLicensePlateHws(value)
        },
        'globalState.customer'() {
            this.handleCustomerDefaults()
        }            
    },
    components: {
        CustomerDisplay,
        CustomerSearch,
        CheckboxInput,
        OnSiteSales,
        TextInput,
        PopUp
    }
}
</script>

<style lang="scss" scoped>
// transition order:
// https://stackoverflow.com/questions/18846481/use-transition-on-flexbox-order
// maybe change order of divs with JS to allow for a correct DOM?

.landing-container {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 16px;
    height: calc(100% - 100px);

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > .left, .middle, .right {
        flex: 1;
        min-width: 400px;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        padding: 0 8px;
    }

    > .left {
        max-width: 520px;
    }

    > .right {
        max-width: 500px;

        label {
            user-select: initial;
        }
    }

    .landing-entry {
        width: 100%;
        height: 100%;
        background: #fff;
        box-shadow: 0 2px 8px #00000022;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        > .entry-header {
            padding: 8px;
            padding-bottom: 0;
            display: flex;

            > .entry-title {
                margin: 8px;
                font-weight: bold;
                font-size: 2em;
            }
        }

        > .entry-content {
            // full height minus header
            height: calc(100% - 58px);
            padding: 16px;
            padding-top: 0;
        }
    }
}

.overview {
    background-color: #fff;
    border-radius: 8px;
    height: fit-content;
    box-shadow: 0 2px 8px #00000022;

    > .content-header {
        padding: 8px;
        padding-bottom: 0;
        display: flex;
        justify-content: space-between;

        > .content-title {
            margin: 8px;
            font-weight: bold;
            font-size: 2em;
        }
    }

    > .content {
        padding: 16px;

        &.selection {
            padding: 16px 32px;
        }
    }

    > .content {
        display: flex;
        flex-direction: column;
        height: 100%;

        > .entry {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 1.2em;

            .input-wrapper {
                width: 100%;
            }

            &.paragraph {
                margin-top: 8px;
            }

            &.header {
                font-weight: bold;
            }

            &.price {
                margin-top: 8px;

                > .value {
                    font-weight: bold;
                    font-size: 2em;
                }
            }

            &.optional-info,
            &.license-plates {
                font-size: 0.95em;

                > div {
                    &:not(:first-of-type) {
                        margin-left: 8px;
                    }
                }
            }

            > .description {
                font-weight: bold;
                min-width: 160px;
                text-align: left;
            }

            > .value {
                text-align: right;

                &.info {
                    display: block;
                    height: 60px;
                    overflow-y: auto;
                }

                &.left {
                    text-align: left;
                }
            }
        }

        > .amount {
            display: flex;
            justify-content: space-between;

            > label {
                font-size: 1.2em;
                font-weight: bold;
            }

            > .picker {
                display: flex;
                align-items: center;

                > label {
                    width: 25px;
                    font-size: 1.5em;
                    font-weight: bold;
                    margin: 0 8px;
                }

                > svg {
                    width: 20px;
                    height: 20px;
                    padding: 8px;
                    border-radius: 50%;

                    &:hover {
                        background-color: #00000022;
                        cursor: pointer;
                    }
                }
            }
        }

        > .options {
            margin-top: auto;
            display: flex;
            gap: 16px;

            > div {
                flex: 1;
            }
        }

        > .actions {
            margin-top: 16px;
            display: flex;
            justify-content: flex-end;

            > button:not(:first-of-type) {
                margin-left: 8px;
            }
        }
    }
}

.customer-container {
    height: fit-content;

    &.edit {
        background-color: #f2f9fb;
        border-left: 4px solid #26a;
        padding-left: 0px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    > .entry-header {
        display: flex;
        justify-content: space-between;
    }

    > .entry-content {
        > .section {
            margin-top: 20px;
        }

        > .personal-data,
        > .address  {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            > div {
                width: 49%;

                &.full {
                    width: 100%;
                }
            }
        }

        > .actions {
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;

            > button {
                margin-left: 16px;
            }
        }
    }

    > .options {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > .search-customer,
        > .add-customer {
            width: 75%;
            height: 65px;
            margin: 0 auto;
            margin-top: 20px;
            box-shadow: 0 2px 8px #00000022;
            border-radius: 8px;

            &.search-customer {
                color: #fff;
                //background: #26a;
                background: #ec0016;
            }

            &:hover {
                &,
                * {
                    cursor: pointer;
                }

                background-color: #00000022;

                &.search-customer {
                    //background-color: #2266aacc;
                    background-color: #5a6268;
                }

            }

            > .action {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                > svg {
                    height: 30px;
                    width: 30px;
                }

                > label {
                    font-size: 1.5em;
                    font-weight: bold;
                    margin-left: 12px;
                }
            }
        }
    }
}
</style>