const POSITION = {
    TOP_LEFT:     0,
    TOP_MID:      1,
    TOP_RIGHT:    2,
    BOTTOM_LEFT:  3,
    BOTTOM_MID:   4,
    BOTTOM_RIGHT: 5
}

const LEVEL = {
    INFO:    0,
    WARN:    1,
    SUCCESS: 2,
    ERROR:   3
}

export {
    POSITION,
    LEVEL
}