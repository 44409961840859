import { h, render } from 'vue'

function removeElement(el) {
    if (typeof el.remove !== 'undefined') {
        el.remove()
    } else {
        el.parentNode?.removeChild(el)
    }
}

// const TOAST_PARENT_ID = 'toast-parent'
// const TOAST_PARENT_CLASS_NAME = 'toast-parent'

function addElement(component, props, parentContainer) {
    const vNode = h(component, props)
    const container = document.createElement('div')

    parentContainer.appendChild(container)
    render(vNode, container)

    return vNode.component
}

export {
    addElement,
    removeElement
}