<template>
    <div class="table-wrapper">
        <div class="row">
            <div class="entry header"> Unternehmen </div>
            <div class="entry header"> Fahrernamen </div>
            <div class="entry header"> Gültig von </div>
            <div class="entry header"> Gültig bis </div>
            <div class="entry header" v-if="deletable">  
                <!-- empty div because no header is needed for this column -->
            </div>
        </div>

        <div :class="{'row':true, 'selectable':selectable} " v-for="costCoverage in costCoverages" :key="costCoverage.id" @click="handleSelect(costCoverage)">

            <div class="entry top-line">
                {{ costCoverage.authorisedCompany }}
            </div>
            <div class="entry top-line">
                {{ costCoverage.driver }}
            </div>
            <div class="entry top-line">
                {{ costCoverage.validFrom? util.formatDisplayDate(costCoverage.validFrom): '' }}
            </div>
            <div class="entry top-line">
                {{ costCoverage.validTo? util.formatDisplayDate(costCoverage.validTo): '' }}
            </div>
            <div :class="{'entry':true, 'no-content':!deletable, 'actions':deletable, 'top-line': true}" v-if="deletable">
                <button v-if="deletable" @click.stop="handleDelete(costCoverage)">
                    <fa-icon icon="fa-regular fa-trash-can"/>
                    <label>Löschen</label>
                </button>
            </div>
            <div class="license-plate-row">
                <div class="license-plates-wrapper">
                    <LicensePlateDisplay 
                        class="license-plate" 
                        v-for="(plate, idx) in costCoverage.licensePlates" 
                        :key="idx" 
                        :content="plate.licensePlate" 
                    />                
                </div> 
            </div>
        </div>
    </div>
</template>

<script>
import LicensePlateDisplay from '@elements/license-plate-display/LicensePlateDisplay.vue'

const EMITS = {
    DELETE: 'delete',
    SELECT: 'select',
}

export default {
    name: 'cost-coverages-table',
    inject: ['util'],
    emits: [EMITS.SELECT, EMITS.DELETE],
    data() {
        return {
            color:'yellow'
        }
    },
    props: {
        costCoverages: {
            type: Array,
            required: true
        },
        selectable: {
            type: Boolean,
            default: false
        },
        deletable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        numberOfColumns(){
            return this.deletable ? 5: 4
        },
        gridTemplateColumns() {
            // variable used by style until better solution is found
            if (this.numberOfColumns === 4){
                return 'auto auto auto auto'
            } 
            else if (this.numberOfColumns === 5){
                return 'auto auto auto auto auto'
            }
            else {
                return 'auto'
            }
        }
    },
    methods: {
        handleDelete(costCoverage){
            this.$emit(EMITS.DELETE, costCoverage)
        },
        handleSelect(costCoverage){
            if(this.selectable){
                this.$emit(EMITS.SELECT, costCoverage)
            }
        }
    },
    components: {
        LicensePlateDisplay
    },
}
</script>

<style lang="scss" scoped>
$default-padding: 12px;

.table-wrapper {  
    display: grid;  
    grid-template-columns: v-bind(gridTemplateColumns);

    .row {
        display: contents;

        &:hover.selectable > div:not(.header) {
            background: #00000022;
            cursor: pointer;
        }

        .entry {
            text-align: left;  
            padding: $default-padding;

            &:not(.header) {
                border-top: 1px solid #ccc;
            }

            &.header {
                display: flex;
                color: #666;
                font-size: 1.2em;
                position: sticky;
                border-bottom: 1px solid #ccc;
                top: 0;
                background-color: white;
            }

            &.actions {
                grid-row-end: span 2;
            }

        }
        .license-plate-row {
            grid-column-start: span 4;

            .license-plates-wrapper {
                display: flex;
                flex-wrap:wrap;
                column-gap: 5px;

                .license-plate {
                    font-size: 0.5em;
                    max-width: 120px;
                    padding-bottom: $default-padding;
                }
             }
        }
    }
}
</style>