<template>
    <div class="customer-page-wrapper">
        <div class="customer-info">
            <CustomerDisplay :customer="customer" @save="customer = $event"/>
        </div>

        <div>
            <div class="customer-tab-navigation">
                <div :class="{ 'active' : activeTab === TABS.BOOKINGS }" @click="activeTab = TABS.BOOKINGS">Buchungen</div>
                <div :class="{ 'active' : activeTab === TABS.LICENSE_PLATES_AND_USER_PROFILES }" @click="activeTab = TABS.LICENSE_PLATES_AND_USER_PROFILES">KFZ-Kennzeichen / Kundenprofile</div>
                <div :class="{ 'active' : activeTab === TABS.COST_COVERSGES }" @click="activeTab = TABS.COST_COVERSGES">Kostenübernahmen</div>
            </div>

            <div v-if="activeTab === TABS.BOOKINGS" class="contracts tab">
                <TicketDisplay :tickets="this.bookings" :with-customer="false" v-model:salesDate="bookingsRange" @update:salesDate="getBookings" @update:ticket="handleTicketUpdate"/>
            </div>

            <div v-if="activeTab === TABS.LICENSE_PLATES_AND_USER_PROFILES" class="license-plates-and-user-profiles tab">
                <div>
                    <h3>KFZ-Kennzeichen</h3>
                    <LicensePlatesDisplay :license-plates="licensePlates" @add="addLicensePlate" @remove="removeLicensePlate"/>
                </div>
                <div>
                    <h3>Kundenprofile</h3>
                    <UserProfilesDisplay :disabled="!allowProfileEdit" class="user-profiles" :options="profileOptions" :user-profiles="userProfiles" @add="addProfileToUser" @remove="removeProfileFromUser"/>
                </div>
            </div>
            <div v-if="activeTab === TABS.COST_COVERSGES" class="contracts tab">
                <CostCoveragesTableWrapper 
                    :deletable="true" 
                    :editable="true"
                    :uuidCustomer="customer.uuid"
                    :cost-coverages="costCoverages" 
                    @delete="handleDeleteCostCoverage"
                    @update="handleUpdateCostCoverage"
                    @add="handleAddCostCoverage"
                />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

import TicketDisplay from '@components/TicketDisplay.vue'
import CustomerDisplay from '@components/CustomerDisplay.vue'
import UserProfilesDisplay from '@components/UserProfilesDisplay.vue'
import LicensePlatesDisplay from '@components/LicensePlatesDisplay.vue'
import CostCoveragesTableWrapper from '@components/CostCoveragesTableWrapper.vue'

const TABS = {
    BOOKINGS: 0,
    LICENSE_PLATES_AND_USER_PROFILES: 1,
    COST_COVERSGES: 2
}

export default {
    name: 'customer-page',
    inject: [ 'util', 'globalState', 'keycloak'],
    beforeMount() {
        if (!this.globalState.customer) {
            this.$router.push(this.$route.meta.parentPath)
        }
    },
    data() {
        return {
            TABS,
            activeTab: 0,
            withInvalidBookings: false,
            bookings: [],
            bookingsRange: [],
            profileOptions: [],
            userProfiles: [],
            licensePlates: [],
            costCoverages: []
        }
    },
    computed: {
        allowProfileEdit() {
            return this.keycloak.instance?.permissions?.includes('customer-profiles')
        },
        customer: {
            get() {
                return this.globalState.customer
            },
            set(customer) {
                this.globalState.customer = customer
            }
        },

        bookingsFrom() {
            return new Date(this.bookingsRange[0].year, this.bookingsRange[0].month)
        },
        bookingsTo() {
            if (JSON.stringify(this.bookingsRange[0]) === JSON.stringify(this.bookingsRange[1])) {
                return new Date(this.bookingsRange[1].year, this.bookingsRange[1].month + 1)
            } else {
                return new Date(this.bookingsRange[1].year, this.bookingsRange[1].month + 1, 0)
            }

        }
    },
    watch: {
        customer() {
            this.init()
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            this.bookingsRange = this.getMonthRange(6)
            this.getBookings()
            this.initProfiles()
            this.initLicensePlates()
            this.initCostCoverages()
        },
        async getBookings() {
            let ticketsUrl = this.util.middleware()

            ticketsUrl += '/ticketing/tickets/' + encodeURIComponent(this.customer.customerReference.replaceAll('+', '-').replaceAll('/', '_'))
            ticketsUrl += '?from=' + encodeURIComponent(this.bookingsFrom.toISOString().slice(0, 19) + '+02:00')
            ticketsUrl += '&to=' + encodeURIComponent(this.bookingsTo.toISOString().slice(0, 19) + '+02:00')
            ticketsUrl += '&withDisabled=' + this.withInvalidBookings
            ticketsUrl += '&withInvalid=' +  this.withInvalidBookings

            return axios.get(ticketsUrl)
            .then(response => {
                this.bookings = response.data.sort((a,b) => {
                    // newest first, by created date
                    const aTime = new Date(a.created).getTime()
                    const bTime = new Date(b.created).getTime()
                    return bTime - aTime
                })
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async initCostCoverages(){
            this.costCoverages = await this.getCostCoverages(this.customer.uuid)
        },
        async getCostCoverages(uuidCustomer){
            let url = this.util.middleware()
            url += '/costcoverage'
            
            return axios.get(url, {params: {externalReference: uuidCustomer}})
            .then((response) => {
                return response.data                    
            }).catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async initLicensePlates() {
            try {
                // update the current customer directly
                this.customer.licensePlates = await this.getLicensePlates()
                this.licensePlates          = this.customer.licensePlates.map(entry => entry.value)
            } catch (err) {
                this.$toast.error(err)
            }
        },
        async getLicensePlates() {
            let licensePlatesUrl = this.util.middleware()
            licensePlatesUrl += '/user-profiles/' + this.customer.npsUserId
            licensePlatesUrl += '/license-plates'

            return axios.get(licensePlatesUrl)
            .then(response => {
                return response.data
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async addLicensePlate(licensePlate) {
            let licensePlateUrl = this.util.middleware()
            licensePlateUrl += '/user-profiles/' + this.customer.npsUserId
            licensePlateUrl += '/license-plates'

            const licensePlateBody = {
                licensePlate
            }

            return axios.post(licensePlateUrl, licensePlateBody)
            .then(() => {
                this.initLicensePlates()
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async removeLicensePlate(licensePlate) {
            let licensePlateUrl = this.util.middleware()
            licensePlateUrl += '/user-profiles/' + this.customer.npsUserId
            licensePlateUrl += '/license-plates/' + licensePlate

            return axios.delete(licensePlateUrl)
            .then(() => {
                this.initLicensePlates()
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async initProfiles() {
            try {
                this.profileOptions = await this.getProfileOptions()
                // update the current customer directly
                this.customer.profileSettings = await this.getUserProfileSettings()

                this.userProfiles = this.customer.profileSettings.map(profile => {
                    return {
                        displayid: profile.id,
                        displayname: `${profile.name} (Gültig bis: ${this.util.formatDisplayDate(profile.validUntil)})`,
                        original: this.profileOptions.find(option => option.original.id === profile.id)?.original
                    }
                })
            } catch (err) {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            }
        },
        async getUserProfileSettings() {
            let userProfileUrl = this.util.middleware()
            userProfileUrl += '/user-profiles/' + this.customer.npsUserId
            userProfileUrl += '/settings'

            return axios.get(userProfileUrl)
            .then(response => {
                return response.data
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async getProfileOptions() {
            let optionsUrl = this.util.middleware()
            optionsUrl += '/configuration/profiles'

            const optionsResponse = await axios.get(optionsUrl)

            return optionsResponse.data.map(profile => {
                return {
                    displayid:   profile.id,
                    displayname: profile.name,
                    original:    profile
                }
            })
        },
        async addProfileToUser(profileOption) {
            let addUrl = this.util.middleware()
            addUrl += '/user-profiles/' + this.customer.npsUserId
            addUrl += '/settings'

            return axios.post(addUrl, profileOption)
            .then(() => {
                return this.initProfiles()
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async removeProfileFromUser(profileOption) {
            let addUrl = this.util.middleware()
            addUrl += '/user-profiles/' + this.customer.npsUserId
            addUrl += '/settings/' + profileOption.id

            return axios.delete(addUrl, { data: profileOption })
            .then(() => {
                return this.initProfiles()

            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        handleTicketUpdate(ticket) {
            const idx = this.bookings.findIndex((element) => element.orderId == ticket.orderId)
                if (idx > -1) {
                    this.bookings.splice(idx, 1, ticket)
                }
        },
        getMonthRange(numberOfMonths) {
            const today = new Date()
        
            const currentMonth = today.getMonth()
            const currentYear = today.getFullYear()

            const startDate = new Date()
            startDate.setMonth(currentMonth - numberOfMonths)
            const endDate = new Date(currentYear, currentMonth + 1, 0)

            const startRange = { month: startDate.getMonth(), year: startDate.getFullYear() }
            const endRange   = { month: endDate.getMonth(),   year: endDate.getFullYear() }

            return [ startRange, endRange ]
        },
        async handleDeleteCostCoverage(costCoverage) {
            await this.deleteCostCoverage(costCoverage.idCostCoverage)
            await this.initCostCoverages()
        },
        async deleteCostCoverage(idCostCoverage){
            let url = this.util.middleware()
            url += '/costcoverage'
            url += '/' + idCostCoverage
            return axios.delete(url)
            .then((response) => {
                return response.data                    
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async handleUpdateCostCoverage(costCoverage) {
            await this.updateCostCoverage(costCoverage)
            await this.initCostCoverages()
        },
        async updateCostCoverage(costCoverage){
            let url = this.util.middleware()
            url += '/costcoverage'

            const body = {
                costCoverage: costCoverage
            }

            return axios.put(url, body)
            .then((response) => {
                return response.data                    
            })
        },
        async handleAddCostCoverage(costCoverage) {
            await this.addCostCoverage(costCoverage)
            await this.initCostCoverages()
        },
        async addCostCoverage(costCoverage){
            let url = this.util.middleware()
            url += '/costcoverage'
            
            const body = {
                costCoverage
            }

            return axios.post(url, body)
            .then((response) => {
                return response.data                    
            })
        },
    },
    components: {
        CustomerDisplay,
        TicketDisplay,
        LicensePlatesDisplay,
        UserProfilesDisplay,
        CostCoveragesTableWrapper
    }
}
</script>

<style lang="scss" scoped>
.customer-page-wrapper {
    display: flex;
    flex-direction: row;

    > div:first-of-type {
        width: 490px;
    }

    > div:last-of-type {
        margin-left: 20px;
        flex: 1;
    }
}

.customer-info {
    // width: 100%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px #00000022;
    position: relative;

    // :deep(.header) {
    //     > .title {
    //         margin-left: 100px;
    //     }
    // }
}

.customer-tab-navigation {
    display: flex;
    // margin-top: 24px;
    margin-left: 8px;

    > div {
        padding: 4px 8px;
        font-size: 1.5em;

        &:not(:first-child) {
            margin-left: 24px;
        }

        &:hover {
            cursor: pointer;
            border-bottom: 2px solid #f0141433;
        }

        &.active {
            border-bottom: 2px solid #f01414;
        }
    }
}

.filter-input {
    margin-right: 20px;
    width: 200px;
    display: flex;
    flex-direction: row;
    padding: 0 16px;
    justify-content: top;
    align-items: center;
}


.tab {
    margin-top: 12px;
    background-color: #fff;
    border-radius: 8px;
    padding: 16px;
    min-height: 300px;
    height: calc(100% - 48px);
    box-shadow: 0 2px 4px #00000022;
    flex: 1;

    &.edit {
        background-color: #f2f9fb;
        border-left: 4px solid #26a;
        padding-left: 12px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &.license-plates-and-user-profiles {
        display: flex;
        justify-content: space-between;

        > div {
            flex: 1;

            > .license-plates {
                margin-right: 8px;
            }

            > .user-profiles {
                margin-left: 8px;
                margin-top: 20px;
            }

            > h3 {
                margin: 0;
                text-align: left;
                margin-bottom: 8px;
            }
        }
    }
}
</style>
