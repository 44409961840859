/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import * as solid from '@fortawesome/free-solid-svg-icons'

import * as regular from '@fortawesome/free-regular-svg-icons'

const installedIcons = [
    solid.faArrowRight,
    solid.faUpRightFromSquare,
    solid.faSearch,
    solid.faXmark,
    solid.faAngleDown,
    solid.faCopy,
    solid.faArrowsRotate,
    solid.faClockRotateLeft,
    solid.faArrowRotateLeft,
    solid.faPlus,
    solid.faMinus,
    solid.faCartShopping,
    solid.faCheck,
    solid.faArrowRightFromBracket,
    solid.faPlay,
    solid.faShopLock,
    solid.faSackDollar,
    solid.faPrint,
    solid.faBan,
    solid.faFileInvoice,
    solid.faMoneyBill1Wave,
    solid.faCreditCard,
    solid.faReceipt,
    solid.faEllipsis,
    solid.faKey,
    solid.faGear,

    regular.faUser,
    regular.faPenToSquare,
    regular.faCircleXmark,
    regular.faFloppyDisk,
    regular.faAddressCard,
    regular.faTrashCan,
    regular.faIdBadge,
    regular.faCircleXmark,
    regular.faMoneyBill1,
    regular.faFileLines,
]

/* add icons to the library */
library.add(...installedIcons)

export default FontAwesomeIcon