<template>
    <div class="user-profiles-display-container">
        <MultiSelectInput :disabled="disabled" class="profile-select" :event-only="true" @remove="option => removeProfileFromUser(option.original)" @select="option => handleOpenAddProfile(option)" :options="options" :model-value="userProfiles"/>

        <PopUp class="add-profile" title="Neues Profil hinzufügen" :open="addProfile.show"  @close="handleCloseAddProfile">
            <h3>Bitte geben Sie die Gültigkeit für das neue Profil an:</h3>

            <div class="validity">
                <TextInput label="Gültig von" type="date" v-model="addProfile.from"/>
                <TextInput label="Gültig bis" type="date" v-model="addProfile.to"/>
            </div>

            <template v-slot:actions>
                <div class="actions">
                    <button class="highlight" @click="handleAddProfile">
                        <fa-icon icon="fa-solid fa-plus"/>
                        <label>Profil hinzufügen</label>
                    </button>
                    <button @click="handleCloseAddProfile">
                        <fa-icon icon="fa-solid fa-xmark"/>
                        <label>Abbrechen</label>
                    </button>
                </div>
            </template>
        </PopUp>
    </div>
</template>

<script>
import PopUp from '@elements/PopUp.vue'
import TextInput from '@elements/TextInput.vue'
import MultiSelectInput from '@elements/MultiSelectInput.vue'

const EMIT_REMOVE = 'remove'
const EMIT_ADD    = 'add'

export default {
    name: 'user-profiles-display',
    emits: [ EMIT_REMOVE, EMIT_ADD ],
    inject: [ 'util' ],
    props: {
        options: {
            type: Array, // { displayid: id, displayname: name, orginal: original }
            required: true
        },
        userProfiles: {
            type: Array // { displayid: id, displayname: name, orginal: original }
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            addProfile: {
                show:    false,
                from:    undefined,
                to:      undefined,
                profile: undefined
            }
        }
    },
    methods: {
        handleOpenAddProfile(selectedOption) {
            this.addProfile.profile = selectedOption.original
            this.addProfile.from    = new Date().toISOString().substr(0, 10)
            this.addProfile.to      = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().substr(0, 10)
            this.addProfile.show    = true
        },
        handleCloseAddProfile() {
            this.addProfile.profile = undefined
            this.addProfile.show    = false
        },
        handleAddProfile() {
            if (!this.addProfile.from || !this.addProfile.to) {
                this.$toast.warn('Bitte geben Sie die Gültigkeit vollständig an')
                return
            }

            const newProfile      = this.addProfile.profile
            newProfile.validFrom  = this.addProfile.from
            newProfile.validUntil = this.addProfile.to

            this.addProfileToUser(newProfile)
            this.handleCloseAddProfile()
        },
        removeProfileFromUser(profileOption) {
            this.$emit(EMIT_REMOVE, profileOption)
        },
        addProfileToUser(profileOption) {
            this.$emit(EMIT_ADD, profileOption)
        }
    },
    components: {
        MultiSelectInput,
        PopUp,
        TextInput
    }
}
</script>

<style lang="scss" scoped>
.user-profiles-display-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 4px;
}

.add-profile {
    :deep(.content) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 1.2em;
        min-width: 30em;

        > h3 {
            margin: 12px auto 20px auto;
        }

        > .validity {
            width: 100%;
            display: flex;
            justify-content: space-around;
        }
    }

    .actions {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;

        > button {
            margin-left: 16px;

            &.highlight {
                background-color: red;

                &:disabled {
                    background-color: #ff000066;
                }

                &:not(:disabled):hover {
                    background-color: #ff000099;
                }
            }
        }
    }
}
</style>