<template>
    <div class="shift-management-wrapper">
        <div class="entry shift-container">
            <div class="entry-header">
                <label class="entry-title">Aktuelle Schicht</label>
            </div>

            <div class="content">
                <div class="shift-info">
                    <div class="info">
                        <label>Nummer: </label>
                        <label v-if="shiftData.id">{{ shiftData.id }}</label>
                        <label v-else><i>Keine Schicht aktiv</i></label>
                    </div>
                    <div class="info">
                        <label>Geöffnet: </label>
                        <label v-if="shiftData.id">{{ loginDateTime }} Uhr</label>
                        <label v-else><i>Keine Schicht aktiv</i></label>
                    </div>
                    <div class="info">
                        <label>Ausgabestelle: </label>
                        <label v-if="shiftData.id">{{ deviceName }}, {{ locationName }}</label>
                        <label v-else><i>Keine Schicht aktiv</i></label>
                    </div>
                </div>

                <div class="cart-wrapper">
                    <h3>Verkäufe</h3>
                    <TextInput label="Suche..." v-model="cartFilter"/>
                    <div class="cart-list">
                        <div class="cart" v-for="(cart, idx) in filteredCarts" :key="idx">
                            <div class="left">
                                <label class="time">{{ cart.formattedTime }} Uhr</label>
                            </div>
                            <div class="center">
                                <div v-for="(transaction, idx) in cart.transactions" :key="idx">
                                    <label class="product-info">{{ transaction.productNameLong }} {{ transaction.productNameShort ? '(' + transaction.productNameShort + ')' : '' }}</label>
                                    <a class="ticket-info" v-if="transaction.showAdditionalReference" @click="searchTicketId(transaction.additionalReference)">{{ transaction.additionalReference }}</a>
                                </div>
                            </div>
                            <div class="right">
                                <label>{{ util.formatCurrency(cart.sum) }} €</label>
                                <label>{{ cart.displayPayment }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="shift-amount">
                    <h3>Schichteinnahmen</h3>
                    <label>{{ util.formatCurrency(cartSum) }} €</label>
                </div>

                <div class="cash-amount">
                    <h4>davon Bareinnahmen</h4>
                    <label>{{ util.formatCurrency(cartSumCash) }} €</label>
                </div>
                <div class="cash-amount">
                    <h4>davon Rechnungen</h4>
                    <label>{{ util.formatCurrency(cartSumInvoice) }} €</label>
                </div>
                <div class="cash-amount">
                    <h4>davon Lastschriften</h4>
                    <label>{{ util.formatCurrency(cartSumDirectDebit) }} €</label>
                </div>
                <div class="cash-amount">
                    <h4>davon externe Zahlungsdienstleister</h4>
                    <label>{{ util.formatCurrency(cartSumExternal) }} €</label>
                </div>

                <div class="actions">
                    <button class="highlight" @click="showSafebagPickup = true" :disabled="!shiftData.id">
                        <fa-icon icon="fa-solid fa-print"/>
                        <label>Safebagabholliste</label>
                    </button>
                    <button class="highlight button-left-part" @click="handlePrintReport(true)" :disabled="!shiftData.id">
                        <fa-icon icon="fa-solid fa-print"/>
                        <label>Vorläufiger Schichtabschluss</label>
                    </button>
                    <button class="highlight button-right-part" @click="handlePrintReport(false)" :disabled="!shiftData.id">
                        <label>mit Verkäufen</label>
                    </button>
                    <button class="highlight" @click="triggerShiftLogout" :disabled="!shiftData.id">
                        <fa-icon icon="fa-solid fa-shop-lock"/>
                        <label>Schichtabschluss und abmelden</label>
                    </button>
                </div>
            </div>
        </div>

        <div class="entry safebag-container">
            <div class="entry-header">
                <label class="entry-title">Safebags</label>
            </div>

            <div class="content">
                <div class="all-safebags">
                    <h3 v-if="shiftData.id">Safebags der aktuellen Schicht {{ shiftData.id }}</h3>
                    <h3 v-else><i>Keine Schicht aktiv</i></h3>
                    <div class="safebag" v-for="(safebag, idx) in shiftData.safebags" :key="idx">
                        <div class="left">
                            <label>{{ util.formatCurrency(safebag.sum) }} €</label>
                            <label>{{ safebag.no }}</label>
                        </div>
                        <div class="center">
                            <label>Datum</label>
                            <label>{{ safebag.formattedDate }}</label>
                        </div>
                        <div class="right">
                            <button @click="editSafebag(safebag)">
                                <fa-icon icon="fa-regular fa-pen-to-square"/>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="current-amount">
                    <h3>Aktueller Schichtbestand</h3>
                    <div class="entry">
                        <label class="amount">{{ util.formatCurrency(safebagSum) }} €</label>
                    </div>
                </div>

                <div class="current-safebag">
                    <h2>Neuer Safebag</h2>
                    
                    <div class="entry">
                        <h3>Offene Barsumme:</h3>
                        <label class="amount">{{ util.formatCurrency(remainingSafebagSum) }} €</label>
                    </div>

                    <div class="entry">
                        <TextInput v-model="currentSafebag.no" label="Nummer..."/>
                    </div>

                    <div class="entry">
                        <TextInput v-model="currentSafebag.sum" label="Betrag..."></TextInput>
                    </div>
                </div>

                <div class="actions">
                    <button class="highlight" @click="showCommitSafebag = true" :disabled="!shiftData.id || !currentSafebag.no || !currentSafebag.sum">
                        <fa-icon icon="fa-solid fa-sack-dollar" />
                        <label>Safebag Buchen</label>
                    </button>
                </div>
            </div>
        </div>

        <PopUp :open="showSafebagPickup" title="Abholvorgang anstoßen" @close="pickupSafebags" submitLabel="Ja" cancelLabel="Nein">
            <label>Wollen Sie wirklich den Abholvorgang für die Safebags anstoßen?</label>
        </PopUp>

        <PopUp :open="showCommitSafebag" title="Safebag speichern?" @close="saveSafebag" submitLabel="Ja" cancelLabel="Nein">
            <label>Wollen Sie den Safebag wirklich speichern?</label>
        </PopUp>

        <PopUp class="close-shift" :open="showLogoutShift" title="Schicht beenden?" @close="logoutShift" submitLabel="Ja" cancelLabel="Nein" :disabledSubmit="!isDataCorrect">
            <div v-if="!safebagsComplete" class="warning">
                <label>Achtung!!! In den Safebagsummen fehlen {{ util.formatCurrency(cartSumCash - safebagSum) }} €.</label>
            </div>
            <CheckboxInput class="checkbox-input-data-correctness" text="die gemachten Angaben sind korrekt" v-model="isDataCorrect" />
            <div class="print-options-wrapper">
                <CheckboxInput text="Schichtausdruck erstellen" v-model="logoutWithPrint" />
                <CheckboxInput class="option-with-sales" :disabled="!logoutWithPrint" text="Ausdruck inkl. Verkäufe" v-model="logoutWithSales" />
            </div>
            <div class="logout-shift-question">
                <label>Wollen Sie die Schicht wirklich beenden?</label>
            </div>
        </PopUp>

        <PopUp :open="showEditSafebag" title="Safebag bearbeiten" @close="handleEditSafebag" submitLabel="Speichern" cancelLabel="Abbrechen">
            <div class="entry">
                <TextInput v-model="editingSafebag.no" label="Nummer"/>
            </div>
            <div class="entry">
                <TextInput v-model="editingSafebag.sum" label="Betrag" @focus="$event.target.select()"/>
            </div>
        </PopUp>

        <PopUp class="ticket-overview" title="Ticketübersicht" :open="selectedTickets.length > 0" @close="selectedTickets = []">
            <TicketDisplay class="ticket-content" :tickets="selectedTickets" :withCustomer="true" @update:selectedCustomer="handleCustomerSelection" @update:ticket="handleTicketUpdate"/>

            <template v-slot:actions>
                <div class="actions">
                    <button @click="selectedTickets = []">
                        <fa-icon icon="fa-solid fa-xmark" src="@img/xmark.svg" />
                        <label>Abbrechen</label>
                    </button>
                </div>
            </template>
        </PopUp>
    </div>
</template>

<script>
import PopUp from '@elements/PopUp.vue'
import TicketDisplay from '@components/TicketDisplay.vue'
import TextInput from '@elements/TextInput.vue'
import { getCustomer, addCustomerToTicket } from '@lib/middleware-connector'
import axios from 'axios'
import CheckboxInput from '@components/elements/CheckboxInput.vue'

const BACKEND_PAYMENT_METHOD = {
    TERMINAL:     11,
    CASH:         22,
    INVOICE:      25,
    DIRECT_DEBIT: 26
}

export default {
    name: "shift-management-page",
    inject: [ 'util', 'session', 'keycloak', 'hws', 'globalState' ],
    data() {
        return {
            shiftData: {
                id: 0,
                uid: "",
                deviceId: 0,
                staffId: 0,
                loginTs: 0,
                logoutTs: 0,
                creationTs: 0,
                carts: [],
                safebags: []
            },
            selectedTickets: [],
            showSafebagPickup: false,
            showCommitSafebag: false,
            showLogoutShift: false,
            logoutWithSales: false,
            logoutWithPrint: false,
            showEditSafebag: false,
            editingSafebag: {
                no: undefined,
                sum: undefined
            },
            currentSafebag: {
                no: undefined,
                sum: undefined
            },
            cartFilter: undefined,
            isDataCorrect: false,
        }
    },
    mounted() {
        this.getCurrentShiftData()
        .catch(error => {
            console.log(error)
        })
    },
    computed: {
        shift() {
            return this.session.shift
        },
        hwsData() {
            return this.hws.data;
        },
        locationName() {
            return this.shift.location?.displayname
        },
        deviceName() {
            return this.shift.device?.displayname
        },
        loginDateTime() {
            const date = new Date(this.shiftData.loginTs)
            return `${this.util.formatDisplayDate(date)} - ${this.util.formatDisplayTime(date)}`
        },
        filteredCarts() {
            if (!this.cartFilter) {
                return this.shiftData.carts
            }

            return this.shiftData.carts?.filter(entry => {
                const amountFilter  = entry.sum.toString().includes(this.cartFilter?.toLowerCase())
                const dateFilter    = entry.formattedDate.includes(this.cartFilter?.toLowerCase())
                const timeFilter    = entry.formattedTime.includes(this.cartFilter?.toLowerCase())
                const productFilter = entry.transactions.find(entry => {
                    return entry.productName.toLowerCase().includes(this.cartFilter?.toLowerCase())
                })
                const addRefFilter  = entry.transactions.find(entry => {
                    return entry.additionalReference.toLowerCase().includes(this.cartFilter?.toLowerCase())
                })

                return amountFilter || dateFilter || timeFilter || productFilter || addRefFilter
            })
        },
        cartSum() {
            if (this.shiftData.carts.length > 0) {
                return this.shiftData.carts.map(entry => entry.sum).reduce((a, b) => a + b)
            } else {
                return 0
            }
        },
        cartSumCash() {
            if (this.shiftData.carts.length > 0) {
                return this.shiftData.carts.map((entry) => {
                    if (entry.payments.length > 0) {
                        return entry.payments.map((entry) => {
                            if (entry.method == BACKEND_PAYMENT_METHOD.CASH) {
                                return entry.sum
                            } else {
                                return 0
                            }
                        }).reduce((a, b) => a + b)
                    } else {
                        return 0
                    }
                }).reduce((a, b) => a + b)
            } else {
                return 0
            }
        },
        cartSumInvoice() {
            if (this.shiftData.carts.length > 0) {
                return this.shiftData.carts.map((entry) => {
                    if (entry.payments.length > 0) {
                        return entry.payments.map((entry) => {
                            if (entry.method == BACKEND_PAYMENT_METHOD.INVOICE) {
                                return entry.sum
                            } else {
                                return 0
                            }
                        }).reduce((a, b) => a + b)
                    } else {
                        return 0
                    }
                }).reduce((a, b) => a + b)
            } else {
                return 0
            }
        },
        cartSumDirectDebit() {
            if (this.shiftData.carts.length > 0) {
                return this.shiftData.carts.map((entry) => {
                    if (entry.payments.length > 0) {
                        return entry.payments.map((entry) => {
                            if (entry.method == BACKEND_PAYMENT_METHOD.DIRECT_DEBIT) {
                                return entry.sum
                            } else {
                                return 0
                            }
                        }).reduce((a, b) => a + b)
                    } else {
                        return 0
                    }
                }).reduce((a, b) => a + b)
            } else {
                return 0
            }
        },
        cartSumExternal() {
            return this.cartSum - (this.cartSumCash + this.cartSumInvoice + this.cartSumDirectDebit)
        },
        safebagSum() {
            if (this.shiftData.safebags.length > 0) {
                return this.shiftData.safebags.map(entry => entry.sum).reduce((a, b) => a + b)
            } else {
                return 0
            }
        },
        safebagsComplete() {
            return this.safebagSum >= this.cartSumCash
        },
        remainingSafebagSum() {
            return Math.max(this.cartSumCash - this.safebagSum, 0)
        }
    },
    methods: {
        triggerShiftLogout() {
            this.isDataCorrect = false
            this.showLogoutShift = true
        },
        async triggerHwsEndOfDay(){
            if (this.hwsData.status == 0) {
                return await this.hws.initEndOfDay()
                .catch(err => {
                    this.$toast.error(err.message || err.errorMessage || err)
                })
            }
        },
        getShiftServerDisplayPaymentMethod(paymentMethod) {
            switch (paymentMethod) {
                case BACKEND_PAYMENT_METHOD.CASH :
                    return 'Bar'
                case BACKEND_PAYMENT_METHOD.INVOICE :
                    return 'Rechnung'
                case BACKEND_PAYMENT_METHOD.DIRECT_DEBIT :
                    return 'Lastschrift'
                case BACKEND_PAYMENT_METHOD.TERMINAL :
                    return 'Terminal'
                default:
                    return undefined
            }
        },
        async tryGetTicketData(ticketId){
            let dataUrl = this.util.middleware()
            dataUrl += '/ticketing/ticket'
            dataUrl += '/' + ticketId

            return axios.get(dataUrl)
            .then((response) => {
                return response.data
            })
        },
        editSafebag(safebag) {
            this.editingSafebag.originalNo = safebag.no
            this.editingSafebag.no = safebag.no
            this.editingSafebag.timestamp = safebag.timestamp
            this.editingSafebag.sum = this.util.formatCurrency(safebag.sum)
            this.showEditSafebag = true
        },
        async handleEditSafebag(doSave) {
            this.showEditSafebag = false
            if (doSave) {
                const newSafebag = {
                    id: 0,
                    reference: '',
                    no: this.editingSafebag.no,
                    timestamp: this.editingSafebag.timestamp,
                    sum: Math.floor(parseFloat(this.editingSafebag.sum.replace(',', '.')) * 100)
                }

                this.saveEditedSafebag(this.editingSafebag.originalNo, newSafebag)
            }
        },
        async saveEditedSafebag(originalNo, safebag) {
            let dataUrl = this.util.middleware()
            dataUrl += '/shifts/safebags'
            dataUrl += '/' + originalNo

            await axios.patch(dataUrl, safebag)
            .then(() => {
                return this.getCurrentShiftData()
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        handleTicketUpdate(ticket) {
            const index = this.selectedTickets.findIndex((data) => ticket.orderId === data.orderId)
            if (index > -1) {
                    this.selectedTickets.splice(index, 1, ticket)
                }
        },
        async searchTicketId(ticketId){
            this.selectedTickets = []

            this.tryGetTicketData(ticketId)
            .then(async (ticket) => {
                ticket = await addCustomerToTicket(ticket)                
                this.selectedTickets = [ticket]
            })
        },
        async handleCustomerSelection(customerSearchResult) {
            if (customerSearchResult) {
                try {
                    const customer = await getCustomer(customerSearchResult.uuid)
                    Object.assign(this.globalState, { customer })
                    this.selectedTickets = []
                } catch(err){
                    this.$toast.error(err.response?.data?.userMessage || err.message)
                    return null
                }
            }
        },               
        async getCurrentShiftData() {
            const shift = this.session.shift
            const deviceId = shift.device.displayid
            const staffId = shift.staffNr

            this.currentSafebag = {
                no: undefined,
                sum: undefined
            }

            let dataUrl = this.util.middleware()
            dataUrl += '/shifts/devices/' + deviceId
            dataUrl += '/staff/' + staffId
            dataUrl += '/data'

            return axios.get(dataUrl)
            .then(response => {
                return response.data
            })
            .then(shiftData => {
                shiftData.carts = shiftData.carts.map(item => {
                    const date = new Date(item.timestamp)
                    item.formattedDate  = this.util.formatDisplayDate(date)
                    item.formattedTime  = this.util.formatDisplayTime(date)

                    item.transactions = item.transactions.map((transaction, index) => {
                        // Only show the first occurence of an ticketId
                        transaction.showAdditionalReference = !!transaction.additionalReference
                        transaction.showAdditionalReference &&= item.transactions.findIndex(data => data.additionalReference === transaction.additionalReference) == index

                        return transaction
                    })

                    let payments = item.payments.map((payment) => {
                        return this.getShiftServerDisplayPaymentMethod(payment.method)
                    })
                    payments = payments.filter((item, idx) => payments.indexOf(item) == idx) // Remove duplicates
                    item.displayPayment = payments.join(', ')
                    
                    return item
                })
                shiftData.safebags = shiftData.safebags.map(item => {
                    const date = new Date(item.timestamp)
                    item.formattedDate = this.util.formatDisplayDate(date)
                    item.formattedTime = this.util.formatDisplayTime(date)

                    return item
                })
                this.shiftData = shiftData
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        pickupSafebags(doPickup){
            this.showSafebagPickup = false
            if (doPickup) {
                try {
                    this.printSafebagPickupList()
                } catch(err) {
                    this.$toast.error(err.response?.data?.userMessage || err.message)
                }
            }
        },
        saveSafebag(doSave) {
            this.showCommitSafebag = false
            if (doSave) {
                const shift = this.session.shift
                const deviceId = shift.device.displayid
                const staffId = shift.staffNr
                const newSafebag = {
                    id: 0,
                    reference: '',
                    no: this.currentSafebag.no,
                    timestamp: new Date().getTime(),
                    sum: Math.floor(parseFloat(this.currentSafebag.sum.replace(',', '.')) * 100)
                }

                let dataUrl = this.util.middleware()
                dataUrl += '/shifts/devices/' + deviceId
                dataUrl += '/staff/' + staffId
                dataUrl += '/safebag'

                axios.post(dataUrl, newSafebag)
                .then(() => {
                    return this.getCurrentShiftData()
                })
                .catch(err => {
                    this.$toast.error(err.response?.data?.userMessage || err.message)
                })
            }
        },
        async printSafebagPickupList() {
            const shift = this.session.shift
            const deviceId = shift.device.displayid
            const staffId = shift.staffNr

            let dataUrl = this.util.middleware()
            dataUrl += '/shifts/devices/' + deviceId
            dataUrl += '/staff/' + staffId
            dataUrl += '/safebagpickup'

            const response = await axios.get(dataUrl, {
                responseType: 'arraybuffer'
            })

            this.sendReportDataToHws(response.data, 'Safebagabholliste', response.headers['content-type'])
        },
        async sendReportDataToHws(reportData, reportName, contentType) {
            if (this.hwsData.status == 0) {
                const img = new Uint8Array(reportData)
                return this.hws.printImage(img)
            } else {
                const file    = new File([reportData], reportName, {type: contentType})
                const url     = URL.createObjectURL(file)
                window.open(url)
            }
        },
        async handlePrintReport(excludeSales) {
            try {
                this.printReport(excludeSales)
            } catch(err) {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            }
        },
        async printReport(excludeSales) {
            const shift    = this.session.shift
            const deviceId = shift.device.displayid
            const staffId  = shift.staffNr

            let dataUrl = this.util.middleware()
            dataUrl += '/shifts/devices/' + deviceId
            dataUrl += '/staff/' + staffId
            dataUrl += '/report'

            const response = await axios.get(dataUrl, {
                responseType: 'arraybuffer',
                params: {
                    format: 'img',
                    nosales: excludeSales
                }
            })

            return this.sendReportDataToHws(response.data, 'Schichtabschluss', response.headers['content-type'])
        },
        async sendLogout(){
            await this.triggerHwsEndOfDay()

            const shift = this.session.shift
            const deviceId = shift.device.displayid
            const staffId = shift.staffNr

            let dataUrl = this.util.middleware()
            dataUrl += '/shifts/devices/' + deviceId
            dataUrl += '/staff/' + staffId
            dataUrl += '/logout'

            return axios.get(dataUrl, {
                responseType: 'arraybuffer',
                params: {
                    format: 'img',
                    nosales: !this.logoutWithSales
                }
            })
            .then((response) => {
                if(this.logoutWithPrint){
                    return this.sendReportDataToHws(response.data, 'Schichtabschluss', response.headers['content-type']) 
                }
            })
        },
        async doLogout(){
            return this.sendLogout()
            .then(() => {
                this.keycloak.instance.logout()
            })
        },
        async logoutShift(doEnd) {
            this.showLogoutShift = false
            if (doEnd) {
                return this.doLogout()
                .catch(err => {
                    this.$toast.error(err.response?.data?.userMessage || err.message)
                })
            }
        }
    },
    components: {
        TicketDisplay,
        TextInput,
        PopUp,
        CheckboxInput
    }
}
</script>

<style scoped lang="scss">
.shift-management-wrapper {
    display: flex;

    > .close-shift {
        :deep(.content) {
            > .warning {
                color: red;
                font-size: 1.5em;
                padding-bottom: 30px;
            }
            > .checkbox-input-data-correctness {
                padding-top: 0px;
                padding-bottom: 5px;
            }
            > .print-options-wrapper {
                display: flex;
                flex-direction: column;
                > .option-with-sales {
                    padding-top: 0px;
                    padding-left: 50px;
                }
            }
            > .logout-shift-question {
                padding-top: 20px;
                text-align: left;
            }
        }
    }

    > .entry {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 8px #00000022;
        text-align: left;
        height: 100%;

        &.shift-container {
            flex: 1;
            margin-right: 8px;
        }

        &.safebag-container {
            width: 500px;
            margin-left: 8px;
        }

        h2 {
            margin: 0;
        }
    }

    > .entry {
        display: flex;
        flex-direction: column;
        > .entry-header {
            padding: 8px;
            padding-bottom: 0;
            display: flex;

            > .entry-title {
                margin: 8px;
                font-weight: bold;
                font-size: 2em;
            }
        }

        > .content {
            padding: 0 16px 16px 16px;
            flex: 1;

            > .actions {
                display: flex;
                justify-content: flex-end;

                button {
                    margin: 4px;
                }

                > .button-left-part {
                    border-radius: 4px 0px 0px 4px;
                    margin-right: 1px;
                    padding-right: 0px;
                }

                > .button-right-part {
                    border-radius: 0px 4px 4px 0px;
                    margin-left: 0px;
                    padding-left: 0px;
                }
            }
        }
    }
}

.shift-container {
    > .content {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > .shift-info {
            display: flex;
            justify-content: space-between;
            > .info {
                margin: 8px 0;
                font-size: 1.5em;
                color: #999;
            }
        }

        > .cart-wrapper {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow: hidden;

            > .cart-list {
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                padding: 0 8px;

                > .cart {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: fit-content;
                    padding: 8px 0;

                    &:not(:last-of-type) {
                        border-bottom: 1px solid #ccc;
                    }

                    > div {
                        &.left {
                            flex: 1;
                            display: flex;
                            flex-direction: column;

                            > .date {
                                color: #999;
                            }

                            > .time {
                                font-size: 1.25em;
                            }
                        }

                        &.center {
                            flex: 2;
                            display: flex;
                            flex-direction: column;
                            max-width: 600px;
                            > div {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;

                                .product-info {
                                    font-size: 1.2em;
                                    font-weight: bold;
                                }
                                .ticket-info {
                                    font-size: 1.2em;
                                    color: blue;
                                    text-decoration: underline;
                                    cursor: pointer;
                                }
                            }
                        }

                        &.right {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            text-align: right;
                                    font-size: 1.2em;
                        }
                    }
                }
            }
        }

        > .shift-amount {
            margin-top: 20px;
            border-top: 6px double #ccc;
            display: flex;

            > h3 {
                align-items: flex-start;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            > label {
                align-self: flex-end;
                margin-left: auto;
                text-align: right;
                font-size: 2em;
                font-weight: bold
            }
        }

        > .cash-amount {
            display: flex;

            > h4 {
                align-items: flex-start;
                margin-left: 20px;
                margin-top: 4px;
                margin-bottom: 4px;
                font-weight: normal
            }

            > label {
                align-self: flex-end;
                margin-left: auto;
                text-align: right;
                font-size: 1.2em;
                font-weight: normal
            }
        }

        > .actions {
            margin-top: auto;

            button {
                margin: 2px;
            }
        }
    }
}

.safebag-container {
    > .content {
        display: flex;
        flex-direction: column;

        > .all-safebags {
            width: 100%;
            max-height: 480px;
            overflow-y: auto;
            > .safebag {
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 8px;

                &:not(:first-of-type) {
                    border-top: 1px solid #ccc;
                }

                > .left {
                    flex: 5;

                    > label {
                        display: block;

                        &:first-of-type {
                            font-weight: bold;
                            font-size: 1.25em;
                        }

                        &:last-of-type {
                            color: #999;
                        }
                    }
                }

                > .center {
                    flex: 1;

                    display: flex;
                    justify-content: space-between;

                    > label {
                        flex: 1;
                        margin: 0 4px;
                        text-align: right;

                        &:first-of-type {
                            color: #999;
                        }
                    }
                }

                > .right {
                    flex: 1;
                    width: 40px;

                    button {
                        width: 40px;
                        margin-left: 20px;
                    }
                }
            }
        }

        > .current-amount {
            > .entry {
                display: flex;

                > .amount {
                    margin-left: auto;
                    font-size: 2em;
                    font-weight: bold;
                }
            }
        }

        > .current-safebag {
            margin-top: auto;

            > .entry {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 8px;

                > .id-label {
                    font-weight: bold;
                }

                > .id {
                    color: #999;
                }

                > * {
                    flex: 1;

                    &:last-child {
                        text-align: right;
                    }
                }

                > .amount {
                    margin-left: auto;
                    font-size: 1.4em;
                    font-weight: bold;
                }

                :deep(input[type=number]),
                :deep(input::-webkit-outer-spin-button),
                :deep(input::-webkit-inner-spin-button) {
                    appearance: none;
                    // -moz-appearance: textfield;
                    // -webkit-appearance: none;
                    margin: 0;
                }
            }
        }

        > .actions {
            display: flex;
            justify-content: flex-end;
        }
    }
}

.ticket-overview {
    .ticket-content {
        max-height: 600px;
        overflow: auto;
        margin-bottom: 20px;
        width: 1400px;
     }

    .actions {
        display: flex;
        justify-content: flex-end;
    }
}
</style>