<template>
    <div class="wrapper">
        <div class="header">
            <div class="text-input">
                <TextInput class="text-input" label="Suche..." v-model="search"/>
            </div>
            <CheckboxInput v-model="validsOnly" text="Nur gültige"/>
            <button @click="handleOpenNewCostCoverage()" class="highlight" v-if="editable">
                <fa-icon icon="fa-solid fa-plus"></fa-icon>
                <label>Kostenübernahme hinzufügen</label>
            </button>
        </div>
        <div class="table">
            <CostCoveragesTable 
                :cost-coverages="filteredCostCoverages" 
                :selectable="editable || selectable"
                :deletable="editable"
                @select="handleSelect"
                @delete="handleDelete"
            />
        </div>
        <PopUp :title="editPopUpTitle" :open="showEditPopUp" @close="handleCloseEditPopUp" submitLabel="Speichern" class="edit-popup">
            <div class="edit-pop-up-input-wrapper" >
                <DateInput 
                    v-model="costCoverageToEdit.validFrom"
                    :required="true"
                    label="Gültig ab"
                    :clearable="false" 
                    :error="v$.costCoverageToEdit.validFrom.$error"
                    class="text-field">
                </DateInput>
                <DateInput 
                    v-model="costCoverageToEdit.validTo" 
                    label="Gültig bis" 
                    :min-date="new Date(costCoverageToEdit.validFrom)" 
                    :placeholder="costCoverageToEdit.validTo? ' ': 'bis auf Wiederruf'" 
                    class="text-field">
                </DateInput>
                <TextInput 
                    v-model="costCoverageToEdit.authorisedCompany" 
                    label="Beauftragtes Unternehmen" 
                    class="text-field full-width"
                />
                <TextInput 
                    v-model="costCoverageToEdit.driver" 
                    label="Fahrername" 
                    class="text-field full-width"/>
                <TextInput 
                    v-model="newLicensePlate" 
                    label="KFZ-Kennzeichen" 
                    class="text-field"/>
                <button :disabled="!newLicensePlate" @click="addLicensePlate(newLicensePlate)">
                    <fa-icon icon="fa-solid fa-plus" class="add-license-plate-icon"/>
                </button>
                <div v-for="licensePlate, idx in costCoverageToEdit.licensePlates" :key="idx" class="license-plates-wrapper">
                    <LicensePlateDisplay class="license-plate" :content="licensePlate.licensePlate" />
                    <button class="delete-button" @click="deleteLicensePlate(idx)">
                        <fa-icon icon="fa-regular fa-trash-can"/>
                    </button>
                </div>
            </div>
        </PopUp>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import CostCoveragesTable from '@elements/CostCoveragesTable.vue'
import TextInput from '@elements/TextInput.vue'
import CheckboxInput from '@elements/CheckboxInput.vue'
import PopUp from '@elements/PopUp.vue';
import DateInput from '@elements/DateInput.vue';
import LicensePlateDisplay from '@elements/license-plate-display/LicensePlateDisplay.vue'


const EMITS = {
    DELETE: 'delete',
    UPDATE: 'update',
    ADD: 'add',
    SELECT: 'select-cost-coverage',  
}

const EDIT_COST_COVERAGE_MODE = {
    UPDATE: 'update',
    NEW: 'new'
}

const validateValidFrom = (_, costCoverage)=> { 
    if(!costCoverage.validTo){
        return true
    }
    return new Date(costCoverage.validTo) > new Date(costCoverage.validFrom)
}

export default {
    name: 'cost-coverages-display',
    inject: [ 'util' ],
    emits: [
        EMITS.DELETE,
        EMITS.UPDATE,
        EMITS.ADD,
        EMITS.SELECT,
    ],
    props: {
        costCoverages: {
            type: Array,
            required: true
        },
        uuidCustomer: {
            type: String,
            required: true // required for new customers (does not matter if not allowed)
        },
        //can only select or edit not both
        selectable: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    setup () {
        return { v$: useVuelidate({ $stopPropagation: true }) }
    },
    data() {
        return {
            validsOnly: false,
            search: '',
            showEditPopUp: false,
            costCoverageToEdit: {},
            newLicensePlate: '',
            editCostCoverageMode: EDIT_COST_COVERAGE_MODE.UPDATE,
        }
    },
    computed: {
        filteredCostCoverages() {
            if(this.validsOnly){
                return this.costCoverages.filter(cc => this.isCostCoverageValid(cc) && this.isSearchInCostCoverage(cc))
            }
            else {
                return this.costCoverages.filter(cc => this.isSearchInCostCoverage(cc))
            }
        },
        editPopUpTitle(){
            switch(this.editCostCoverageMode){
                case EDIT_COST_COVERAGE_MODE.NEW:
                    return 'Neue Kostenübernahme hinzufügen'
                case EDIT_COST_COVERAGE_MODE.UPDATE:
                    return 'Kostenübernahme bearbeiten'
                default:
                    return ''
            }
        },
    },
    methods: {
        handleSelect(costCoverage){
            if(this.selectable){
                this.$emit(EMITS.SELECT, costCoverage)
            }
            else if (this.editable) {
                this.showEditPopUp = true
                this.editCostCoverageMode = EDIT_COST_COVERAGE_MODE.UPDATE
                this.costCoverageToEdit = JSON.parse(JSON.stringify(costCoverage))
            }
        },
        handleOpenNewCostCoverage(){
            this.showEditPopUp = true
            this.editCostCoverageMode = EDIT_COST_COVERAGE_MODE.NEW
            this.costCoverageToEdit = this.newCostCoverage()
        },
        handleDelete(costCoverage){
            this.$emit(EMITS.DELETE, costCoverage)
        },
        isCostCoverageValid(costCoverage){
            const now = new Date()
            const currentDate = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()))
            const includeEquals = true
            return this.util.isDateInBetween(currentDate, costCoverage.validFrom, costCoverage.validTo, includeEquals, includeEquals)
        },
        isSearchInCostCoverage(costCoverage){
            const searchTerm = this.search.toLowerCase()

            if (!searchTerm || searchTerm.trim() === '') {
                return true
            }

            const companyMatch = costCoverage.authorisedCompany?.toLowerCase().includes(searchTerm) || false
            const driverMatch = costCoverage.driver?.toLowerCase().includes(searchTerm) || false
            const licensePlateMatch = costCoverage.licensePlates.some(lp => lp.licensePlate.toLowerCase().includes(searchTerm))
            const startDateMatch = this.util.formatDisplayDate(costCoverage.validFrom).includes(searchTerm)
            const endDateMatch = this.util.formatDisplayDate(costCoverage.validTo).includes(searchTerm)

            return companyMatch || driverMatch || licensePlateMatch || startDateMatch || endDateMatch
        },
        newCostCoverage(){
            const now = new Date()
            const today = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())).toISOString()
            return {
                "authorisedCompany": "",
                "externalReference": this.uuidCustomer,
                "driver": "",
                "licensePlates": [],
                "validFrom": today,
                "validTo": null
            }
        },
        handleCloseEditPopUp(submit){
            if(submit) {
                this.v$.$touch()
                if(this.v$.$invalid) {
                    return
                }
                //Validation not implemented yet
                switch(this.editCostCoverageMode){
                    case EDIT_COST_COVERAGE_MODE.NEW:
                        this.$emit(EMITS.ADD, this.costCoverageToEdit)
                        break
                    case EDIT_COST_COVERAGE_MODE.UPDATE:
                        this.$emit(EMITS.UPDATE, this.costCoverageToEdit)
                        break
                    default:
                        break
                }
            }
            this.showEditPopUp = false
            this.newLicensePlate = ''
        },
        deleteLicensePlate(idx){
            this.costCoverageToEdit.licensePlates.splice(idx, 1)
        },
        addLicensePlate(label){
            this.costCoverageToEdit.licensePlates.push({
                licensePlate: label,
            })
        },
    },
    validations () {
        return {
            costCoverageToEdit: {
                validFrom: { required, validateValidFrom: validateValidFrom }
            }
        }
    },
    components: {
        CostCoveragesTable,
        TextInput,
        CheckboxInput,
        PopUp,
        LicensePlateDisplay,
        DateInput
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    display: flex;
    height: calc(100%);
    padding-bottom: 16px;
    flex-direction: column;

    .header {
        display: flex;
        gap: 16px;

        .text-input {
            flex: 1;
        }
    }
}
.table {
    overflow: auto;
}

.edit-popup {
    :deep(.content-wrapper) {
        width: 1200px;
    }

    .edit-pop-up-input-wrapper {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto auto auto auto;

        column-gap: 10px;

        .text-field {
            place-self: center;
            width: 100%;
        }
        .full-width {
            grid-column: 1 / span 2;
            width: 100%;
        }

        .add-license-plate-icon {
            color: #ec0016;
        }
        .license-plates-wrapper {
            display: flex;
            align-items: center;
            gap: 0px;

            .license-plate {
                padding: 2px 0px;
                font-size: 0.5em;
            }
        }
    }
}


</style>