class Timer {
    // https://stackoverflow.com/a/3969760
    _started
    _callback
    _delay
    _timer
    
    constructor(callback, delay) {
        this._started  = Date.now()
        this._callback = callback
        this._delay    = delay

        this._timer = setTimeout(callback, delay)
    }

    pause() {
        this.stop()
        this._delay -= Date.now() - this._started
    }

    resume() {
        this.stop()
        this._started = Date.now()
        this._timer   = setTimeout(this._callback, this._delay)
    }

    stop() {
        clearTimeout(this._timer)
    }
}

export default Timer