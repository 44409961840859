<template>
    <div class="customer-search-container">
        <div class="left">
            <div class="landing-entry catalogue">
                <div class="entry-header">
                    <label class="entry-title">Kundensuche</label>
                </div>

                <CustomerSearch class="entry-content" @result="handleCustomerSearchResult"/>
            </div>
        </div>
    </div>
</template>

<script>
import CustomerSearch from '@components/CustomerSearch.vue'

export default {
    name: 'customer-search-page',
    inject: ['globalState'],
    data() {
        return {
            ticketSelection: undefined,
            done: false,
            amount: 0
        }
    },
    methods: {
        handleCustomerSearchResult(customer) {
            Object.assign(this.globalState, { customer })
            //this.showSearch = false
            this.$router.push({ name: 'customer-page'})
        },
        handleTicketSelection() {
            this.$router.push({ name: 'authorization-creation' })
        }
    },
    components: {
        CustomerSearch
    }
}
</script>

<stlye lang="scss" scoped>
// transition order:
// https://stackoverflow.com/questions/18846481/use-transition-on-flexbox-order
// maybe change order of divs with JS to allow for a correct DOM?

.customer-search-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > .left {
        min-width: 400px;
        /* width: 50%; */
        height: fit-content;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        padding-left: 8px;
    }

    .landing-entry {
        width: 100%;
        background: #fff;
        box-shadow: 0 2px 8px #00000022;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        &.catalogue {
            height: 100%;
            min-height: 300px;
        }

        > .entry-header {
            padding: 8px;
            padding-bottom: 0;
            display: flex;

            > .entry-title {
                margin: 8px;
                font-weight: bold;
                font-size: 2em;
            }
        }

        > .entry-content {
            // full height minus header
            height: calc(100% - 58px);
            padding: 16px;
            padding-top: 0;
        }
    }
}
</stlye>