<template>
    <div class="license-plates-display-wrapper">
        <div class="new-license-plate">
            <TextInput label="Neues Kennzeichen [AB-CD 1234]..." v-model="newLicensePlateUppercase" @keyup.enter="addLicensePlate"/>
            <button class="highlight" @click="addLicensePlate">
                <fa-icon icon="fa-solid fa-plus"/>
            </button>
        </div>

        <div class="license-plate-list">
            <div class="license-plate" v-for="(plate, idx) in licensePlates" :key="idx">
                <label>{{ plate }}</label>
                <button @click="removeLicensePlate(plate)">
                    <fa-icon icon="fa-regular fa-trash-can"/>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import TextInput from '@elements/TextInput.vue'

const EMIT_REMOVE = 'remove'
const EMIT_ADD    = 'add'

export default {
    name: 'license-plates-display',
    emits: [ EMIT_REMOVE, EMIT_ADD ],
    props: {
        licensePlates: {
            type: Array, // { displayid: id, displayname: name, original: orginal }
            required: true
        }
    },
    data() {
        return {
            newLicensePlate: undefined
        }
    },
    computed: {
        newLicensePlateUppercase: {
            get() {
                return this.newLicensePlate
            },
            set(newValue) {
                this.newLicensePlate = newValue.toUpperCase()
            }
        }
    },
    methods: {
        addLicensePlate() {
            if (!this.newLicensePlate) return
            this.$emit(EMIT_ADD, this.newLicensePlate)
            this.newLicensePlate = undefined
        },
        removeLicensePlate(plate) {
            this.$emit(EMIT_REMOVE, plate)
        },
    },
    components: {
        TextInput
    }
}
</script>

<style lang="scss" scoped>
.license-plates-display-wrapper {
    width: 100%;
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;

    > .new-license-plate {
        display: flex;
        align-items: center;
        padding: 0 8px;

        > div {
            margin-right: 8px;
            width: 100%;
        }
    }

    > .license-plate-list {
        overflow-y: auto;

        > .license-plate {
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:not(:last-of-type) {
                border-bottom: 1px solid #ccc;
            }
        }
    }
}
</style>