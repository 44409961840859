<template>
    <div class="customer-search-results" @click="close()" @keyup.esc="close()">
        <div class="result-content" @click="$event.stopPropagation()">
            <h3>Die Suche hat mehrere Treffer ergeben.</h3>

            <TextInput class="filter" label="Filter..." v-model="resultFilter"/>

            <div class="result-list">
                <div class="result" v-for="result in filteredResults" :key="result.id" @click="handleResultSelection(result)">
                    <div class="customer-number">
                        <label class="customerNumber">{{ result.customerNumber }}</label>
                    </div>
                    <div class="personal-info">
                        <label class="name">{{ result.firstName + ' ' + result.lastName }}</label>
                    </div>
                    <div class="personal-info">
                        <label class="email">{{ result.email }}</label>
                    </div>
                    <div class="address">
                        <label class="city">{{ result.postalCode + ' ' + result.city }}</label>
                    </div>
                    <div class="icon">
                        <fa-icon icon="fa-solid fa-arrow-right"/>
                    </div>
                </div>
            </div>

            <div class="result-actions">
                <button @click="close()">
                    <fa-icon icon="fa-solid fa-xmark" src="@img/xmark.svg"/>
                    <label>Suche anpassen</label>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import TextInput from '@elements/TextInput.vue'
import { getCustomer } from '@lib/middleware-connector'

const EMIT_RESULT_SELECTION = 'result'
const EMIT_CLOSE = 'close'

export default {
    name: 'customer-search-results',
    inject: [ 'util' ],
    emits: [ EMIT_RESULT_SELECTION,
             EMIT_CLOSE],
    props: {                
        searchResults: {
            type: Array            
        }
    },
    data() {
        return {
            resultFilter: ''
        }
    },
    computed: {
        filteredResults() {
            return this.searchResults.filter(result => {
                const noFilter = !this.resultFilter || this.resultFilter.trim() === ''

                const firstNameMatch = result.firstName.toLowerCase().includes(this.resultFilter.toLowerCase())
                const lastNameMatch  = result.lastName.toLowerCase().includes(this.resultFilter.toLowerCase())
                const customerNumberMatch  = result.customerNumber.toLowerCase().includes(this.resultFilter.toLowerCase())
                const postalCodeMatch  = result.postalCode.toLowerCase().includes(this.resultFilter.toLowerCase())
                const cityMatch  = result.city.toLowerCase().includes(this.resultFilter.toLowerCase())
                const emailMatch  = result.email.toLowerCase().includes(this.resultFilter.toLowerCase())

                return noFilter || firstNameMatch || lastNameMatch || customerNumberMatch || postalCodeMatch || cityMatch || emailMatch
            })
        }
    },
    methods: {
        async handleResultSelection(result) {
            try {
                const customer = await getCustomer(result.uuid)
                this.$emit(EMIT_RESULT_SELECTION, customer)
            } catch(err){
                this.$toast.error(err.response?.data?.userMessage || err.message)
                return null
            }
        },
        close() {
            this.$emit(EMIT_CLOSE)
        }
    },
    components: {
        TextInput
    }
}
</script>

<style lang="scss" scoped>

.inputs {
    display: flex;
    flex-wrap: wrap;

    > .email {
        width: 190px;
    }

    > .firstname, .lastname, .phone, .city {
        margin-left: 24px;
        width: 140px;
    }

}

.customer-search-results {
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000aa;
    display: flex;
    align-items: center;
    justify-content: center;

    > .result-content {
        background: #fff;
        border-radius: 8px;
        min-width: 500px;
        width: 45%;
        padding: 16px;

        h3 {
            text-align: left;
            margin-top: 0;
            margin-bottom: 4px;
        }

        > .filter {
            margin-top: 8px;
        }

        > .result-list {
            font-size: 1.1em;
            margin: 8px 0;
            height: 300px;
            overflow-y: auto;

            > .result {
                height: 40px;
                display: flex;
                align-items: center;
                padding: 0 8px;

                &:not(:last-child) {
                    border-bottom: 1px solid #ccc;
                }

                &:hover {
                    background: #00000022;
                }

                &:hover,
                &:hover :deep(*) {
                    cursor: pointer;
                }


                > .customer-number {
                    flex: 14;

                    label {
                        display: block;
                        text-align: left;
                    }

                }
                > .personal-info {
                    flex: 20;

                    label {
                        display: block;
                        text-align: left;
                    }
                }

                > .address {
                    flex: 20;
                    margin-left: 16px;

                    label {
                        display: block;
                        text-align: left;
                    }
                }

                > .icon {
                    flex: 0;
                    margin-left: auto;
                    height: 85%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    > svg {
                        padding: 6px;
                        height: 65%;
                    }
                }
            }
        }

        > .result-actions {
            margin-left: auto;
            width: fit-content;
            margin-top: 8px;
        }
    }
}
</style>