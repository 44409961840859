import { addElement } from './lib/render'
import ToastComponent from './view/ToastComponent.vue'
import { POSITION, LEVEL } from './lib/option-definitions.js'

const toastPlugin = {
    install(app, options) {
        const toast = new _Toast(options)
        // add instance var for use with 'this.$toast'
        app.config.globalProperties.$toast = toast
        // additionally provide an injectable instace
        app.provide('$toast', toast)
    }
}

// default option set for init without options
const defaultOptions = {
    position: POSITION.TOP_RIGHT,
    level:    LEVEL.INFO,
    duration: 10000
}

// private class that handles/provides the api functions
class _Toast {
    _options
    _queue

    /**
     * init with default options if no options are passed
     * 
     * @param {ToastOptions} options General toast configuration
     * @returns Plugin instance
     */
    constructor(options) {
        this._options = _assignDefined(new ToastOptions(defaultOptions), options)
    }

    _placeToast(message, options) {
        // combine default options, method options and message to pass as props
        const props = _assignDefined({}, this._options, options, { message })
        addElement(ToastComponent, props, document.body)
    }

    info(message, options = {}) {
        options.level = LEVEL.INFO
        return this._placeToast(message, options)
    }

    warn(message, options = {}) {
        options.level = LEVEL.WARN
        return this._placeToast(message, options)
    }

    success(message, options = {}) {
        options.level = LEVEL.SUCCESS
        return this._placeToast(message, options)
    }

    error(message, options = {}) {
        options.level = LEVEL.ERROR
        return this._placeToast(message, options)
    }

    custom(message, options) {
        return this._placeToast(message, options)
    }
}

class ToastOptions {
    // pass through so the options don't need to be imported seperately
    static POSITION = POSITION
    static LEVEL    = LEVEL

    position
    duration
    level

    constructor({ position, level, duration } = {}) {
        this.position = position
        this.duration = duration
        this.level    = level
    }
}

const _assignDefined = (target, ...sources) => {
    for (const source of sources) {
        if (!source) continue
        for (const key of Object.keys(source)) {
            const val = source[key]
            if (val !== undefined) {
                target[key] = val
            }
        }
    }
    return target
}

export default toastPlugin

export {
    ToastOptions,
    POSITION,
    LEVEL
}