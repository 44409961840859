import { keycloakState } from '@lib/state.js'

let logoutTimerId = null
let elapsedTime = 0
let logoutTime = 1200 // 20mins in sec

const resetLogoutTimer = () => {
    elapsedTime = 0
}

const addEventListeners = () => {
    document.addEventListener('keypress', resetLogoutTimer)
    document.addEventListener('mousemove', resetLogoutTimer)
    document.addEventListener('mousedown', resetLogoutTimer)
}

const monitorUserLogout = () => {
    resetLogoutTimer()
    addEventListeners()

    logoutTimerId = setInterval(()=> {
        if(elapsedTime < logoutTime) {
            return elapsedTime++
        }

        clearInterval(logoutTimerId)
        if(keycloakState.available) {
            keycloakState.instance?.logout()
        } else {
            console.warn("Autologout: keyclock not av, restarting timer")
            monitorUserLogout()
        }
    }, 1000)
}

export {
    monitorUserLogout
}