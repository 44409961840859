<template>
    <div v-if="isloading" class="input-line-loading-placeholder shimmer big"></div>
    <div v-else class="input-wrapper" :class="[{ 'error' : error }, { 'with-label' : label }]">
        <div class="input-container" @click="handleInput(!modelValue)" :disabled="disabled" :class="[{ 'active':this.modelValue }, { 'display':this.display}]">
            <div class="toggle"
                 :class="[{ 'with-label' : this.label }, { 'active' : this.modelValue }]"
                 :style="backgroundcolor ? 'background-color: ' + backgroundcolor + ' !important;' : ''">

                <fa-icon icon="fa-solid fa-check" v-if="this.modelValue"/>
                <fa-icon icon="fa-solid fa-xmark" v-else />
            </div>

            <label class="checkbox-text" >{{ this.text }}</label>
        </div>

        <label class="input-label" v-if="label" :style="backgroundcolor ? 'background-color: ' + backgroundcolor + ' !important;' : ''">{{ this.label }} {{ required ? '*' : '' }}</label>

        <div class="error-container">
            <label class="error-label" :class="{ 'show' : error }">{{ this.errortext }}</label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CheckboxInput',
    props: {
        modelValue: {
            type: Boolean,
            required: true
        },
        text: {
            type: String,
            default: undefined
        },
        display: {
            type: Boolean,
            default: false
        },
        isloading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: undefined
        },
        required: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        },
        errortext: {
            type: String,
            default: undefined
        },
        backgroundcolor: {
            type: String,
            default: undefined
        },
    },
    methods: {
        handleInput: function(value) {
            if (this.disabled) return
            if (!this.display) this.$emit('update:modelValue', value)
        },
        clicked: function(event) {
            this.$emit('click', event)
        }
    },
}
</script>

<style lang="scss" scoped>
.input-wrapper {
    padding-top: 15px;
    position: relative;

    &.error:deep(*) {
        color: red;
        border-color: red;
    }

    > .input-label {
        position: absolute;
        font-size: 1em;
        left: 10px;
        background: #fff;
        top: 6px;
        padding: 0px 5px;
        transition: .1s all;
        height: 10px;
        line-height: 10px;
    }

    > .display {
        white-space: pre-wrap;
    }

    > .input-container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 30px;
        box-sizing: border-box;
        font-size: 1.2em;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        background-color: #fff;
        box-shadow: 0 2px 8px #00000022;
        border-radius: 8px;
        user-select: none;
        padding-right: 10px;

        &:hover:not(.active) {
            background-color: #00000022;
        }

        &[disabled=true] {
            background: #00000000;
            opacity: 0.4;
            &:hover,
            &:hover  > * {
                cursor: default !important;
                background-color: inherit;
            }
        }

        &.active {
            background: #63a615;
            color: #fff;
        }

        &.display {
            border: 1px solid #ccc;
            background: unset;

            > .toggle {
                fill: #fff;

                &.active {
                    //color:#26a;
                    color:#63a615;
                    //fill: #26a;
                    fill: #63a615;
                    & + .checkbox-text {
                        color: inherit;
                        fill: inherit
                    }
                }
            }
        }

        &:hover {
            &:not(.display) {
                &,
                > * {
                    cursor: pointer;
                }
            }
        }

        > .toggle {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 30px;
            fill: #999;

            > .checkbox-text {
                color: #000;
            }

            &.active {
                fill: #fff;
                & + .checkbox-text {
                    color: #fff;
                }
            }

            > svg {
                height: 60%;
            }
        }
    }

    > .input-label {
        font-size: 1.1em;
        color: #2c2e2f;
    }

    > .error-container {
        min-height: 15px;
        margin-top: 3px;
        padding-left: 10px;
        color: red;
        text-align: left;

        > .error-label {
            visibility: hidden;

            &.show {
                visibility: visible;
            }
        }
    }
}
</style>