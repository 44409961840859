<template>
    <div class="list-ticket-depiction-container" :class="{ 'open' : detailsOpen }" >
        <div class="list-view">
            <div class="open-wrapper" @click="toggleActiveTicket(idx)">
                <fa-icon class="caret" icon="fa-solid fa-angle-down"/>
                <fa-icon class="xmark" icon="fa-solid fa-xmark" />
            </div>

            <div class="name-wrapper">
                <label class="name">{{ ticket.ticketName }}</label>
                <label class="order-id">{{ ticket.orderId }}</label>
                <label class="order-date">{{ util.formatDisplayDateTime(ticket.created) }}</label>
                <label v-if="ticket?.customer" class="order-customer">{{ ticket.customer.lastName }}, {{ ticket.customer.firstName }} | {{ ticket.customer.customerNumber }}</label>
            </div>

            <div class="state-wrapper">
                <label class="state" v-bind:class="{ active: ticketValid(ticket)}">{{ ticketStateDisplay(ticket) }}</label>
            </div>

            <div class="price-wrapper">
                <label class="price">{{ util.formatCurrency(ticket.price) + ' €' }}</label>
                <label class="payment-method">{{ this.util.formatPaymentMethodString(ticket.paymentMethod) }}</label>
            </div>

            <div class="remaining-trips-wrapper">
                <label class="remaining-trips">{{ ticketRemainingTripsDisplay(ticket) }}</label>
                <label class="initial">Ab {{ ticket.direction.originName }}</label>
                <button class="highlight" @click="handleCreateUsage" :disabled="ticketDisabled(ticket) || !ticketHasTripForDirection(ticket)">
                    <fa-icon icon="fa-solid fa-clock-rotate-left" />
                    <label>Entwerten</label>
                </button>
            </div>

            <div class="reservations-wrapper">
                <div class="reservation" v-if="ticket.reservationOutward.active" title="Hin">
                    <fa-icon icon="fa-solid fa-arrow-right"/>
                    <label> {{ util.formatDisplayDateTime(ticket.reservationOutward?.date) }}</label>
                </div>
                <div class="reservation" v-if="ticket.reservationReturn.active" title="Rück">
                    <fa-icon icon="fa-solid fa-arrow-right" class="back"/>
                    <label> {{ util.formatDisplayDateTime(ticket.reservationReturn?.date) }}</label>
                </div>
            </div>

            <div class="license-plates-wrapper">
                <LicensePlateDisplay :title="plate" class="license-plate" :content="plate" v-for="(plate, idx) in ticket.licensePlates" :key="idx"/>
            </div>

            <div class="validity-wrapper">
                <label class="from"><span>Gültig ab </span>{{ this.util.formatDisplayDate(ticket.validFrom) }}</label>
                <label class="to"><span>Gültig bis </span>{{ this.util.formatDisplayDate(ticket.validTo) }}</label>
            </div>

            <div class="actions-wrapper">
                <div :ref="REFS.CONTAINER" class="actions-toggle" @click="handleOpenTicketOptions">
                    <fa-icon icon="fa-solid fa-ellipsis" />
                </div>
                <div :ref="REFS.ACTIONS" class="actions-menu" :class="{ 'open' : actionsOpen }">
                    <button class="highlight" v-if="withCustomer" :disabled="!ticket.customer" @click="handleOpenCustomer">
                        <fa-icon icon="fa-regular fa-user" />
                        <label >Kunde</label>
                    </button>
                    <button class="highlight" @click="handleHwsPrint" :disabled="ticketBlocked(ticket)">
                        <fa-icon icon="fa-regular fa-address-card" />
                        <label>Ausgeben</label>
                    </button>
                    <button class="highlight" @click="handlePdfPrint" :disabled="ticketBlocked(ticket)">
                        <fa-icon icon="fa-regular fa-address-card" />
                        <label>Ausgeben (pdf)</label>
                    </button>
                    <button class="highlight" @click="handleManualFee">
                        <fa-icon icon="fa-regular fa-money-bill-1" />
                        <label>Nacherhebung</label>
                    </button>
                    <button class="highlight" @click="handleManualRefund">
                        <fa-icon icon="fa-regular fa-money-bill-1" />
                        <label>Erstattung</label>
                    </button>
                    <button class="highlight" v-if="!ticket.disabledDate" @click="handleDisableTicket" :disabled="ticketDisabled(ticket)">
                        <fa-icon icon="fa-regular fa-circle-xmark" />
                        <label>Sperren</label>
                    </button>
                    <button class="highlight" v-else @click="handleEnableTicket">
                        <fa-icon icon="fa-regular fa-circle-xmark" />
                        <label>Entsperren</label>
                    </button>
                    <button class="highlight" @click="handleCancelTicket" :disabled="!ticketCanCancel(ticket)">
                        <fa-icon icon="fa-regular fa-circle-xmark" />
                        <label>Stornieren</label>
                    </button>                    
                </div>
            </div>
        </div>

        <div class="details-view">
            <div class="details-content">
                <div class="usages-wrapper">
                    <h3 class="header">Nutzungen</h3>
                    <h3 v-if="!ticket.usages || ticket.usages.length === 0">- Keine Nutzungen -</h3>
                    <div v-else class="usage" v-for="(usage, usageIdx) in ticket.usages" :key="usageIdx">
                        <div class="time">
                            <label>{{ util.formatDisplayDateTime(usage.usageTime) }}</label>
                        </div>
                        <div class="license-plate">
                            <LicensePlateDisplay v-if="usage.usageLicensePlate" :content="usage.usageLicensePlate"/>
                            <label class="placeholder" v-else>Kein Kennzeichen</label>
                        </div>
                        <div class="direction">
                            <fa-icon icon="fa-solid fa-arrow-right" />
                            <label>{{ usage.usageDirection.destinationName }}</label>
                        </div>
                        <div class="cancel">
                            <button v-if="!usage.usageCanceled" @click="handleCancelUsage(usage)">
                                <fa-icon icon="fa-solid fa-ban"/>
                            </button>
                            <label v-else>Storniert</label>
                        </div>
                    </div>
                </div>

                <div class="reservations-wrapper">
                    <h3 class="header">Reservierungen</h3>
                    <h3 v-if="!ticket.reservations || ticket.reservations.length === 0">- Keine Reservierungen -</h3>
                    <div v-else class="reservation" v-for="(reservation, idx) in ticket.reservations" :key="idx">
                        <label class="date"> {{ util.formatDisplayDateTime(reservation.date) }}</label>
                        <fa-icon icon="fa-solid fa-arrow-right"/>
                        <label class="direction">{{ reservation.direction?.destinationName }}</label>
                    </div>
                </div>

                <div class="edit-wrapper">
                    <h3>Gültigkeit bearbeiten</h3>
                    <div class="validity">
                        <VueDatePicker v-if="detailsOpen" v-model="editTicket.from" auto-apply :enable-time-picker="false" format="dd.MM.yyyy" />
                        <VueDatePicker v-if="detailsOpen" v-model="editTicket.to" auto-apply :enable-time-picker="false" format="dd.MM.yyyy" />
                        <button class="update-validity highlight" @click="handleNewValidity(editTicket.from, editTicket.to)">
                            <fa-icon icon="fa-regular fa-floppy-disk" />
                        </button>
                    </div>

                    <h3>Kennzeichen bearbeiten</h3>
                    <LicensePlatesDisplay class="edit-license-plates" v-if="detailsOpen" @add="plate => handleNewLicensePlate(plate)" @remove="plate => handleRemoveLicensePlate(plate)" :license-plates="ticket.licensePlates"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import '@vuepic/vue-datepicker/dist/main.css'
import VueDatePicker from '@vuepic/vue-datepicker'

import LicensePlateDisplay from '@elements/license-plate-display/LicensePlateDisplay.vue'
import LicensePlatesDisplay from '@components/LicensePlatesDisplay.vue'

const EMITS = {
    OPEN_ACTIONS:         'open-actions',
    OPEN_DETAILS:         'open-details',
    NEW_LICENSE_PLATE:    'new-license-plate',
    REMOVE_LICENSE_PLATE: 'remove-license-plate',
    NEW_VALIDITY:         'new-validity',
    CANCEL_USAGE:         'cancel-usage',
    NEW_USAGE:            'new-usage',
    OPEN_CUSTOMER:        'open-customer',
    HWS_PRINT:            'hws-print',
    PDF_PRINT:            'pdf-print',
    MANUAL_FEE:           'manual-fee',
    MANUAL_REFUND:        'manual-refund',
    ENABLE:               'enable',
    DISABLE:              'disable',
    CANCEL_TICKET:        'cancel-ticket',    
}

const REFS = {
    CONTAINER: 'ticket-display',
    ACTIONS: 'ticket-actions'
}

export default {
    name: 'list-ticket-depiction',
    emits: [
        EMITS.OPEN_ACTIONS,
        EMITS.OPEN_DETAILS,
        EMITS.NEW_LICENSE_PLATE,
        EMITS.REMOVE_LICENSE_PLATE,
        EMITS.NEW_VALIDITY,
        EMITS.CANCEL_USAGE,
        EMITS.NEW_USAGE,
        EMITS.OPEN_CUSTOMER,
        EMITS.HWS_PRINT,
        EMITS.PDF_PRINT,
        EMITS.MANUAL_FEE,
        EMITS.MANUAL_REFUND,
        EMITS.ENABLE,
        EMITS.DISABLE,
        EMITS.CANCEL_TICKET
    ],
    inject: [ 'util' ],
    props: {
        ticket: {
            type: Object,
            required: true
        },
        withCustomer: {
            type: Boolean,
            default: false
        },
        shiftDirection: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            REFS,
            detailsOpen: false,
            actionsOpen: false,
            editTicket: {
                from: undefined,
                to:   undefined
            }
        }
    },

    mounted() {
        // add option-list to body, so it is not impacted by its direct parent (overflow: hidden)
        // additionaly get an initial position for the list
        document.body.append(this.actionsEl)
    },
    beforeUnmount() {
        // remove the options from the body to avoid duplicates
        this.actionsEl.remove()
    },
    computed: {
        actionsEl() {
            return this.$refs[REFS.ACTIONS]
        },
        containerEl() {
            return this.$refs[REFS.CONTAINER]
        }
    },
    methods: {
        handleEnableTicket() {
            this.$emit(EMITS.ENABLE)
        },
        handleDisableTicket() {
            this.$emit(EMITS.DISABLE)
        },
        handleCancelTicket() {
            this.$emit(EMITS.CANCEL_TICKET)
        },        
        handleHwsPrint() {
            this.$emit(EMITS.HWS_PRINT)
        },
        handlePdfPrint(){
            this.$emit(EMITS.PDF_PRINT)
        },
        handleManualFee() {
            this.$emit(EMITS.MANUAL_FEE)
        },
        handleManualRefund() {
            this.$emit(EMITS.MANUAL_REFUND)
        },
        handleCreateUsage() {
            this.$emit(EMITS.NEW_USAGE)
        },
        handleOpenCustomer() {
            this.$emit(EMITS.OPEN_CUSTOMER)
        },
        handleCancelUsage(usage) {
            this.$emit(EMITS.CANCEL_USAGE, usage)
        },
        handleNewLicensePlate(plate) {
            this.$emit(EMITS.NEW_LICENSE_PLATE, plate)
        },
        handleRemoveLicensePlate(plate) {
            this.$emit(EMITS.REMOVE_LICENSE_PLATE, plate)
        },
        handleNewValidity(from, to) {
            this.$emit(EMITS.NEW_VALIDITY, { from, to })
        },
        toggleActiveTicket() {
            if (!this.detailsOpen) {
                // going to open
                this.$emit(EMITS.OPEN_DETAILS)
                // make editable copies of the dates
                this.editTicket.from = this.ticket.validFrom
                this.editTicket.to   = this.ticket.validTo
            }

            this.detailsOpen = !this.detailsOpen
        },
        handleOpenTicketOptions(event) {
            if (!this.actionsOpen) {
                // notfiy parent
                this.$emit(EMITS.OPEN_ACTIONS)

                this.actionsOpen = true

                document.addEventListener('click', () => {
                    this.actionsOpen = false
                })

                event.stopPropagation()

                this.$nextTick(() => {
                    this.util.positionPopupElement(this.containerEl, this.actionsEl)
                })
                return true
            }
            return false
        },
        ticketStateDisplay(ticket) {

            if (ticket.validation) {
                return ticket.validation.detailView.validText
            }
            if (this.ticketBlocked(ticket)) {
                return 'Gesperrt'
            }

            if (this.ticketInvalid(ticket)) {
                return 'Abgelaufen'
            }

            if (this.ticketDevalued(ticket)) {
                return ''
            }
            return ''
        },
        ticketValid(ticket) {
            if (ticket.validation) {
                return ticket.validation.listView?.validText?.toLowerCase() === 'gültig'
            }

            return !this.ticketDisabled(ticket) && !this.ticketInvalid(ticket)
        },
        ticketDisabled(ticket) {
            return this.ticketDevalued(ticket) || this.ticketBlocked(ticket)
        },
        ticketDevalued(ticket) {
            const remainingTrips  = (ticket.outwardTrips ?? 0) + (ticket.returnTrips ?? 0)
            return remainingTrips <= 0
        },
        ticketCanCancel(ticket) {
            return ticket.canCancel && !this.ticketUsed(ticket) && !this.ticketDisabled(ticket)
        },
        ticketBlocked(ticket) {
            return ticket.disabledDate !== null
        },
        ticketUsed(ticket) {
            return ticket.usages.length > 0
        },
        ticketInvalid(ticket) {
            return new Date(ticket.validTo).getTime() < Date.now()
        },
        ticketHasTripForDirection(ticket) {
            // Mehrfahrten-Tickets sind nicht Richtungsabhängig
            if (ticket.multipleJourney) {
                return ((ticket.outwardTrips ?? 0) > 0)
            }
            // Hinfahrt
            if ((ticket.direction.id === this.shiftDirection)) {
                return ((ticket.outwardTrips ?? 0) > 0)
            } else {
                return ((ticket.returnTrips ?? 0) > 0)
            }
        
        },
        ticketReservationsDisplay(ticket) {
            ticket.reservationOutward = true
            ticket.reservationReturn = true

            if (!ticket.reservationOutward && !ticket.reservationReturn) {
                return 'keine Reservierung'
            }

            let result = []

            if (ticket.reservationOutward) {
                result.push('Reserviert Hin')
            }

            if (ticket.reservationReturn) {
                result.push('Reserviert Rück')
            }

            return result.join('\r\n')
        },
        ticketRemainingTripsDisplay(ticket) {
            const postFix = ' Fahrte(n) verbleibend'
            const remainingTrips = (ticket.outwardTrips ?? 0) + (ticket.returnTrips ?? 0)
            return remainingTrips + postFix
        },
    },
    components: {
        LicensePlateDisplay,
        LicensePlatesDisplay,
        VueDatePicker
    }
}

</script>

<style lang="scss" scoped>
.list-ticket-depiction-container {
    flex: 1;
    padding: 8px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-bottom: 1px solid #ccc;

    &.open {
        > .list-view {
            > .open-wrapper {
                > svg {
                    transform: rotate(90deg);

                    &.caret {
                        opacity: 0;
                    }
                    &.xmark {
                        opacity: 1 !important
                    }
                }
            }
        }
        > .details-view {
            grid-template-rows: 1fr;
        }
    }

    > .list-view {
        display: flex;
        justify-content: space-between;

        > .name-wrapper {
            > .order-id,
            > .order-customer {
                user-select: initial;
            }
        }

        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;

            &.actions-wrapper,
            &.open-wrapper {
                display: flex;
                justify-content: center;
                align-items: center ;

                &:hover {
                    cursor: pointer;
                }
            }

            > .state {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        > .open-wrapper {
            position: relative;

            > svg {
                position: absolute;
                height: 20px;
                color: #666;
                transition: grid-template-rows 0.2s all;

                &.xmark {
                    opacity: 0;
                }
            }
        }

        > .license-plates-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            > .license-plate {
                font-size: 0.5em;

                &:not(:first-of-type) {
                    margin-top: 4px;
                }
            }
        }

        > .validity-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            > label {
                text-align: left;
                font-size: 1.2em;
                font-weight: bold;

                > span {
                    font-weight: normal;
                    display: inline-block;
                    width: 70px;
                    color: #666;
                }
            }
        }

        > .actions-wrapper {
            position: relative;

            > .actions-toggle {
                > svg {
                    height: 24px;
                    width: 24px;
                    border-radius: 50%;
                    padding: 4px;
                    color: #666;

                    &:hover {
                        background-color: #ccc;
                    }
                }
            }
        }

        > .name-wrapper {
            display: flex;
            flex-direction: column;

            > label {
                width: fit-content;
            }

            > .name {
                font-weight: bold;
                font-size: 1.25em;
                text-align: left;
            }
        }

        > .state-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            > .state {
                font-size: 1.25em;
                color: red;
                font-weight: bold;
            }
            > .active {
                font-size: 1.25em;
                color: green;
                font-weight: bold;
            }
        }

        > .price-wrapper {
            > .price {
                font-weight: bold;
                font-size: 1.25em;
            }
        }

        > .remaining-trips-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            > button {
                margin-top: 8px;
                width: 100%;
            }

            > .remaining-trips {
                font-size: 1.2em;
            }

            > .initial {
                color: #666;
            }
        }

        > .reservations-wrapper {
            align-items: center;

            > .reservation {
                display: flex;
                align-items: center;
                color: green;

                > svg {
                    width: 20px;
                    height: 16px;

                    &.back {
                        transform: rotate(-180deg);
                    }
                }

                > label {
                    text-align: left;
                    width: 110px;
                    font-size: 1.2em;
                    font-weight: bold;
                }
            }
        }
    }

    > .details-view {
        display: grid;
        grid-template-rows: 0fr;
        transition: 0.3s all ease-out;

        > .details-content {
            overflow: hidden;
            display: flex;
            justify-content: space-between;

            > div {
                margin: 12px 8px;

                &.edit-wrapper {
                    flex: 3;

                    > h3 {
                        margin: 8px 0;
                        text-align: left;
                        border-bottom: 1px solid #ccc;

                        &:not(:first-of-type) {
                            margin-top: 80px;
                        }
                    }

                    > .validity {
                        margin-top: 8px;
                        display: flex;

                        > * {
                            &:not(:first-child) {
                                margin-left: 4px;
                            }
                        }
                    }

                    > .edit-license-plates {
                        :deep(.license-plate-list) {
                            height: 140px;
                        }
                    }
                }

                &.reservations-wrapper {
                    flex: 3;

                    > .header {
                        margin: 8px 0;
                        text-align: left;
                        border-bottom: 1px solid #ccc;
                    }

                    > .reservation {
                        width: 100%;
                        border-bottom: 1px solid #eee;
                        padding: 4px 0;
                        height: 40px;
                        display: flex;
                        align-items: center;

                        > svg {
                            width: 20px;
                            height: 16px;

                            &.back {
                                transform: rotate(-180deg);
                            }
                        }

                        > label {
                            text-align: center;
                            font-size: 1.2em;

                            &.date {
                                flex: 3;
                            }

                            &.direction {
                                flex: 5;
                            }
                        }
                    }
                }

                &.usages-wrapper {
                    flex: 4;
                    overflow-y: auto;

                    > .header {
                        margin: 8px 0;
                        text-align: left;
                        border-bottom: 1px solid #ccc;
                    }

                    > .devalue {
                        width: 100%;
                    }

                    > .usage {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-bottom: 1px solid #eee;
                        padding: 4px 0;
                        height: 40px;

                        > div {
                            flex: 1;
                        }

                        > .time {
                            max-width: 100px;
                        }

                        > .license-plate {
                            max-width: 120px;
                            font-size: 0.5em;

                            > .placeholder {
                                font-size: 2em;
                                color: red;
                            }
                        }

                        > .direction {
                            display: flex;

                            > svg {
                                width: 20px;
                            }

                            > label {
                                flex: 1;
                                text-align: left;
                            }
                        }

                        > .cancel {
                            max-width: fit-content;
                            > label {
                                color: red;
                            }
                            > button {
                                color: red;
                            }
                        }
                    }
                }
            }
        }

        > .actions {
            margin-top: auto;
            display: flex;
            justify-content: flex-end;
            width: 100%;

            > button {
                &:not(:first-of-type) {
                    margin-left: 8px;
                }
            }
        }
    }
}

.actions-menu {
    border-radius: 8px;
    display: none;
    position: absolute;
    background-color: #fff;
    padding: 16px 8px;
    box-shadow: 0 2px 8px #00000066;
    z-index: 100;

    &.open {
        display: flex;
        flex-direction: column;
    }

    > button {
        width: 100%;
        justify-content: flex-start;

        &:not(:first-of-type) {
            margin-top: 8px;
        }
    }
}
</style>