<template>
    <div class="on-site-sales-container">
        <h3>Richtung</h3>
        <div class="direction-container">
            <div class="direction" :class="{ 'selected' : direction.id === selectedDirection?.id }" v-for="direction in selectionData.directions" :key="direction.id">
                <input :id="'direction-' + direction.id" type="radio" :value="direction" v-model="selectedDirection" @click="resetProductSelection"/>
                <label :for="'direction-' + direction.id">
                    <span>{{ direction.from }}</span>
                    <fa-icon icon="fa-solid fa-arrow-right"/>
                    <span>{{ direction.to }}</span>
                </label>
            </div>
        </div>

        <h3>Fahrzeug</h3>
        <div class="vehicle-container">
            <div class="vehicle" :class="{ 'selected' : vehicle.id === selectedVehicle?.id }" v-for="vehicle in selectionData.vehicleTypes" :key="vehicle.id">
                <input :id="'vehicle-' + vehicle.id" type="radio" :value="vehicle" v-model="selectedVehicle" @click="resetProductSelection">
                <label :for="'vehicle-' + vehicle.id">{{ vehicle.name }}</label>
            </div>
        </div>

        <h3>Produktfilter</h3>
        <div class="additional-options-container">
            <CheckboxInput text="Sylter" v-model="syltDiscount" @click="resetProductSelection"/>
        </div>

        <h3>Produkt</h3>
        <div class="product-container">
            <div class="product" :class="{ 'selected' : product.id === modelValue?.id }" v-for="product in filteredProducts" :key="product.id">
                <input :id="'product-' + product.id" type="radio" :value="modelValue" @click="handleProductSelection(product)">
                <label :for="'product-' + product.id">
                    <span>{{ product.name }}</span>
                </label>
            </div>
            <div class="product padding"></div>
            <div class="product padding"></div>
            <div class="product padding"></div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import CheckboxInput from '@elements/CheckboxInput.vue'

export default {
    name: 'on-site-sales',
    inject: [ 'util', 'session', 'globalState' ],
    props: {
        modelValue: Object
    },
    data() {
        return {
            syltDiscount: false,
            selectedDirection: undefined,
            selectedVehicle: undefined,
            selectionData: {
                directions: [],
                vehicleTypes: [],
                products: []
            }
        }
    },
    mounted() {
        this.init()
    },
    computed: {
        filteredProducts() {
            return this.selectionData.products.filter(product => {
                const vehicleFilter = !this.selectedVehicle || product.vehicleType === this.selectedVehicle.name
                const sylterFilter  = product.sylter === this.syltDiscount

                return vehicleFilter && sylterFilter
            })
        }
    },
    methods: {
        async init() {
            if (!this.globalState.directions) {
                this.globalState.directions = await this.getDirections()
            }
            this.selectionData.directions = this.globalState.directions
            const direction = this.session.shift.location.direction - 1
            this.selectedDirection = this.selectionData.directions.at(direction)


            if (!this.globalState.vehicles) {
                this.globalState.vehicles = await this.getVehicles()
            }
            this.selectionData.vehicleTypes = this.globalState.vehicles
            this.selectedVehicle = this.selectionData.vehicleTypes[0]

            if (!this.globalState.products) {
                this.globalState.products = await this.getProducts()
            }
            this.selectionData.products = this.globalState.products

            this.syltDiscount = !!this.globalState.customer?.profileSettings?.find(profile => profile.name.toLowerCase().includes('sylter'))
        },
        async getDirections() {
            let directionsUrl = this.util.middleware()
            directionsUrl += '/ticketing/relations'

            return axios.get(directionsUrl)
            .then(response => {
                return response.data.map((direction) => this.mapDirection(direction))
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async getVehicles() {
            let vehiclesUrl = this.util.middleware()
            vehiclesUrl += '/ticketing/vehicles'

            return axios.get(vehiclesUrl)
            .then(response => {
                return response.data
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async getProducts() {
            let productsUrl = this.util.middleware()
            productsUrl += '/ticketing/products'

            return axios.get(productsUrl)
            .then(response => {
                return response.data
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async handleProductSelection(product) {
            product.price  = await this.getPrice(product.id)
            product.amount = 1 // reset amount

            this.$emit('update:modelValue', {
                product,
                direction: this.selectedDirection,
                vehicle:   this.selectedVehicle
            })
        },
        resetProductSelection() {
            this.$emit('update:modelValue', undefined)
        },
        formatPrice(priceInCent) {
            if (!priceInCent) {
                return ''
            }

            const locale = 'de-DE'
            const eurFormat = Intl.NumberFormat(locale, {
                style: 'currency',
                currency: 'EUR'
            })

            return eurFormat.format(priceInCent / 100)
        },
        mapDirection(direction) {
            return {
                id: direction.id,
                from: direction.originName,
                fromId: direction.originId,
                to: direction.destinationName,
                toId: direction.destinationId
            }
        },
        async getPrice(idProduct) {
            let priceUrl = this.util.middleware()
            priceUrl += '/ticketing/products/'+ idProduct
            priceUrl += '/price'

            return axios.get(priceUrl)
            .then(response => {
                return response.data
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        }
    },
    components: {
        CheckboxInput
    },
    watch: {
        'globalState.customer'() {
            this.syltDiscount = !!this.globalState.customer?.profileSettings?.find(profile => profile.name.toLowerCase().includes('sylter'))
        }
    },
}
</script>

<style lang="scss" scoped>
.on-site-sales-container {
    width: 100%;
    height: 100%;
    background: #fff;

    font-size: 1.2em;
    font-weight: bold;

    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;

    h3 {
        margin-bottom: 4px;
        text-align: left;
        font-weight: normal;
    }
}

.direction-container {
    display: flex;
    > .direction {
        flex: 1;
        border-radius: 8px;
        box-shadow: 0 2px 8px #00000022;
        margin: 0 4px;

        &:hover {
            background: #00000022;
        }

        &.selected {
            background-color: #63a615;
            color: #fff;
            border: none;
        }
        &:hover,
        &:hover :deep(*) {
            cursor: pointer;
        }

        > input {
            display: none;
        }

        > label {
            padding: 8px 16px;
            display: block;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.2em;

            > svg {
                margin: 0 8px;
                height: 14px;
            }
        }
    }
}

.vehicle-container {
    display: flex;

    > .vehicle {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        box-shadow: 0 2px 8px #00000022;
        margin: 0 4px;

        &:hover {
            background: #00000022;
        }

        &.selected {
            background-color: #63a615;
            color: #fff;
            border: none;
        }

        &:hover,
        &:hover :deep(*) {
            cursor: pointer;
        }

        > input {
            display: none;
        }

        > label {
            padding: 8px 16px;
            display: block;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.2em;
            width: 100%;
            height: 100%;
        }
    }
}

.additional-options-container {
    display: flex;
    .selected {
        background-color: #63a615;
        color: #fff;
        border: none;
    }

    > div {
        flex: 1;
        max-width: 50%;

        &:first-of-type {
            margin-right: 4px;
        }

        &:last-of-type {
            margin-left: 4px;
        }
    }
}

.product-container {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    justify-content: space-between;

    > .product {
        width: 20%;
        min-width: 175px;
        min-height: 76px;
        border-radius: 4px;
        margin: 4px 0;

        border-radius: 8px;
        box-shadow: 0 2px 8px #00000022;
        // margin: 0 4px;

        &.selected {
            //background-color: #26a;
            background-color: #63a615;
            color: #fff;
            border: none;
        }

        &:hover:not(.selected) {
            border: none;
            background: #00000022;
            padding: 1px;
        }

        &:hover,
        &:hover :deep(*) {
            cursor: pointer;
        }

        &.padding {
            height: 0;
            padding: 0;
            visibility: hidden;
        }

        > input {
            display: none;
        }

        > label {
            padding: 8px 16px;
            height: 100%;
            width: 100%;
            font-size: 1.2em;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.info-container {
    display: flex;

    > div {
        flex: 1;

        &:not(:first-child) {
            margin-left: 16px;
        }

        :deep(input) {
            text-transform: uppercase;
        }
    }

    > button {
        margin-left: 8px;
    }
}

.summary-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    > label {
        display: block;
        margin-left: auto;
    }

    > .price {
        font-size: 2.5em;
        font-weight: bold;
    }

    > .info {
        text-align: right;
    }
}

.actions {
    margin-top: auto;
    display: flex;

    > .buy {
        // bigger?
        margin-left: auto;
    }
}
</style>