import App from './App.vue'
import axios from 'axios'
import router from './router'
import utility from '@plugins/util.js'
import toast, { POSITION, ToastOptions } from '@plugins/toast/index.js'
import FontAwesomeIcon from '@plugins/font-awesome.js'
import { Hws, LISTENER } from '@plugins/hws'
import { connectKeycloak } from '@plugins/keycloak'
import { createApp, reactive } from 'vue'
import { globalState, keycloakState, sessionState } from '@lib/state.js'

const vueApp = createApp(App)

// >--< >--< >--< >--< >--< >--< >--< >--<
// >--< >--< STATE  DECLARATIONS >--< >--<
// >--< >--< >--< >--< >--< >--< >--< >--<
const hwsState = reactive(new Hws([LISTENER.BARCODE, LISTENER.LICENSE_PLATE, LISTENER.BROADCAST_STATUS, LISTENER.VEHICLE]))
// >--< >--< >--< >--< >--< >--< >--< >--<

// >--< >--< >--< >--< >--< >--< >--< >--<
// >--< >--<  STARTUP FUNCTIONS  >--< >--<
// >--< >--< >--< >--< >--< >--< >--< >--<

/**
 * (asynchronous) function that does the very basic setup
 * before the app can be "run()"
 * mainly handles provisions and configuration calls that
 * are needed for the app to function properly or at all
 */
async function setup() {
    const configResponse   = await axios.get(utility.middleware() + '/configuration')

    // --- --- --- HWS --- --- ---
    hwsState.enabled = configResponse.data.hws.enabled
    // --- --- --- --- --- --- ---

    // --- APPLICATION PROVISIONS ---
    // provisions are done first, so the state can be populated by
    // and used to configure plugins
    vueApp.provide('session',     sessionState)
    vueApp.provide('util',        utility)
    vueApp.provide('globalState', globalState)
    vueApp.provide('keycloak',    keycloakState)
    vueApp.provide('hws',         hwsState)
    // --- --- --- --- --- --- ---

    // --- KEYCLOAK INSTANTIATION ---
    const keycloakInstance = await connectKeycloak(configResponse.data.keycloak)
    Object.assign(keycloakState, {
        available: true,
        instance: keycloakInstance
    })
    // --- --- --- --- --- --- ---

    // --- SESSION INSTANTIATION ---
    // after the keyloack auth is established, we can decide if we need a new Session
    // or if we can use the old one
    // keycloak provides an "auth_time" param in the parsed token
    // with that we can differntiate if the user just logged in, or if the keycloak auth session
    // was still active
    // if the session was still active, we will reuse the sessionState, if not we create a new one
    const loginAuthTime = keycloakInstance.tokenParsed.auth_time
    if (sessionState.authTime !== loginAuthTime) {
        sessionState.reinit(loginAuthTime)
    }
    // --- --- --- --- --- --- ---

    // --- AXIOS SETUP ---
    // we set up axios to e.g. send headers with every request like the KC token
    configureAxios(keycloakState, globalState)
    // --- --- --- --- --- --- ---

    globalState.bookkeepingConfig = configResponse.data.bookkeepingConfig
    globalState.systemInfo = configResponse.data.systemInfo
    globalState.checkInUserId = configResponse.data.checkInUserId
}

/**
 * 1. takes a responsive keycloak state to send the current token
 *    with everey request.
 *    this does not work when the reference of the property is passed directly
 *    -> "keycloakResponsiveState.instance.token" as param
 *        since the reactivity is not deep!
 *
 * 2. keeps timers for every request that trigger a loading State if the request
 *    takes too long. Adds a timer for every request, removes a time for every response in time
 *
 * @param {reactive<keycloakState>} keycloakResponsiveState reactive keycloak state object
 */
function configureAxios(keycloakResponsiveState, globalResponsiveState, timeBeforeUiBlockInMs = 2000) {
    const loadingTimerIds = []

    axios.interceptors.request.use(config => {
        config.headers['Authorization'] = 'Bearer ' + keycloakResponsiveState.instance.token

        const newTimerId = setTimeout(() => {
            globalResponsiveState.isLoading = true
        }, timeBeforeUiBlockInMs)

        loadingTimerIds.push(newTimerId)

        // pass timeId to config, so that it is available in the response
        config.timerId = newTimerId
        // also pass time of start so wen can check if the timer triggered
        config.timerStart = Date.now()

        return config
    })

    axios.interceptors.response.use(response => {
        responseTimerHandler(response)
        return response
    },
    error => {
        if (error.response) {
            responseTimerHandler(error.response)
        } else {
            responseTimerHandler(error)
        }
        throw error
    })

    // handles timers from resposnes
    const responseTimerHandler = (response) => {
        // cancel the timer
        // and if triggered reset the loading state
        const timerIdToCancel = response.config.timerId
        const timerIndex = loadingTimerIds.indexOf(timerIdToCancel)
        const timerStart = response.config.timerStart
        const now = Date.now()

        const timerTriggered = (now - timerStart) > timeBeforeUiBlockInMs

        if (timerTriggered) {
            // if the timer was triggered, reset the loading screen
            globalResponsiveState.isLoading = false
        }

        // remove the timer id from the list and clear timeout
        loadingTimerIds.splice(timerIndex, 1)
        clearTimeout(timerIdToCancel)

        // follow the response chain
    }
}

/**
 * (asynchronous) function that is called after the app is "run()"
 */
async function initAppData() {
    const localDevice = window.localStorage.getItem('localDevice')
    if (localDevice && sessionState.hasOpenShift) {
        const baseUrl = process.env.NODE_ENV === 'production' ? '/psp-sylt' : ''
        hwsState.init(JSON.parse(localDevice)?.data, baseUrl)
    }

    let bookkeepingURL = utility.middleware()
    bookkeepingURL += '/configuration/booking-data'

    await axios.get(bookkeepingURL)
    .then((response) => {
        Object.assign(globalState, {
            bookkeepingConfig: response.data
        })
    })

    let checkUrl = utility.middleware()
    checkUrl += '/configuration/base-data'

    return axios.get(checkUrl)
    .then(({ data }) => {
        Object.assign(globalState, {
            baseData: data
        })
    })
}

/**
 * (asynchronous) function that is called after the basic "setup()" is done
 * represents the acutal app start
 */
async function run() {
    // --- REGISTER COMPLETE PLUGINS ---
    vueApp.use(router)

    const toastOptions = new ToastOptions()
    toastOptions.position = POSITION.BOTTOM_LEFT
    vueApp.use(toast, toastOptions)

    vueApp.component('fa-icon', FontAwesomeIcon)
    // --- --- --- --- --- --- ---

    // --- MOUNT THE APP --- (actual app start)
    vueApp.mount('#app')
}
// >--< >--< >--< >--< >--< >--< >--< >--<

// >--< >--< >--< >--< >--< >--< >--< >--<
// >--< APP START  PROMISE CHAIN >--< >--<
// >--< >--< >--< >--< >--< >--< >--< >--<
setup()
.then(async () => {
    run()
})
.then(() => {
    initAppData()
})
// >--< >--< >--< >--< >--< >--< >--< >--<
