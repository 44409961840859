import LandingPage from '@views/LandingPage.vue'
import CustomerPage from '@views/CustomerPage.vue'
import BeginShiftPage from '@views/BeginShiftPage.vue'
import ShoppingCartPage from '@views/ShoppingCartPage.vue'
import UserManagementPage from '@views/UserManagementPage'
import CustomerSearchPage from '@views/CustomerSearchPage.vue'
import ShiftManagementPage from '@views/ShiftManagementPage.vue'
import ShiftSupervisionPage from '@views/ShiftSupervisionPage.vue'
import ManualBookingPage from '@views/ManualBookingPage.vue'

import { keycloakState, sessionState } from '@lib/state'
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/begin-shift',
        name: 'begin-shift',
        component: BeginShiftPage,
        meta: {
            nav: false,
            explicitNoNav: true,
            hideCustomer: true
        }
    },
    {
        path: '/users',
        name: 'user-management',
        component: UserManagementPage,
        meta: {
            rights: [ 'user-management' ],
            nav: true,
            display: 'Benutzer'
        }
    },
    {
        path: '/landing-page',
        name: 'landing-page',
        component: LandingPage,
        meta: {
            rights: [ 'sales' ],
            nav: true,
            display: 'Verkauf'
        }
    },
    {
        path: '/customers',
        name: 'customer-page',
        component: CustomerPage,
        meta: {
            rights: [ 'customer-care' ],
            // path that is used for sub-page-back
            parentPath: '/customer-search',
            display: 'Kundendaten',
            denyCloseActiveCustomer: true,
            denyOpenActiveCustomer: true
        }
    },
    {
        path: '/customer-search',
        name: 'customer-search',
        component: CustomerSearchPage,
        meta: {
            nav: true,
            rights: [ 'customer-care' ],
            display: 'Kundendaten'
        }
    },
    {
        path: '/manual-booking',
        name: 'manual-booking',
        component: ManualBookingPage,
        meta: {
            rights: [ 'refunds-and-subsequent-payments' ],
            nav: true,
            display: 'Nachbuchungen'
        }
    },
    {
        path: '/closure',
        name: 'closure',
        component: ShiftManagementPage,
        meta: {
            nav: true,
            display: 'Abschluss'
        }
    },
    {
        path: '/shift-supervision',
        name: 'shift-supervision',
        component: ShiftSupervisionPage,
        meta: {
            nav: true,
            display: 'Schichtübersicht'
        }
    },
    // {
    //     path: '/configuration',
    //     name: 'configuration',
    //     component: undefined,
    //     meta: {
    //         nav: true,
    //         display: 'Konfiguration'
    //     }
    // },
    {
        path: '/cart',
        name: 'shopping-cart',
        component: ShoppingCartPage,
        meta: {
            // maybe real navigation stack?
            parentPath: '/',
            display: 'Warenkorb',
        }
    },
    // catch all non existing routes
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        // always redirect to landing page
        beforeEnter: (to, from, next) => {
            next({ path: '/', query: to.query })
        }
    }
]

const base = process.env.NODE_ENV === 'production' ? '/psp-sylt' : ''

const router = new createRouter({
    history: createWebHistory(base),
    routes
})

router.beforeEach((to/*, from*/) => {
    // check if the user is allowed to navigate to the given page
    const rightsToRoute   = to.meta.rights
    const userPermissions = keycloakState.instance.permissions
    
    // check if the user already opened his shift
    if (to.name !== 'begin-shift' && !sessionState.hasOpenShift) {
        // default route without open shift is 'begin-shift'
        return { name: 'begin-shift' }
    } else if (to.path === '/') {
        // else we route the user to the first page he is allowed to see
        return _getAllowedDefaultRoute(userPermissions)
    }

    if (to.name !== 'begin-shift' && rightsToRoute && !userPermissions.find(permission => rightsToRoute.includes(permission))) {
        return _getAllowedDefaultRoute(userPermissions)
    }
})

const _getAllowedDefaultRoute = (userPermissions) => {
    // if not, send the user to the the first page he is allowed to access
    for (const route of routes) {
        const routeHasRights          = !!route.meta?.rights
        const routeIsNavigatable      = !!route.meta?.nav && !route.meta?.explicitNoNav
        const userIsAllowedToUseRoute = route.meta?.rights?.find(right => userPermissions.includes(right))

        // 1. the route needs to be accesible by navigation
        // 2. either the route needs no rights or the user is allowed to use it
        if (routeIsNavigatable && (!routeHasRights || userIsAllowedToUseRoute)) {
            return route
        }
    }

    // if even that fails, try to redirect to the landing page
    // -> possible endless loop if the user does not have the right to go to the landing page
    return { name: 'landing-page' }
}

export default router