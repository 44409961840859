<template>
    <div class="customer-search-container" @keyup.enter="handleSearch">
        <div class="inputs">
            <TextInput v-model="customerNumber" label="Kundennummer" class="customer-nr"/>
            <TextInput v-model="firstName" label="Vorname" class="firstname"/>
            <TextInput v-model="lastName" label="Nachname" class="lastname"/>
            <TextInput v-model="birthdate" label="Geburtsdatum" class="lastname" type="date"/>
            <TextInput v-model="licensePlate" label="Kennzeichen" class="license-plate"/>
        </div>

        <div class="inputs">
            <TextInput v-model="email" label="E-Mail-Adresse" class="email"/>
            <TextInput v-model="phone" label="Telefonnummer" class="phone"/>
            <TextInput v-model="street" label="Straße" class="street"/>
            <TextInput v-model="postalCode" label="PLZ" class="zip"/>
            <TextInput v-model="city" label="Ort" class="city"/>
        </div>

        <div class="inputs">
            <TextInput v-model="appInstanceNumber" label="Kartennummer" class="appinstance-nr"/>
            <TextInput v-model="ticketNumber" label="TicketID" class="ticket-nr"/>
            <TextInput v-model="formatIbanInput" label="IBAN" class="iban"/>
        </div>

        <div class="actions">
            <button class="reset" @click="clearSearchFields">
                <fa-icon icon="fa-solid fa-arrow-rotate-left"/>
                <label>Eingaben zurücksetzen</label>
            </button>
            <button class="search highlight" @click="handleSearch">
                <fa-icon icon="fa-solid fa-search" src="@img/search-bright.svg"/>
                <label>Kunde suchen</label>
            </button>
        </div>

        <div class="customer-search-results" v-show="showSearchResults" @click="showSearchResults = false" @keyup.esc="showSearchResults = false">
            <div class="result-content" @click="$event.stopPropagation()">
                <h3>Die Suche hat mehrere Treffer ergeben.</h3>

                <TextInput class="filter" label="Filter..." v-model="resultFilter"/>

                <div class="result-list">
                    <div class="result" v-for="result in filteredResults" :key="result.id" @click="handleResultSelection(result)">
                        <div class="customer-number">
                            <label class="customerNumber">{{ result.customerNumber }}</label>
                        </div>
                        <div class="personal-info">
                            <label class="name">{{ result.firstName + ' ' + result.lastName }}</label>
                        </div>
                        <div class="personal-info">
                            <label class="email">{{ result.email }}</label>
                        </div>
                        <div class="address">
                            <label class="city">{{ result.postalCode + ' ' + result.city }}</label>
                        </div>
                        <div class="icon">
                            <fa-icon icon="fa-solid fa-arrow-right"/>
                        </div>
                    </div>
                </div>

                <div class="result-actions">
                    <button @click="showSearchResults = false">
                        <fa-icon icon="fa-solid fa-xmark" src="@img/xmark.svg"/>
                        <label>Suche anpassen</label>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextInput from '@elements/TextInput.vue'
import { getCustomer, findCustomers } from '@lib/middleware-connector'

const EMIT_RESULT_SELECTION = 'result'

export default {
    name: 'customer-search',
    inject: [ 'util' ],
    emits: [ EMIT_RESULT_SELECTION ],
    data() {
        return {
            customerNumber: '',
            firstName: '',
            lastName: '',
            birthdate: '',
            licensePlate: '',
            street: '',
            postalCode: '',
            city: '',
            email: '',
            phone: '',
            ticketNumber: '',
            appInstanceNumber: '',
            iban: '',
            showSearchResults: false,
            searchResults: [],
            resultFilter: ''
        }
    },
    computed: {
        filteredResults() {
            return this.searchResults.filter(result => {
                const noFilter = !this.resultFilter || this.resultFilter.trim() === ''

                const firstNameMatch = result.firstName.toLowerCase().includes(this.resultFilter.toLowerCase())
                const lastNameMatch  = result.lastName.toLowerCase().includes(this.resultFilter.toLowerCase())
                const customerNumberMatch  = result.customerNumber.toLowerCase().includes(this.resultFilter.toLowerCase())
                const postalCodeMatch  = result.postalCode.toLowerCase().includes(this.resultFilter.toLowerCase())
                const cityMatch  = result.city.toLowerCase().includes(this.resultFilter.toLowerCase())
                const emailMatch  = result.email.toLowerCase().includes(this.resultFilter.toLowerCase())
                //TODO licensePlateMatch
                
                return noFilter || firstNameMatch || lastNameMatch || customerNumberMatch || postalCodeMatch || cityMatch || emailMatch
            })
        },
        formatIbanInput: {
            get() {
                return this.iban
            },
            set(newValue) {
                this.iban = this.util.formatDisplayIban(newValue)
            },
        }
    },
    methods: {
        async handleSearch() {
            const customerSearchInput = {
                customerNumber:    this.customerNumber,
                firstName:         this.firstName,
                lastName:          this.lastName,
                birthdate:         this.birthdate,
                street:            this.street,
                postalCode:        this.postalCode,
                city:              this.city,
                email:             this.email,
                phone:             this.phone,
                ticketNumber:      this.ticketNumber,
                appInstanceNumber: this.appInstanceNumber,
                iban:              this.iban.trim().replaceAll(' ',''),
                licensePlate:      this.licensePlate

            }

            return findCustomers(customerSearchInput)
            .then(customers => {
                if (customers.length === 0) {
                    this.$toast.info('Die Suche hat keinen Treffer ergeben.')
                    return
                }

                if (customers.length === 1) {
                    return this.handleResultSelection(customers[0])
                }

                this.searchResults     = customers
                this.showSearchResults = true
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async handleResultSelection(result) {
            try {
                const customer = await getCustomer(result.uuid)
                this.clearSearchFields()
                this.$emit(EMIT_RESULT_SELECTION, customer)
            } catch(err){
                this.$toast.error(err.response?.data?.userMessage || err.message)
                return null
            }
        },
        clearSearchFields() {
            this.customerNumber = ''
            this.firstName = ''
            this.lastName = ''
            this.birthdate = ''
            this.street = ''
            this.postalCode = ''
            this.city = ''
            this.email = ''
            this.phone = ''
            this.ticketNumber = ''
            this.appInstanceNumber = ''
            this.iban = ''
            this.showSearchResults = false
            this.licensePlate = ''
        }
    },
    components: {
        TextInput
    }
}
</script>

<style lang="scss" scoped>
.customer-search-container {
    width: 100%;
    background: #fff;
    min-height: 200px;

    display: flex;
    flex-direction: column;
}

.inputs {
    display: flex;
    flex-wrap: wrap;
    > .customer-nr {
        width: 150px;
    }

    > .ticket-nr, .iban, .license-plate {
        margin-left: 24px;
        width: 150px;
    }

    > .appinstance-nr {
        width: 190px;
    }

    > .email {
        width: 190px;
    }

    > .firstname, .lastname, .phone, .city {
        margin-left: 24px;
        width: 140px;
    }

    > .street {
        margin-left: 24px;
        width: 165px;
    }

    > .zip {
        margin-left: 24px;
        width: 90px;
    }
}

.actions {
    align-self: flex-end;
    margin-top: auto;
    display: flex;

    > .search {
        margin-left: 12px;
    }
}

.customer-search-results {
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000aa;
    display: flex;
    align-items: center;
    justify-content: center;

    > .result-content {
        background: #fff;
        border-radius: 8px;
        min-width: 500px;
        width: 45%;
        padding: 16px;

        h3 {
            text-align: left;
            margin-top: 0;
            margin-bottom: 4px;
        }

        > .filter {
            margin-top: 8px;
        }

        > .result-list {
            font-size: 1.1em;
            margin: 8px 0;
            height: 300px;
            overflow-y: auto;

            > .result {
                height: 40px;
                display: flex;
                align-items: center;
                padding: 0 8px;
                
                &:not(:last-child) {
                    border-bottom: 1px solid #ccc;
                }

                &:hover {
                    background: #00000022;
                }

                &:hover,
                &:hover :deep(*) {
                    cursor: pointer;
                }


                > .customer-number {
                    flex: 14;

                    label {
                        display: block;
                        text-align: left;
                    }

                }
                > .personal-info {
                    flex: 20;

                    label {
                        display: block;
                        text-align: left;
                    }
                }

                > .address {
                    flex: 20;
                    margin-left: 16px;
                    
                    label {
                        display: block;
                        text-align: left;
                    }
                }

                > .icon {
                    flex: 0;
                    margin-left: auto;
                    height: 85%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    > svg {
                        padding: 6px;
                        height: 50%;
                    }
                }
            }
        }

        > .result-actions {
            margin-left: auto;
            width: fit-content;
            margin-top: 8px;
        }
    }
}
</style>