<template>
    <transition :name="transitionName">
        <div :ref="REFS_TOAST" v-show="active" class="toast-container" :class="[ `toast-level-${level}`, `toast-position-${position}` ]" @click="dismiss" @mouseover="toggleTimer($event, true)" @mouseleave="toggleTimer($event, false)">
            <div class="content" @mouseover="preventEvent" @mouseleave="preventEvent">
                <label>{{ message }}</label>
                <!-- <img /> icons? -->
            </div>
            <div :ref="REFS_PROGRESS_BAR" class="progress-bar"></div>
        </div>
    </transition>
</template>

<script>
import Timer from '../lib/timer.js'
import { removeElement } from '../lib/render.js'
import { POSITION, LEVEL } from '../lib/option-definitions.js'

const REFS_PROGRESS_BAR = 'progress-bar'
const REFS_TOAST = 'toast'

export default {
    name: 'toast-component',
    props: {
        message: {
            type: String,
            required: true
        },
        level: {
            type: Number,
            validator(value) {
                return Object.values(POSITION).includes(value)
            }
        },
        position: {
            type: Number,
            validator(value) {
                return Object.values(LEVEL).includes(value)
            }
        },
        duration: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            timer: undefined,
            active: false,
            parentTop: undefined,
            parentBottom: undefined,
            REFS_PROGRESS_BAR,
            REFS_TOAST
        }
    },
    beforeMount() {
        this.setupContainer()
    },
    mounted() {
        this.show()
    },
    methods: {
        setupContainer() {
            this.parentTop = document.querySelector('.toast-parent.top')
            this.parentBottom = document.querySelector('.toast-parent.bottom')

            // No need to create them, they already exists
            if (this.parentTop && this.parentBottom) return

            if (!this.parentTop) {
                this.parentTop = document.createElement('div')
                this.parentTop.className = 'toast-parent top'
            }

            if (!this.parentBottom) {
                this.parentBottom = document.createElement('div')
                this.parentBottom.className = 'toast-parent bottom'
            }

            const container = document.body
            container.appendChild(this.parentTop)
            container.appendChild(this.parentBottom)
        },
        show() {
            // insert the toast into a prepared parent and remove the wrapper
            // that was used to insert it into the DOM via h() and render()
            // -> obtain the wrapper EL beforfe changing the position in the DOM!!
            //    else the correct parent would be removed
            const wrapper = this.toastEl.parentElement
            this.correctParent.insertAdjacentElement('afterbegin', this.toastEl)
            removeElement(wrapper)

            this.active = true
            this.timer = new Timer(this.dismiss, this.duration)
            this.startProgessBar()
        },
        dismiss() {
            if (this.timer) this.timer.stop()

            this.active = false

            // give time to let the transition play out+
            setTimeout(() => {
                removeElement(this.toastEl)
            }, 300)
        },
        toggleTimer(event, pause) {
            event.stopPropagation()
            if (!this.timer) return

            if (pause) {
                this.timer.pause()
                this.pauseProgressBar()
            } else {
                this.timer.resume()
                this.resumeProgressBar()
            }
        },
        startProgessBar() {
            this.progressBarEl.style.animationDuration  = this.duration + 'ms'
            this.progressBarEl.style.animationPlayState = 'running'
        },
        pauseProgressBar() {
            this.progressBarEl.style.animationPlayState = 'paused'
        },
        resumeProgressBar() {
            this.progressBarEl.style.animationPlayState = 'running'
        }
    },
    computed: {
        progressBarEl() {
            return this.$refs[REFS_PROGRESS_BAR]
        },
        toastEl() {
            return this.$refs[REFS_TOAST]
        },
        transitionName() {
            let name = ''

            const topPositions = [
                POSITION.TOP_LEFT,
                POSITION.TOP_MID,
                POSITION.TOP_RIGHT
            ]

            const bottomPositions = [
                POSITION.BOTTOM_LEFT,
                POSITION.BOTTOM_MID,
                POSITION.BOTTOM_RIGHT
            ]

            if (topPositions.includes(this.position)) {
                name = 'slide-down'
            }

            if (bottomPositions.includes(this.position)) {
                name = 'slide-up'
            }

            return name
        },
        correctParent() {
            switch (this.position) {
                case POSITION.TOP_MID:
                case POSITION.TOP_RIGHT:
                case POSITION.TOP_LEFT:
                    return this.parentTop
                case POSITION.BOTTOM_MID:
                case POSITION.BOTTOM_RIGHT:
                case POSITION.BOTTOM_LEFT:
                    return this.parentBottom
                default:
                    return undefined
            }
        },
    }
}
</script>

<style lang="scss">
// global parent styles
.toast-parent {
    position: fixed;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2em;
    overflow: hidden;
    z-index: 99999;
    pointer-events: none;

    &.top {
        flex-direction: column;
    }

    &.bottom {
        flex-direction: column-reverse;
    }
}
</style>

<style lang="scss" scoped>
.toast-container {
    padding: 8px 12px;
    margin: 4px;
    width: fit-content;
    min-width: 200px;
    color: #fff;
    box-shadow: 0 2px 8px #00000022;
    font-size: 1.5em;
    border-radius: 8px;
    overflow: hidden;
    pointer-events: auto;
    position: relative;
    max-width: 500px;
    text-align: justify;
    // default color
    background-color: #666666f3;

    :deep(*) {
        //prevent children from interfering with the pause/resume logic
        pointer-events: none;
    }

    &.toast-position- {
        &0, // top-left, bottom-left
        &3 {
            align-self: flex-start;
        }

        &1,// top-mid, bottom-mid
        &4 {
            align-self: center;
        }

        &2, // top-right, bottom-right
        &5 {
            align-self: flex-end;
        }
    }

    &.toast-level- {
        &0 { // info
            background-color: #0070C0;
        }

        &1 { // warn
            background-color: #ffcc00f3;
            color: #333;
        }

        &2 { // success
            background-color: #00B050;
        }

        &3 { // error
            background-color: #C00000;
        }
    }

    > .progress-bar {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: #ffffff99;
        height: 4px;
        animation: progressbar;
        animation-play-state: paused;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }
}

// progress bar animation
@keyframes progressbar {
    0% {
        width: 100%;
    }
    100% {
        width: 0%;
    }
}

// transitions
.slide-down-enter-active,
.slide-down-leave-active,
.slide-up-enter-active,
.slide-up-leave-active {
    transition: all 300ms ease-out;
}

.slide-down-enter-from {
    transform: translateY(-50%);
    opacity: 0;
}

.slide-down-leave-to {
    transform: translateY(-50%);
    opacity: 0;
}

.slide-up-enter-from {
    transform: translateY(50%);
    opacity: 0;
}

.slide-up-leave-to {
    transform: translateY(50%);
    opacity: 0;
}
</style>