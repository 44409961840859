import Keycloak from 'keycloak-js'

export async function connectKeycloak({ url, realm, clientId, refreshInSeconds = 30 } = {}) {
    const keycloakConfig = {
        url,
        realm,
        clientId
    }

    const keycloak = new Keycloak(keycloakConfig)

    const initOptions = {
        onLoad: 'login-required',
        useNonce: true,
        checkLoginIframe: false
    }

    return keycloak.init(initOptions)
    .then(authenticated => {
        setInterval(() => {
            if (keycloak.authenticated) {
                keycloak.updateToken(900)
                .catch(() => {
                    keycloak.logout()
                })
            }
        }, refreshInSeconds * 1000)

        if (authenticated) {
            const realmAccess    = keycloak.realm_access?.roles || []
            const resourceAccess = Object.keys(keycloak.resourceAccess).map(key => {
                return keycloak.resourceAccess[key]?.roles || []
            }).flat()

            keycloak.permissions = [...realmAccess, ...resourceAccess]
            return keycloak
        } else {
            throw 'kc-connection-failed'
        }
    })
    .catch(() => {
        // just returns 'true' as err value
        throw 'kc-init-error'
    })
}