<template>
    <div class="begin-shift-page-wrapper">
        <div class="shift-content-container">
            <div class="entry-header">
                <label class="entry-title">Schicht beginnen</label>
            </div>

            <div class="entry-content">
                <div class="inputs">
                    <DropDownInput v-model="location" :option-list="locationOptionList" label="Standort" @input="handleLocationSelection"/>
                    <DropDownInput :disabled="!location" v-model="device" :option-list="deviceOptionList" label="Gerät"/>
                </div>
                <div class="actions">
                    <button @click="logout">
                        <label>Ausloggen</label>
                        <fa-icon icon="fa-solid fa-arrow-right-from-bracket"/>
                    </button>
                    <button class="highlight" @click="beginShift" :disabled="!device">
                        <label>Schicht beginnen</label>
                        <fa-icon icon="fa-solid fa-play"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Shift } from '@lib/session.js'

import DropDownInput from '@elements/DropDownInput.vue'
import axios from 'axios'

export default {
    name: 'begin-shift-page',
    inject: [ 'globalState', 'keycloak', 'session', 'util', 'hws' ],
    data() {
        return {
            location: undefined,
            device: undefined,
            locationOptionList: [],
            deviceOptionList: []
        }
    },
    mounted() {
        this.init()
        .then(() => {
            this.location = JSON.parse(window.localStorage.getItem('localLocation'))

            if (this.location) {
                return this.handleLocationSelection()
            } else {
                return undefined
            }
        })
        .then(() => {
            this.device = JSON.parse(window.localStorage.getItem('localDevice'))
        })
    },
    methods: {
        async init() {
            // we need the locations for any case
            const availableLocations = await this.getLocations()
            this.locationOptionList = availableLocations.map(location => {
                return {
                    displayid: location.id,
                    displayname: location.name,
                    direction : location.direction
                }
            })
        },
        async getLocations() {
            let locationsUrl = this.util.middleware()
            locationsUrl += '/shifts/locations'

            return axios.get(locationsUrl)
            .then(response => {
                return response.data
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async handleLocationSelection() {
            this.device = undefined

            return this.getDevices(this.location.displayid)
            .then(devices => {
                this.deviceOptionList = devices.map(device => {
                    return {
                        displayid: device.id,
                        displayname: device.name,
                        data: device
                    }
                })
            })
        },
        async getDevices(locationId) {
            let devicesUrl = this.util.middleware()
            devicesUrl += '/shifts/locations/' + locationId
            devicesUrl += '/devices'

            return axios.get(devicesUrl)
            .then(response => {
                return response.data
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async doLogin(deviceId, staffId) {
            let loginUrl = this.util.middleware()
            loginUrl += '/shifts/devices/' + deviceId
            loginUrl += '/staff/' + staffId
            loginUrl += '/login'

            this.location = window.localStorage.setItem('localLocation', JSON.stringify(this.location))
            this.device = window.localStorage.setItem('localDevice', JSON.stringify(this.device))

            return axios.get(loginUrl)
            .then(response => {
                return response.data
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        beginShift() {
            const staffNr = this.keycloak?.instance?.tokenParsed?.BenutzerNr
            const newShift = new Shift()
            newShift.location = this.location
            newShift.device   = this.device
            newShift.staffNr  = staffNr

            this.doLogin(this.device.displayid, staffNr)
            .then(loginData => {
                newShift.id = loginData.id

                this.session.shift = newShift
                
                const baseUrl = process.env.NODE_ENV === 'production' ? '/psp-sylt' : ''
                this.hws.init(newShift.device.data, baseUrl)

                this.$router.push({ name: 'landing-page' })
            })
            .catch(error => {
                console.log(error)
            })
        },
        logout() {
            this.keycloak.instance.logout()
        }
    },
    components: {
        DropDownInput
    }
}
</script>

<style lang="scss" scoped>
.begin-shift-page-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;

    > .shift-content-container {
        width: 600px;
        background-color: #fff;
        box-shadow: 0 2px 8px #00000022;
        border-radius: 8px;
        height: fit-content;
    }
}

.shift-content-container {
    > .entry-header {
        padding: 8px;
        padding-bottom: 0;
        display: flex;

        > .entry-title {
            margin: 8px;
            font-weight: bold;
            font-size: 2em;
        }

        > svg {
            margin-left: auto;
            object-fit: contain;
            height: 16px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    > .entry-content {
        // full height minus header
        height: calc(100% - 58px);
        padding: 16px;
        padding-top: 0;

        > .inputs {
            margin-top: 20px;
        }

        > .actions {
            margin-top: 20px;
            display: flex;
            justify-content: flex-end;

            > button {
                &:not(:first-of-type) {
                    margin-left: 8px;
                }
            }
        }
    }
}
</style>