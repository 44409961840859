<template>
    <div class="active-customer-container">
        <div class="customer-container" :class="{ 'hidden' : this.hideCustomer }">
            <div class="customer" >
                <fa-icon icon="fa-regular fa-user"/>
                <div class="name" v-if="customer">
                    <label>Kundennummer</label>
                    <label>{{ customer?.customerNumber  }}</label>
                </div>
                <div class="name" v-if="customer">
                    <label>Name</label>
                    <label>{{ customer?.firstName + ' ' + customer?.lastName  }}</label>
                </div>
                <div class="address" v-if="customer">
                    <label>Adresse</label>
                    <label>{{ customer?.street + ' ' + customer?.houseNumber + ', ' + customer?.postalCode + ' ' + customer?.city }}</label>
                </div>
                <div class="contact" v-if="customer">
                    <label>E-Mail-Adresse</label>
                    <label>{{ customer?.email }}</label>
                </div>
            </div>

            <div class="actions">
                <button class="open-customer" :disabled="(!!this.$route.meta.denyOpenActiveCustomer)||(!customer)" @click="goToCustomer">
                    <fa-icon icon="fa-solid fa-up-right-from-square"/>
                    <label>Kunde öffnen</label>
                </button>
                <button class="change-customer" @click="showSearch = true">
                    <fa-icon icon="fa-solid fa-search"/>
                    <label>Kunde wechseln</label>
                </button>
                <button class="close-customer" :disabled="!!this.$route.meta.denyCloseActiveCustomer" @click="closeCustomer">
                    <fa-icon icon="fa-solid fa-xmark" />
                    <label>Kunde schließen</label>
                </button>
            </div>
        </div>

        <div class="quick-search-container" :class="{ 'hidden' : !this.hideCustomer, 'latest-open' : showLatestSearches }">
            <!-- <TextInput label="Schnellsuche..." v-on:keyup.enter="quickSearch" v-model="quickSearchInput"/> -->
            <DropDownInput class="quick-search-field" v-model="selectedQuickSearchField" :optionList ="quickSearchFieldsOptionList" :defaultOption="false"/>
            <input class="quick-search" type="text" placeholder="Schnellsuche..." @click="openLatestSearches" v-on:keyup.enter="handleQuickSearch" v-model="customerSearchInput.quickSearch"/>
            <div class="latest-searches-container" v-if="false" v-show="showLatestSearches" @click="$event.stopPropagation()">
                <div class="latest-searches">
                    <h3>Letzte Suchen</h3>
                    <div v-for="(result, idx) in latestSearchResults" :key="idx" @click="handleQuickSearchResult">
                        <label>{{ result.name }}</label>
                    </div>
                </div>
            </div>

            <button class="search highlight" @click="handleQuickSearch">
                <fa-icon icon="fa-solid fa-search"/>
                <!---- <label>Kunde suchen</label> -->
                <label>{{ this.quickSearchText }}</label>
            </button>
        </div>

        <CustomerSearchResults v-show="showSearchResults" :searchResults="searchResults" @result="handleCustomerSearchResult" @close="showSearchResults = false"/>

        <PopUp :open="showSearch" title="Kundensuche" @close="showSearch = false">
            <CustomerSearch ref="customerSearch" @result="handleCustomerSearchResult"/>
            
            <template v-slot:actions>
                <!-- no buttons placeholder -->
                <label></label>
            </template>
        </PopUp>

        <PopUp class="ticket-overview" title="Ticketübersicht" :open="selectedTickets.length > 0" @close="selectedTickets = []">
            <TicketDisplay class="ticket-content" :tickets="selectedTickets" :withCustomer="true" @update:selectedCustomer="handleCustomerSelection" @update:ticket="handleTicketUpdate"/>

            <template v-slot:actions>
                <div class="actions">
                    <button @click="selectedTickets = []">
                        <fa-icon icon="fa-solid fa-xmark" src="@img/xmark.svg" />
                        <label>Abbrechen</label>
                    </button>
                </div>
            </template>
        </PopUp>        
    </div>
</template>

<script>
import PopUp from '@elements/PopUp.vue'
import DropDownInput from './elements/DropDownInput.vue'
import CustomerSearch from '@components/CustomerSearch.vue'
import { ToastOptions } from '@plugins/toast/index.js'
import CustomerSearchResults from '@components/CustomerSearchResults.vue'
import { addCustomerToTicket, findCustomers, getCustomer, QUICK_SEARCH_FIELDS } from '@lib/middleware-connector'
import TicketDisplay from '@components/TicketDisplay.vue'
import axios from 'axios'

export default {
    name: 'active-customer',
    inject: ['globalState', 'util'],
    props: {
        customer: {
            type: Object
        },
    },
    data() {
        return {
            showSearch: false,
            showCardReader: false,
            showSearchResults: false,
            searchResults: [],
            selectedQuickSearchField: undefined,
            quickSearchText: 'Kunde Suchen',
            selectedTickets: [],
            customerSearchInput: {
                quickSearch: '',
                quickSearchField: ''
            },
            quickSearchFieldsOptionList: [
                {displayid: QUICK_SEARCH_FIELDS.EMAIL,                displayname: 'E-Mail-Adresse'},
                {displayid: QUICK_SEARCH_FIELDS.BIRTHDATE,            displayname: 'Geburtsdatum'},
                //{displayid: QUICK_SEARCH_FIELDS.APP_INSTANCE_NUMBER,  displayname: 'Kartennummer'},
                //{displayid: QUICK_SEARCH_FIELDS.LICENCE_PLATE,        displayname: 'Kennzeichen'},
                {displayid: QUICK_SEARCH_FIELDS.CUSTOMER_NUMBER,      displayname: 'Kundennummer'},
                {displayid: QUICK_SEARCH_FIELDS.LAST_NAME_FIRST_NAME, displayname: 'Nachname, Vorname'},
                {displayid: QUICK_SEARCH_FIELDS.TICKET_ID,           displayname: 'Ticket-ID'},
            ],
            showLatestSearches: false,
            latestSearchResults: [
                { name: 'Maxima Musterfrau' },
                { name: 'Rainer Zufall' },
                { name: 'Max Mustermann' }
            ]
        }
    },
    computed: {
        hideCustomer() {
            return !this.globalState.customer
        }
    },
    mounted() {
        this.selectedQuickSearchField = this.quickSearchFieldsOptionList.find( entry => entry.displayid === QUICK_SEARCH_FIELDS.LAST_NAME_FIRST_NAME)
    },
    watch: {
        selectedQuickSearchField(newValue) {
            if (newValue.displayid === QUICK_SEARCH_FIELDS.TICKET_ID) {
                this.quickSearchText= 'Ticket Suchen'   
            } else {
                this.quickSearchText= 'Kunde Suchen'   
            }
        }
    },
    methods: {
        openLatestSearches(event) {
            // this.$nextTick(() => {
            //     // focus searchInput after showing v-show
            //     // -> get TextInput component, then get html input element
            //     const textInputEl = this.$refs[this.searchInputRef]
            //     const inputEl = textInputEl.$refs.input
            //     inputEl.focus()
            // })
            
            if (event) {
                event.stopPropagation()
            }

            // register click-outside event on body
            document.addEventListener('click', () => {
                this.showLatestSearches = false
            })

            this.showLatestSearches = true
        },

        async tryGetTicketData(ticketId){
            let dataUrl = this.util.middleware()
            dataUrl += '/ticketing/ticket'
            dataUrl += '/' + ticketId
            
            return axios.get(dataUrl)
            .then((response) => {
                return response.data                    
            
            })
        },

        async searchTicketId(ticketId){
            this.selectedTickets = []

            this.tryGetTicketData(ticketId)
            .then(async (ticket) => {
                ticket = await addCustomerToTicket(ticket)                
                this.selectedTickets = [ticket]
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },

        async handleCustomerSelection(customerSearchResult) {
            if (customerSearchResult) {
                try {
                    const customer = await getCustomer(customerSearchResult.uuid)
                    Object.assign(this.globalState, { customer })
                    this.selectedTickets = []
                } catch(err){
                    this.$toast.error(err.response?.data?.userMessage || err.message)
                    return null
                }
            }
        },      
        
        handleTicketUpdate(ticket) {
            const index = this.selectedTickets.findIndex((data) => ticket.orderId === data.orderId)
            if (index > -1) {
                    this.selectedTickets.splice(index, 1, ticket)
                }
        },

        async handleQuickSearch() {
            const toastOptions = new ToastOptions({ duration: 5000 })

            this.customerSearchInput.quickSearchField = this.selectedQuickSearchField.displayid

            try {
                if (this.customerSearchInput.quickSearchField === QUICK_SEARCH_FIELDS.TICKET_ID) {
                        
                    if (this.customerSearchInput.quickSearch.trim().length === 0) {
                        this.$toast.info('Die Schnellsuche ist leer oder hat ein falsches Format.', toastOptions)
                    } else {
                        await this.searchTicketId(this.customerSearchInput.quickSearch)
                    }



                } else {
                    const data = await findCustomers(this.customerSearchInput)

                    if (data.length === 0) {
                        this.$toast.info('Die Suche hat keinen Treffer ergeben.', toastOptions)
                        return
                    }

                    if (data.length === 1) {
                        await this.handleResultSelection(data[0])
                        return
                    }

                    this.searchResults = data
                    this.showSearchResults = true
                }

            } catch (error) {
                this.$toast.info('Die Schnellsuche ist leer oder hat ein falsches Format.', toastOptions)
            }
        },
        handleQuickSearchResult() {
            this.customerSearchInput.quickSearch = ''
            this.showSearch = false
        },
        goToCustomer() {
            this.$router.push({ name: 'customer-page'})
        },
        closeCustomer() {
            this.globalState.customer = undefined
        },
        async handleOpenCustomerFromCardData(appInstanceNumber) {
            try {
                const searchResult = await findCustomers({ appInstanceNumber })
                if (searchResult && searchResult.at(0)) {
                    await this.handleResultSelection(searchResult[0])
                }
                this.showCardReader = false
            } catch(err) {
                this.$toast.error(err)
            }
        },
        async handleResultSelection(result) {
            try {
                const customer = await getCustomer(result.uuid)
                this.handleCustomerSearchResult(customer)
            } catch (error) {
                return null
            }
        },
        handleCustomerSearchResult(customer) {
            Object.assign(this.globalState, { customer })
            this.customerSearchInput.quickSearch = ''
            this.showSearchResults = false
            this.showSearch = false
        }
    },
    components: {
        PopUp,
        CustomerSearch,
        DropDownInput,
        TicketDisplay,
        CustomerSearchResults
    }
}
</script>

<style lang="scss" scoped>
.active-customer-container {
    height: 50px;
    width: 100%;
    background: #fff;
    box-shadow: 0 2px 8px #00000022;

    > .customer-container,
    > .quick-search-container {
        position: relative;
        height: 100%;
        display: flex;
        padding: 0 64px;
        overflow: hidden;

        // for transitioning only
        transition: all .3s ease;
        visibility: visible;
        opacity: 1;
        max-height: 50px;

        &.hidden {
            max-height: 0px;
            opacity: 0;
        }

        &.latest-open {
            overflow: visible;
        }

        > .search {
            margin-left: 8px;
        }

        > .content-container {
            padding-top: 0;
        }

        > .quick-search {
            margin: 8px 0;
            //border: 1px solid #26a;
            border: 1px solid #ccc;
            border-radius: 8px;
            text-indent: 164px;
            line-height: 28px;
            width: 50%;
        }

        .quick-search-field {
            position: absolute;
            padding: 8px 8px 8px 0px;

            :deep(.input) {
                font-size: 1.1em;
                border-bottom: 0px;
                color:#999;
                border-radius: 8px;
                height: 32px
            }

            :deep(.input-wrapper) {
                width: 220px;
            }
        }

        > .latest-searches-container {
            position: absolute;
            width: 50%;
            left: 25%;
            top: 42px;
            padding: 0 31px;
            z-index: 10;

            > .latest-searches {
                background-color: #fff;
                box-shadow: 0 2px 8px #00000022;
                border-radius: 8px;
                text-align: left;
                padding: 16px;

                > div {
                    padding: 8px;

                    &:not(:first-of-type) {
                        border-top: 1px solid #ccc;
                    }

                    &:hover {
                        background-color: #00000022;

                        &,
                        :deep(*) {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
.customer {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    > svg {
        height: 50%;
        object-fit: contain;
    }

    > div > label {
        display: block;
        text-align: left;
        margin-left: 1vw;

        &:nth-of-type(1) {
            color: #999;
            font-size: 10px;
        }
    }
}

.ticket-overview {
    .ticket-content {
        max-height: 600px;
        overflow: auto;
        margin-bottom: 20px;
        width: 1400px;
     }

    .actions {
        display: flex;
        justify-content: flex-end;
    }
}

.actions {
    height: 100%;
    display: flex;
    margin-left: auto;

    .change-customer,
    .close-customer {
        margin-left: 12px
    }
}
</style>