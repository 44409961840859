<template>
    <div class="user-wrapper">
        <label class="id">{{ user.staffNo }}</label>
        <label class="email">{{ user.email }}</label>
        <label class="name">{{ getFullName(user) }}</label>
        <label class="groups">
            <MultiSelectInput
                class="group-selection"
                ref="group-select"
                :event-only="true"
                @remove="handleGroupRemoval"
                @select="handleAddGroup"
                @open-actions="handleOpenGroupSelection"
                :options="groupOptions"
                :model-value="user.groups"/>
        </label>
        <div class="actions-container">
            <div :ref="REFS.CONTAINER" class="toggle" @click="handleOpenUserOptions">
                <fa-icon icon="fa-solid fa-ellipsis" />
            </div>
            <div :ref="REFS.ACTIONS" class="actions-menu" :class="{ 'open' : actionsOpen }">
                <button class="delete highlight" @click="handleDeleteUser(user)">
                    <fa-icon icon="fa-regular fa-trash-can"/>
                    <label>Nutzer löschen</label>
                </button>
                <button class="reset-password highlight" @click="handleResetPassword(user)">
                    <fa-icon icon="fa-solid fa-key" />
                    <label>Passwort zurücksetzen</label>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import MultiSelectInput from '@elements/MultiSelectInput.vue'

const EMITS = {
    REMOVE_GROUP:   'remove-group',
    ADD_GROUP:      'add-group',
    OPEN_ACTIONS:   'open-actions',
    OPEN_GROUPS:    'open-groups',
    DELETE_USER:    'delete-user',
    RESET_PASSWORD: 'reset-password'
}

const REFS = {
    CONTAINER: 'actions-container',
    ACTIONS:   'actions-content',
}

export default {
    name: 'list-user-depiction',
    emits: Object.keys(EMITS).map(key => EMITS[key]),
    inject: [ 'util' ],
    props: {
        user: {
            type: Object,
            required: true
        },
        groupOptions: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            REFS,
            actionsOpen: false
        }
    },
    mounted() {
        // add option-list to body, so it is not impacted by its direct parent (overflow: hidden)
        document.body.append(this.actionsEl)
    },
    beforeUnmount() {
        // remove the options from the body to avoid duplicates
        this.actionsEl.remove()
    },
    computed: {
        actionsEl() {
            return this.$refs[REFS.ACTIONS]
        },
        containerEl() {
            return this.$refs[REFS.CONTAINER]
        }
    },
    methods: {
        handleDeleteUser(user) {
            this.$emit(EMITS.DELETE_USER, user)
        },
        handleResetPassword(user) {
            this.$emit(EMITS.RESET_PASSWORD, user)
        },
        handleGroupRemoval(group) {
            this.$emit(EMITS.REMOVE_GROUP, group)
        },
        handleAddGroup(group) {
            this.$emit(EMITS.ADD_GROUP, group)
        },
        getFullName(user) {
            const firstName = user.firstName
            const lastName  = user.lastName
            return (firstName?? '') + ' ' + (lastName?? '')
        },
        handleOpenGroupSelection() {
            this.$emit(EMITS.OPEN_GROUPS, true)
        },
        handleOpenUserOptions(event) {
            if (!this.actionsOpen) {
                // notfiy parent
                this.$emit(EMITS.OPEN_ACTIONS)

                this.actionsOpen = true

                document.addEventListener('click', () => {
                    this.actionsOpen = false
                })

                event.stopPropagation()

                this.$nextTick(() => {
                    this.util.positionPopupElement(this.containerEl, this.actionsEl)
                })
                return true
            }
            return false
        },
    },
    components: {
        MultiSelectInput
    }
}
</script>

<style lang="scss" scoped>
.user-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;

    &:not(:last-of-type) {
        border-bottom: 1px solid #ccc;
    }

    > label {
        flex: 1;
        display: block;
        text-align: left;
        padding: 0 4px;
        display: flex;
        align-items: center;
        overflow: hidden;

        &.id {
            max-width: 100px;
            padding-left: 20px;
        }

        &.email {
            max-width: 300px;
        }

        &.name {
            max-width: 300px;
        }

        &.groups {
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            :deep(.input-container) {
                border-bottom: none;
            }

            > div {
                width: 100%;
                max-width: 100%;
            }
        }
    }

    > .actions-container {
        position: relative;

        > .toggle {
            > svg {
                height: 24px;
                width: 24px;
                border-radius: 50%;
                padding: 4px;
                color: #666;

                &:hover {
                    background-color: #ccc;
                }
            }
        }
    }
}

.actions-menu {
    border-radius: 8px;
    display: none;
    position: absolute;
    background-color: #fff;
    padding: 16px 8px;
    box-shadow: 0 2px 8px #00000066;
    z-index: 100;

    &.open {
        display: flex;
        flex-direction: column;
    }

    > button {
        width: 100%;
        justify-content: flex-start;

        &:not(:first-of-type) {
            margin-top: 8px;
        }
    }
}
</style>