<template>
    <div class="shift-supervision-wrapper">
        <div class="entry shift-container">
            <div class="entry-header">
                <label class="entry-title">Schichtliste</label>
            </div>

            <div class="content">
                <div class="shift-wrapper">
                    <TextInput label="Suche..." v-model="shiftFilter"/>
                    <div class="shift-list">
                        <div class="shift" v-for="(shift, idx) in filteredShifts" :key="idx">
                            <div class="left">
                                <label>{{ shift.formattedLoginDate }}</label>
                                <label>{{ shift.displayNameStaff }}</label>
                                <label v-if="shift.externalId">Schicht-Nr. {{ shift.externalId }}</label>
                                <label v-else>Schicht-Nr. {{ shift.id }}</label>
                            </div>
                            <div class="left-center">
                                <label v-if="shift.logoutTs">Abgeschlossen</label>
                                <label v-else>Offen</label>
                            </div>
                            <div class="center">
                                <label>Summe Verkäufe:</label>
                                <label>Summe Safebags:</label>
                            </div>
                            <div class="right-center">
                                <label>{{ util.formatCurrency(shift.cartSum) }} €</label>
                                <label>{{ util.formatCurrency(shift.safebagSum) }} €</label>
                            </div>
                            <div class="right">
                                <button class="highlight button-left-part" @click="handlePrintReport(shift.id, true)">
                                    <fa-icon icon="fa-solid fa-print"/>
                                    <label>Schichtabschluss</label>
                                </button>
                                <button class="highlight button-right-part" @click="handlePrintReport(shift.id, false)">
                                    <label>mit Verkäufen</label>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TextInput from '@elements/TextInput.vue'
import axios from 'axios'

export default {
    name: "shift-supervision-page",
    inject: [ 'util', 'session', 'keycloak', 'globalState', 'hws' ],
    data() {
        return {
            shiftFilter: '',
            devices: {},
            users: {},
            shifts: []
        }
    },
    mounted() {
        this.init()
    },
    computed: {
        hwsData() {
            return this.hws.data;
        },
        filteredShifts() {
            let result = this.shifts
            
            if (this.shiftFilter) {
                result = this.shifts?.filter(entry => {
                    const nameFilter    = entry.displayNameStaff.toString().includes(this.shiftFilter?.toLowerCase())
                    const dateFilter    = entry.formattedLoginDate.includes(this.shiftFilter?.toLowerCase())
                    const shiftIdFilter = (entry.id == this.shiftFilter?.toLowerCase()) || (entry.externalId == this.shiftFilter?.toLowerCase())

                    return nameFilter || dateFilter || shiftIdFilter
                }) 
            }
            return result.toReversed() 
        }
    },
    methods: {
        async init() {
            this.devices = await this.getDevicedata()
            this.users = await this.getUserdata()            
            this.shifts = await this.getShifts()
        },
        async handlePrintReport(shiftId, excludeSales) {
            try {
                await this.openReport(shiftId, excludeSales)
            } catch(err) {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            }
        },
        async getDevicedata() {
            let dataUrl = this.util.middleware()
            dataUrl += '/shifts/devices'

            return axios.get(dataUrl)
            .then(response => {
                const result = {}
                response.data.forEach(item => {
                    result[item.id] = item
                })
                return result
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async getUserdata() {
            let dataUrl = this.util.middleware()
            dataUrl += '/shifts/users'

            return axios.get(dataUrl)
            .then(response => {
                const result = {}
                response.data.forEach(item => {                     
                    result[item.staffNo] = item
                })
                return result
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async getShifts() {
            const shift = this.session.shift
            const staffId = shift.staffNr

            let dataUrl = this.util.middleware()
            dataUrl += '/shifts'
            if (this.keycloak?.instance?.permissions?.indexOf('shift-supervisor') < 0) {
                dataUrl += '/staff'
                dataUrl += '/' + staffId
            }
            dataUrl += '/list'

            return axios.get(dataUrl)
            .then(response => {
                let shiftData = response.data
                return shiftData.map(item => {
                    const date = new Date(item.loginTs)
                    const user = this.users[item.staffId]
                    return {
                        ...item,
                        formattedLoginDate: this.util.formatDisplayDate(date),
                        formattedLoginTime: this.util.formatDisplayTime(date),
                        displayNameStaff: (user.firstName + ' ' + user.lastName).trim(),
                        displayNameDevice: this.devices[item.deviceId].name
                    }
                })
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        async openReport(shiftId, excludeSales) {
            let reportUrl = this.util.middleware()
            reportUrl += '/shifts/' + shiftId
            reportUrl += '/report'

            const response = await axios.get(reportUrl, { 
                responseType: 'arraybuffer',
                params: {
                    format: 'img',
                    nosales: excludeSales
                }
            })

            if (this.hwsData.status == 0) {
                const img = new Uint8Array(response.data)
                return this.hws.printImage(img)
            } else {
                if (response.status === 200) {
                    const file    = new File([response.data], 'Schicht', {type: response.headers?.['content-type']})
                    const url     = URL.createObjectURL(file)

                    window.open(url)
                }
            }
        },
    },
    components: {
        TextInput
    }
}
</script>

<style scoped lang="scss">
.shift-supervision-wrapper {
    display: flex;
    justify-content: center;

    > .shift-container {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 8px #00000022;
        text-align: left;
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 70%;
        margin-right: 8px;

        h2 {
            margin: 0;
        }

        > .entry-header {
            padding: 8px;
            padding-bottom: 0;
            display: flex;

            > .entry-title {
                margin: 8px;
                font-weight: bold;
                font-size: 2em;
            }
        }

        > .content {
            padding: 0 16px 16px 16px;
            flex: 1;

            > .actions {
                margin-top: 20px;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}

.shift-container {
    > .content {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        > .shift-info {
            display: flex;
            justify-content: space-between;
            > .info {
                margin: 8px 0;
                font-size: 1.5em;
                color: #999;
            }
        }

        > .shift-wrapper {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow: hidden;
            border-top: 6px double #ccc;
            border-bottom: 6px double #ccc;

            > .shift-list {
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                padding: 0 8px;

                > .shift {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: fit-content;
                    padding: 8px 0;

                    &:not(:last-of-type) {
                        border-bottom: 1px solid #ccc;
                    }

                    > div {
                        flex: 1;

                        &.left {
                            display: flex;
                            flex-direction: column;
                            flex: 2;
                        }

                        &.left-center {
                            display: flex;
                            flex-direction: column;
                            font-size: 1.2em;
                            max-width: 200px;
                            text-align: center;
                            flex: 2;
                        }

                        &.center {
                            display: flex;
                            flex-direction: column;
                            font-size: 1.2em;
                            max-width: 200px;
                            text-align: right;
                            flex: 2;
                        }

                        &.right-center {
                            display: flex;
                            flex-direction: column;
                            font-size: 1.2em;
                            max-width: 200px;
                            text-align: right;
                            flex: 2;
                        }

                        &.right {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            flex: 3;

                            > .button-left-part {
                                border-radius: 4px 0px 0px 4px;
                                margin-right: 1px;
                                padding-right: 0px;
                            }

                            > .button-right-part {
                                border-radius: 0px 4px 4px 0px;
                                margin-left: 0px;
                                padding-left: 0px;
                            }
                        }

                    }
                }
            }
        }
    }
}

.print-report {
    $pdf-height: 750px;

    :deep(.content) {
        width: 750px;
        height: $pdf-height;
    }

    .pdf-frame {
        height: $pdf-height;
        width: 100%;
    }
}
</style>