class Session {
    _storageKey
    _payload

    constructor(storageKey = 'psp-session') {
        this._storageKey = storageKey

        // try to read a payload
        const payload = this._read()

        if (!payload) {
            // if there is no previous session
            // create a new payload and save it
            this._payload = new SessionPayload()
            this._write(this._payload)
        } else {
            // otherwise use the existing one
            this._payload = payload
        }
    }

    reinit(authTime) {
        this._destroy()
        this._payload = new SessionPayload()
        this._payload.authTime = authTime
        this._write(this._payload)
    }

    _destroy() {
        localStorage.removeItem(this._storageKey)
    }

    get payload() {
        return this._read()
    }

    get hasOpenShift() {
        const currentShift = this._payload.shift
        const shiftOpen = !!currentShift.staffNr && !!currentShift.location && !!currentShift.device
        return shiftOpen
    }

    get shift() {
        return this._payload.shift
    }

    set shift(shift) {
        this._payload.shift = shift
        this._write(this._payload)
    }

    get authTime() {
        return this._payload.authTime
    }

    set authTime(authTime) {
        this._payload.authTime = authTime
        this._write(this._payload)
    }
    
    get shoppingCart() {
        return this._payload.shoppingCart
    }

    set shoppingCart(shoppingCart) {
        this._payload.shoppingCart = shoppingCart
        this._write(this._payload)
    }

    addToShoppingCart(item) {
        this._payload.shoppingCart.push(item)
        const cart = this._payload.shoppingCart.map((item, idx) => {
            return {...item, idx}
        })
        this._payload.shoppingCart = cart
        this._write(this._payload)
    }
    
    removeFromShoppingCart(item) {
        const cartItem = this._payload.shoppingCart.find(entry => entry.idx === item.idx)
        const cartItemIdx = this._payload.shoppingCart.indexOf(cartItem)
        if (cartItem.amount > 1) {
            cartItem.amount--
        }
        else {
            this._payload.shoppingCart.splice(cartItemIdx, 1)
        }
        this._write(this._payload)
    }

    clearShoppingCart() {
        this._payload.shoppingCart = []
        this._write(this._payload)
    }

    _write(payload) {
        const payloadString = JSON.stringify(payload)
        localStorage.setItem(this._storageKey, payloadString)
    }

    _read() {
        const payloadString = localStorage.getItem(this._storageKey)
        if (!payloadString) {
            return undefined
        }
        return JSON.parse(payloadString)
    }
}

class SessionPayload {
    authTime
    shift = new Shift()
    shoppingCart = [] // of CartItem
}

class Shift {
    location
    device
    staffNr
    id
}

class CartItem {
    amount
    productId
    productOrgId
    productName
    vehicleType
    vehicleId
    price
    info
    originalProduct
    direction
    licensePlates
    subCompany
    driverName
    immediateUse
    saveSylt
    miscellaneous
}

export default Session

export {
    SessionPayload,
    Shift,
    CartItem
}