<template>
    <div class="shopping-cart-container" >
        <div class="shopping-cart-wrapper" >
            <div class="customer-container">
                <div v-if="!withCustomer" class="customer-wrapper no-customer">
                    <div class="header">
                        <label class="title">Kunde</label>
                    </div>

                    <div class="options">
                        <div class="search-customer">
                            <a class="action" @click="handleSearchCustomer">
                                <fa-icon icon="fa-solid fa-search"/>
                                <label>Kunde suchen</label>
                            </a>
                        </div>

                        <div class="add-customer">
                            <a class="action" @click="handleCreateCustomer">
                                <fa-icon icon="fa-solid fa-plus"/>
                                <label>Kunde neu anlegen</label>
                            </a>
                        </div>
                    </div>
                </div>
                <div v-else class="customer-wrapper">
                    <CustomerDisplay :customer="this.globalState.customer" @close="handleCloseCustomer" @save="this.globalState.customer = $event"/>
                </div>
            </div>

            <div>
                <div class="booking-container">
                    <div class="booking-item-container">
                        <DropDownInput class="left" label="Buchungsart" v-model="selectedBookingType" :optionList="bookingTypeOptions" :defaultOption="false" :disabled="selectedPaymentMethod === PAYMENT_METHOD.EC"/>
                    </div>
                    <div class="booking-item-container">
                        <TextInput class="left" label="TicketID" v-model="ticketId"  :disabled="ecPaymentMode"/>
                        <CheckboxInput class="right" v-model="disableTicket" text="Ticket sperren" v-if="currentTicket && !currentTicket.disabledDate && (bookingType === BOOKING_TYPES.REFUND)"  :disabled="ecPaymentMode"/>
                    </div>
                    <div class="booking-item-container">
                        <DropDownInput v-if="bookingType === BOOKING_TYPES.FEE" class="center" label="Grund" v-model="selectedFeeReason" :optionList="feeReasonOptions" :defaultOption="false"  :disabled="ecPaymentMode"/>
                        <DropDownInput v-if="bookingType === BOOKING_TYPES.REFUND" class="center" label="Grund" v-model="selectedRefundReason" :optionList="refundReasonOptions" :defaultOption="false"  :disabled="ecPaymentMode"/>
                    </div>
                    <div class="booking-item-container">
                        <TextInput class="center" label="Anmerkung" v-model="bookingReason" :disabled="ecPaymentMode"/>
                    </div>
                    <div class="booking-item-container">
                    </div>
                    <div class="booking-item-container">
                        <TextInput class="left" label="Betrag" @focus="$event.target.select()" v-model="inputBookingSum" :error="bookingSumIsInvalid" errorText="Ungültiges Format" input-id="booking-sum"  :disabled="ecPaymentMode"/>
                        <DropDownInput class="right" label="Steuer (Betrag)" v-model="selectedBookingSumTaxRate" :optionList="bookingTaxRateOptions" :defaultOption="false"  :disabled="ecPaymentMode"/>
                    </div>
                    <div class="booking-item-container">
                        <DropDownInput class="left" label="Entgelt" v-if="bookingType == BOOKING_TYPES.REFUND" v-model="selectedBookingFee" :optionList="bookingFeeOptions" :defaultOption="false"/>
                    </div>
                    <div class="booking-item-container" v-if="currentTicket">
                        <hr class="center">
                    </div>
                    <div class="booking-item-container" v-if="currentTicket">
                        <h1 class="left">{{ currentTicket.ticketName }}</h1>
                        <h2 class="right">{{ util.formatCurrency(currentTicket.price) }}</h2>
                    </div>
                    <div class="booking-item-container" v-if="currentTicket">
                        <label class="center">{{ currentTicket.ticketInfo }}</label>
                    </div>
                    <div class="booking-item-container" v-if="currentTicket">
                        <label class="center warning" v-if="currentTicket.disabledDate">Das ausgewählte Ticket ist gesperrt.</label>
                        <label class="center" v-else><i>{{ this.currentTicket.remainingTrips }} Fahrten verbleibend</i></label>
                    </div>
                    <div class="price-container">
                        <label>Gesamt</label>
                        <label>{{ util.formatCurrency(paymentSum) }} €</label>
                    </div>
                </div>
            </div>

            <div class="payment-container">
                <div class="payment-wrapper">
                    <div class="payment-content">
                        <div class="payment-method-selector-wrapper">
                            <input type="radio" id="direct-payment" name="payment-type" :value="PAYMENT_METHOD.CASH" v-model="selectedPaymentMethod" :disabled="ecPaymentMode"/>
                            <label for="direct-payment" :class="{ 'selected' : selectedPaymentMethod === PAYMENT_METHOD.CASH }" :disabled="ecPaymentMode">
                                <fa-icon icon="fa-solid fa-money-bill-1-wave" />
                                <span>Barzahlung</span>
                            </label>

                            <input :disabled="selectedBookingType.displayid!==BOOKING_TYPES.FEE" type="radio" id="ec-payment" name="payment-type" :value="PAYMENT_METHOD.EC" v-model="selectedPaymentMethod"/>
                            <label :disabled="selectedBookingType.displayid!==BOOKING_TYPES.FEE" for="ec-payment" :class="{ 'selected' : selectedPaymentMethod === PAYMENT_METHOD.EC }">
                                <fa-icon icon="fa-solid fa-credit-card" />
                                <span>Karte</span>
                            </label>

                            <input :disabled="!savedMethodAllowed || ecPaymentMode" type="radio" id="saved-payment" name="payment-type" :value="PAYMENT_METHOD.SAVED" v-model="selectedPaymentMethod"/>
                            <label title="Im Kunden hinterlegte Bezahlart. Nur verfügabr wenn ein Kunde entweder 'Lastschrift' oder 'Rechnung' als Bezahlart gesetzt hat." :disabled="!savedMethodAllowed || ecPaymentMode" for="saved-payment" :class="{ 'selected' : selectedPaymentMethod === PAYMENT_METHOD.SAVED }">
                                <fa-icon icon="fa-solid fa-receipt" />
                                <span>{{ withCustomer ? getBackendPaymentMethodDisplay(this.customerBackendPaymentMethod) : 'Hinterlegte Bezahlart' }}</span>
                            </label>
                        </div>

                        <div class="cash-payment-container" v-if="(selectedPaymentMethod === PAYMENT_METHOD.CASH) && (bookingType === BOOKING_TYPES.FEE)">
                            <ChangeCalculator :sumInCents="paymentSum" @valid="cashPaymentValid = $event"/>
                            <CheckboxInput class="change-confirmation" v-model="confirmChange" text="Rückgeld gegeben"/>
                        </div>

                        <div class="cash-payout-container" v-if="(selectedPaymentMethod === PAYMENT_METHOD.CASH) && (bookingType === BOOKING_TYPES.REFUND)">
                            <label class="cash-payout-item">Ausgabesumme {{ util.formatCurrency(paymentSum) }} €</label>
                        </div>

                        <div class="ec-payment-container" v-if="selectedPaymentMethod === PAYMENT_METHOD.EC && ecPaymentMode">
                            <label class="info">Bezahlung mit EC-Karte gestartet</label>
                            <label class="info">Bitte beachten Sie die Anweisungen auf dem EC-Terminal</label>

                            <label class="status">Aktueller Status:</label>
                            <h2>{{ hws.data.currentPaymentStatus }}</h2>
                        </div>

                        <div class="saved-payment-container" v-if="selectedPaymentMethod === PAYMENT_METHOD.SAVED">
                            <div class="saved-payment-wrapper">
                                <label>Im Kunden hinterlegte Bezahlart:</label>
                                <h2>{{ this.getBackendPaymentMethodDisplay(this.customerBackendPaymentMethod) }}</h2>
                            </div>
                        </div>
                    </div>

                    <div class="actions">
                        <button class="highlight pay" v-if="selectedPaymentMethod !== PAYMENT_METHOD.EC" :disabled="!canPay" @click="showCommitBooking = true">
                            <fa-icon icon="fa-regular fa-money-bill-1"/>
                            <label>Bezahlen</label>
                        </button>
                        <button  v-else class="highlight pay" :disabled="!canPay" @click="ecPaymentMode = !ecPaymentMode">
                            <fa-icon icon="fa-regular fa-money-bill-1"/>
                            <label>{{ ecPaymentMode? `Abbrechen` : `Bezahlen` }}</label>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <PopUp :open="showSearch" title="Kundensuche" @close="showSearch = false">
            <CustomerSearch ref="customerSearch" @result="handleCustomerSearchResult"/>

            <template v-slot:actions>
                <!-- no buttons placeholder -->
                <label></label>
            </template>
        </PopUp>

        <PopUp :open="showPrintError" title="Fehler" @close="showPrintError = false">
            <label>Der Druckauftrag konnte nicht durchgeführt werden:</label>
            <br />
            <label><b>{{ lastPrintErrorMessage }}</b></label>

            <template v-slot:actions>
                <!-- no buttons placeholder -->
                <label></label>
            </template>
        </PopUp>

        <PopUp :open="showCommitBooking" title="Buchung durchführen?" @close="saveBooking" submitLabel="Ja" cancelLabel="Nein">
            <label v-if="ticketId && disableTicket">Durch die Buchung wird das angegebene Ticket geperrt! </label>
            <label>Wollen Sie die Buchung wirklich speichern?</label>
        </PopUp>
    </div>
</template>

<script>
import PopUp from '@elements/PopUp.vue'
import CustomerDisplay from '@components/CustomerDisplay.vue'
import CustomerSearch from '@components/CustomerSearch.vue'
import DropDownInput from '@elements/DropDownInput.vue'
import TextInput from '@elements/TextInput.vue'
import CheckboxInput from '@components/elements/CheckboxInput.vue'

import axios from 'axios'
import ChangeCalculator from '@components/ChangeCalculator.vue'

const PAYMENT_METHOD = {
    CASH:  0,
    EC:    1,
    SAVED: 2
}

const BACKEND_PAYMENT_METHOD = {
    TERMINAL:     11,
    CASH:         22,
    INVOICE:      25,
    DIRECT_DEBIT: 26
}

const TOWS_PAYMENT_METHODS = {
    UNDEFINED:                 'UNDEFINED',
    DIRECT_DEBIT:              'DIRECT_DEBIT',
    INVOICE:                   'INVOICE',
    CASH:                      'CASH',
    EXTERNAL_SERVICE_PROVIDER: 'EXTERNAL_SERVICE_PROVIDER'
}

const BOOKING_TYPES = {
    FEE:         'FEE',
    REFUND:      'REFUND'
}

const BOOKING_TYPE_OPTIONS = {
    FEE:         {displayid: BOOKING_TYPES.FEE, displayname: 'Nacherhebung'},
    REFUND:      {displayid: BOOKING_TYPES.REFUND, displayname: 'Erstattung'}
}

const FEE_REASONS = {
    OTHER:                  'OTHER',
    WRONG_TARIFF:           'WRONG_TARIFF',
    DISCOUNT_PRICE_FEE:     'DISCOUNT_PRICE_FEE',
    WEIGHT_ABOVE_3_TONS:    'WEIGHT_ABOVE_3_TONS'
}

const FEE_REASON_OPTIONS = {
    OTHER:                  {displayid: FEE_REASONS.OTHER, displayname: 'Sonstige'},
    WRONG_TARIFF:           {displayid: FEE_REASONS.WRONG_TARIFF, displayname: 'Falscher Tarif'},
    DISCOUNT_PRICE_FEE:     {displayid: FEE_REASONS.DISCOUNT_PRICE_FEE, displayname: 'Sparpreis Nacherhebung'},
    WEIGHT_ABOVE_3_TONS:    {displayid: FEE_REASONS.WEIGHT_ABOVE_3_TONS, displayname: 'Gewicht über 3 Tonnen'}
}


const REFUND_REASONS = {
    OTHER:                  'OTHER',
    WRONG_TARIFF:           'WRONG_TARIFF',
    SALES_ERROR:            'SALES_ERROR',
    UNUSED_RETURN_TRIP:     'UNUSED_RETURN_TRIP',
    VERIFICATION_MISSING:   'VERIFICATION_MISSING',
    PASSENGER_RIGHTS:       'PASSENGER_RIGHTS'
}

const REFUND_REASON_OPTIONS = {
    OTHER:                  {displayid: REFUND_REASONS.OTHER, displayname: 'Sonstige'},
    WRONG_TARIFF:           {displayid: REFUND_REASONS.WRONG_TARIFF, displayname: 'Falscher Tarif'},
    SALES_ERROR:            {displayid: REFUND_REASONS.SALES_ERROR, displayname: 'Verkaufsfehler'},
    UNUSED_RETURN_TRIP:     {displayid: REFUND_REASONS.UNUSED_RETURN_TRIP, displayname: 'Zur Rückfahrt nicht genutzt'},
    VERIFICATION_MISSING:   {displayid: REFUND_REASONS.VERIFICATION_MISSING, displayname: 'Besondere Nachweise fehlten'},
    PASSENGER_RIGHTS:       {displayid: REFUND_REASONS.PASSENGER_RIGHTS, displayname: 'FGR - Entschädigung'}
}

export default {
    name: "shopping-cart-page",
    inject: [ 'hws', 'util', 'session', 'globalState' ],
    data() {
        return {
            PAYMENT_METHOD,
            BACKEND_PAYMENT_METHOD,
            TOWS_PAYMENT_METHODS,
            BOOKING_TYPES,
            editMode: false,
            ecPaymentMode: false,
            showSearch: false,
            showPrintError: false,
            lastPrintErrorMessage: '',
            showCommitBooking: false,
            selectedPaymentMethod: 0,
            cashPaymentValid: false,
            confirmChange: false,
            withCustomer: false,

            selectedBookingType: BOOKING_TYPE_OPTIONS.REFUND,
            bookingTypeOptions: Object.values(BOOKING_TYPE_OPTIONS),
            bookingFeeOptions: Object.values(this.globalState.bookkeepingConfig.bookingFees),
            bookingTaxRateOptions: Object.values(this.globalState.bookkeepingConfig.bookingTaxes),

            selectedFeeReason: FEE_REASON_OPTIONS.OTHER,
            feeReasonOptions: Object.values(FEE_REASON_OPTIONS),
            selectedRefundReason: REFUND_REASON_OPTIONS.OTHER,
            refundReasonOptions: Object.values(REFUND_REASON_OPTIONS),

            selectedBookingFee: undefined,
            selectedBookingSumTaxRate: undefined,
            selectedBookingFeeTaxRate: undefined,
            ticketId: '',
            currentTicket: undefined,
            disableTicket: false,
            bookingReason: '',
            inputBookingSum: '0,00'
        }
    },
    mounted() {
        if (this.globalState.customer) {
            this.withCustomer = true
        }
        this.handleCustomerPaymentMethod(this.globalState.customer)

        const type = BOOKING_TYPE_OPTIONS[this.$route.query.type]
        if (type) {
            this.selectedBookingType = type
        }

        const ticketId = this.$route.query.ticketId
        if (ticketId) {
            this.ticketId = ticketId
        }

        this.selectedBookingFee        = this.bookingFeeOptions    .find(item => item.defaultFee)
        this.selectedBookingSumTaxRate = this.bookingTaxRateOptions.find(item => item.defaultBookingTax)
        this.selectedBookingFeeTaxRate = this.bookingTaxRateOptions.find(item => item.defaultFeeTax)

        // select the bookingAmount input field
        document.getElementById('input-booking-sum').select()
    },
    computed: {
        bookingType() {
            return this.selectedBookingType?.displayid
        },
        bookingSumTaxRate() {
            return this.selectedBookingSumTaxRate?.displayid
        },
        bookingFeeTaxRate() {
            let result = this.selectedBookingFeeTaxRate?.displayid
            return result ? result : ''
        },
        bookingSum() {
            const sum = this.util.convertCurrencyStringToCents(this.inputBookingSum)
            if (sum === false) {
                return 0
            }
            return sum
        },
        bookingSumIsInvalid() {
            const sum = this.util.convertCurrencyStringToCents(this.inputBookingSum)
            return sum === false
        },
        bookingFee() {
            const result = this.globalState.bookkeepingConfig.bookingFees[this.selectedBookingFee?.displayid]?.feeAmount
            return result ? result : 0
        },
        paymentSum() {
            if (this.bookingType === BOOKING_TYPES.REFUND) {
                return this.bookingSum - this.bookingFee
            } else {
                return this.bookingSum
            }
        },
        savedMethodAllowed() {
            // saved method is only allowed if there is a customer and the customer
            // has either invoice or direct-debit configured
            const paymentMethodAllowed = [ BACKEND_PAYMENT_METHOD.DIRECT_DEBIT, BACKEND_PAYMENT_METHOD.INVOICE ].includes(this.customerBackendPaymentMethod)
            return this.withCustomer && paymentMethodAllowed
        },
        customerBackendPaymentMethod() {
            switch(this.globalState.customer?.paymentMethod) {
                case TOWS_PAYMENT_METHODS.DIRECT_DEBIT:
                    return BACKEND_PAYMENT_METHOD.DIRECT_DEBIT
                case TOWS_PAYMENT_METHODS.INVOICE:
                    return BACKEND_PAYMENT_METHOD.INVOICE
                case TOWS_PAYMENT_METHODS.CASH:
                    return BACKEND_PAYMENT_METHOD.CASH
                case TOWS_PAYMENT_METHODS.EXTERNAL_SERVICE_PROVIDER:
                case TOWS_PAYMENT_METHODS.UNDEFINED:
                default:
                    return undefined
            }
        },
        paymentMethod() {
            switch(this.selectedPaymentMethod) {
                case PAYMENT_METHOD.CASH:
                    return BACKEND_PAYMENT_METHOD.CASH
                case PAYMENT_METHOD.EC:
                    return BACKEND_PAYMENT_METHOD.TERMINAL
                case PAYMENT_METHOD.SAVED:
                    return this.customerBackendPaymentMethod
                default:
                    return undefined
            }
        },
        canPay() {
            if (this.paymentSum <= 0) {
                return false
            }

            if ((this.paymentMethod === BACKEND_PAYMENT_METHOD.CASH) && (this.bookingType === BOOKING_TYPES.FEE)) {
                return this.cashPaymentValid && this.confirmChange
            }

            return !!this.bookingType && (this.bookingSumTaxRate != undefined) && (this.bookingFeeTaxRate != undefined)
        },
        hwsData() {
            return this.hws.data
        },
        shift() {
            return this.session.shift
        },
        locationId() {
            return this.shift.location?.displayid
        },
        deviceId() {
            return this.shift.device?.displayid
        },
    },
    methods: {
        resetForm() {
            this.inputBookingSum = '0,00'
            this.ticketId        = ''
            this.disableTicket   = false
            this.bookingReason   = ''
            this.confirmChange   = false
            this.paySum          = '0,00'
            this.$router.replace({'query': null})
        },
        handleCustomerPaymentMethod(customer) {
            if (customer && this.savedMethodAllowed) {
                this.selectedPaymentMethod = PAYMENT_METHOD.SAVED
            } else {
                this.selectedPaymentMethod = PAYMENT_METHOD.CASH
            }
        },
        getBackendPaymentMethodDisplay(method) {
            switch(method) {
                case BACKEND_PAYMENT_METHOD.DIRECT_DEBIT:
                    return 'Lastschrift'
                case BACKEND_PAYMENT_METHOD.INVOICE:
                    return 'Rechnung'
                default:
                    return undefined
            }
        },
        async tryLoadTicket() {
            let dataUrl = this.util.middleware()
            dataUrl += '/ticketing/ticket'
            dataUrl += '/' + this.ticketId

            return axios.get(dataUrl)
            .then((response) => {
                this.currentTicket = response.data
                this.currentTicket.remainingTrips = (this.currentTicket.outwardTrips ?? 0) + (this.currentTicket.returnTrips ?? 0)
            })
            .catch(() => {
                this.currentTicket = undefined
            })
        },
        async submitBooking(paymentMethod) {
            // Base Data
            const shift = this.session.shift
            const deviceId = shift.device.displayid
            const staffId = shift.staffNr
            let reason

            // Build Endpoint
            let dataUrl = this.util.middleware()
            dataUrl += '/bookings/devices/' + deviceId
            dataUrl += '/staff/' + staffId
            switch (this.bookingType) {
                case BOOKING_TYPES.FEE :
                    dataUrl += '/additional-fee'
                    reason = this.selectedFeeReason.displayid
                break
                case BOOKING_TYPES.REFUND :
                    dataUrl += '/refund'
                    reason = this.selectedRefundReason.displayid
                break
            }

            const data = {
                customerId: this.globalState.customer?.uuid,
                ticketId: this.ticketId,
                paymentMethod: paymentMethod,
                timestamp: Date.now(),
                fee: this.selectedBookingFee?.displayid,
                feeTaxrate: this.bookingFeeTaxRate,
                sum: this.bookingSum,
                sumTaxrate: this.bookingSumTaxRate,
                disableTicket: this.disableTicket,
                reason: reason,
                text: this.bookingReason
            }

            this.resetForm()

            // Post Data
            const response = await axios.post(dataUrl, data,{
                responseType: 'arraybuffer'
            })

            // Print
            if (this.hwsData.status == 0) {
                const img = new Uint8Array(response.data)
                // Print the receipt twice (once for the company, once for the customer)
                await this.hws.printImage(img)
                return this.hws.printImage(img)
            } else {
                if (response.status === 200) {
                    const file = new File([response.data], 'Quittung.png', {type: 'image/png'})
                    const url  = URL.createObjectURL(file)

                    window.open(url)
                }
            }
        },
        async saveBooking(doPay) {
            this.showCommitBooking = false
            if (doPay) {
                if (this.selectedPaymentMethod === PAYMENT_METHOD.CASH) {
                    await this.submitBooking(BACKEND_PAYMENT_METHOD.CASH)
                } else if (this.selectedPaymentMethod === PAYMENT_METHOD.SAVED) {
                    await this.submitBooking(this.customerBackendPaymentMethod)
                }
            }
        },
        async initEcPayment() {
            return await this.hws.initPayment(this.paymentSum)
            .then(async() => {
                await this.submitBooking(BACKEND_PAYMENT_METHOD.TERMINAL)
                this.ecPaymentMode = false
            })
            .catch(err => {
                this.$toast.error(err.message || err.errorMessage || err)
                this.ecPaymentMode = false
            })
        },  
        cancelEcPayment() {
            this.hws.cancelCurrentPayment()
        },
        handleSearchCustomer() {
            this.showSearch = true
        },
        handleCreateCustomer() {
            this.withCustomer = true
            this.editMode     = true
        },
        handleCustomerSearchResult(customer) {
            Object.assign(this.globalState, {customer})
            this.showSearch   = false
            this.withCustomer = true
        },
        handleCloseCustomer() {
            this.withCustomer = false
            this.globalState.customer = undefined
        },
        removeCartItem(item) {
            this.session.removeFromShoppingCart(item)
        }
    },
    watch: {
        selectedBookingType() {
            this.inputBookingSum = '0,00'
        },
        ticketId(newTicketId) {
            if (newTicketId.length >= 10) {
                this.tryLoadTicket()
            } else {
                this.currentTicket = undefined
            }
        },
        'globalState.customer'(customer) {
            this.withCustomer = !!this.globalState.customer
            this.handleCustomerPaymentMethod(customer)
        },
        ecPaymentMode(newValue) {
            if (newValue === true) {
                this.initEcPayment()
            } else {
                this.cancelEcPayment()
            }
        },
    },
    components: {
        PopUp,
        TextInput,
        DropDownInput,
        CustomerDisplay,
        CustomerSearch,
        ChangeCalculator,
        CheckboxInput
    }
}
</script>

<style lang="scss" scoped>

.shopping-cart-container {
    width: 100%;

    > .shopping-cart-wrapper {
        height: 100%;
        display: flex;
        justify-content: space-between;

        > div {
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            max-height: 100%;
            padding: 0 8px;

            &.payment-container,
            &.customer-container {
                max-width: 520px;
            }

            > div {
                background-color: #fff;
                border-radius: 8px;
                box-shadow: 0 2px 8px #00000022;
                height: 100%;
                padding: 16px;
            }
        }
    }
}

.payment-wrapper {
    display: flex;
    flex-direction: column;

    > .payment-content {
        flex: 1;
        display: flex;
        flex-direction: column;

        > .payment-method-selector-wrapper {
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            > label {
                flex: 1;
                margin: 0 4px;
                box-shadow: 0 2px 8px #00000022;
                padding: 8px 4px;
                border-radius: 8px;
                width: 120px;
                height: 65px;
                display: flex;
                // flex-direction: column;
                justify-content: center;
                align-items: center;

                &.selected {
                    background-color: #63a615;
                    color: #fff;
                }

                &:hover:not([disabled="true"]) {
                    &:not(.selected) {
                        background-color: #00000022;
                    }

                    &, :deep(*) {
                        cursor: pointer;
                    }
                }

                &[disabled="true"] {
                    opacity: 0.5;
                }

                > svg {
                    height: 20px;
                }

                > span {
                    margin-left: 8px;
                    font-size: 1.5em;
                    font-weight: bold;
                }
            }

            > input {
                display: none;
            }
        }

        > .cash-payment-container {
            margin-top: 20px;

            > .change-confirmation {
                width: 50%;
                margin: 0 auto;
            }
        }

        > .cash-payout-container {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            > .cash-payout-item {
                font-size: 2em;
                font-weight: bold;
            }
        }

        > .ec-payment-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            > .info,
            > .status {
                font-size: 1.5em;

                &.status {
                    margin-top: 20px;
                }
            }

            > h2 {
                margin: 0;
            }
        }

        > .saved-payment-container {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            > .saved-payment-wrapper {
                font-size: 1.5em;
            }
        }
    }

    > .actions {
        > .pay {
            width: 100%;
            height: 50px;
            margin: 0;
        }
    }
}

.customer-wrapper {
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 0 !important;

    &.no-customer {
        padding: 16px !important;
    }

    > .header {
        padding-bottom: 0;
        display: flex;

        > .title {
            margin: 8px;
            font-weight: bold;
            font-size: 2em;
        }
    }

    > .actions {
        margin-top: auto;
        display: flex;
        justify-content: flex-end;

        > button {
            margin-left: 16px;
        }
    }

    > .options {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > .search-customer,
        > .add-customer {
            width: 75%;
            height: 65px;
            margin: 0 auto;
            margin-top: 20px;
            box-shadow: 0 2px 8px #00000022;
            border-radius: 8px;

            &.search-customer {
                color: #fff;
                //background: #26a;
                background: #ec0016;
            }

            &:hover {
                &,
                * {
                    cursor: pointer;
                }

                background-color: #00000022;

                &.search-customer {
                    //background-color: #2266aacc;
                    background-color: #5a6268;
                }

            }

            > .action {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                > svg {
                    height: 30px;
                    width: 30px;
                }

                > label {
                    font-size: 1.5em;
                    font-weight: bold;
                    margin-left: 12px;
                }
            }
        }
    }
}

.booking-container {
    display: flex;
    flex-direction: column;

    > .booking-item-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        font-size: 1em;

        > label {
            margin-top: 10px;
            font-size: 1.2em;
        }

        > .left {
            text-align: left;
            width: 45%;
        }
        > .right {
            text-align: right;
            width: 45%;
        }
        > .center {
            text-align: left;
            width: 100%;
        }
        > .warning {
            color: red;
            font-weight: bold;
        }
    }

    > .price-container {
        border-top: 6px double #012345;
        padding: 8px 4px;
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        font-size: 2em;
        font-weight: bold;
    }

    > .actions {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;

        > button {
            margin-left: 16px;
        }
    }
}

</style>