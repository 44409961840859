<template>
    <div class="cancellation-container">
        <div class="cancellation-wrapper">
            <div class="refund-content">
                <div class="refund-method-selector-wrapper">
                    <input type="radio" id="cash-refund" name="payment-type" :value="PAYMENT_METHOD.CASH" :disabled="paymentMethod(ticket) != PAYMENT_METHOD.CASH"/>
                    <label for="cash-refund" :class="{ 'selected': paymentMethod(ticket) === PAYMENT_METHOD.CASH }" :disabled="paymentMethod(ticket) != PAYMENT_METHOD.CASH">
                        <fa-icon icon="fa-solid fa-money-bill-1-wave" />
                        <span>Bar</span>
                    </label>

                    <input type="radio" id="terminal-refund" name="payment-type" :value="PAYMENT_METHOD.TERMINAL" :disabled="paymentMethod(ticket) != PAYMENT_METHOD.TERMINAL"/>
                    <label for="terminal-refund" :class="{ 'selected': paymentMethod(ticket) === PAYMENT_METHOD.TERMINAL }" :disabled="paymentMethod(ticket) != PAYMENT_METHOD.TERMINAL">
                        <fa-icon icon="fa-solid fa-credit-card" />
                        <span>Karte</span>
                    </label>

                    <input type="radio" id="external-refund" name="payment-type" :value="PAYMENT_METHOD.EXTERNAL" :disabled="paymentMethod(ticket) != PAYMENT_METHOD.EXTERNAL"/>
                    <label for="external-refund" :class="{ 'selected': paymentMethod(ticket) === PAYMENT_METHOD.EXTERNAL }" :disabled="paymentMethod(ticket) != PAYMENT_METHOD.EXTERNAL">
                        <fa-icon icon="fa-solid fa-receipt"/>
                        <span>Gutschrift</span>
                    </label>
                </div>
                
                <div class="payment-type-container">                                                                
                    <label class="info">Die Erstattung kann immer nur auf das ursprüngliche Zahlungsmittel durchgeführt werden</label>
                    <div class="payment-type-wrapper">
                        <label>Im Ticket hinterlegte Zahlungsmittel:</label>                        
                    </div>
                    <h2>{{ this.util.formatPaymentMethodString(originalPaymentMethod) }}</h2>
                </div>

                <h2 class="refund-calculator">
                    Ticketpreis: <span class="refund-value">{{ this.util.formatCurrency(ticket.price) }} €</span>
                </h2>   
                <h2 class="refund-calculator">
                    Stornogebühr: <span class="refund-value">{{ this.util.formatCurrency(cancellationFee) }} €</span>
                </h2>  
                <h2 class="refund-calculator">
                    Erstattungsbetrag: <span class="refund-value">{{ this.util.formatCurrency(refundSum) }} €</span>
                </h2>  

                <CheckboxInput v-if="this.ticket.cancellationFee > 0" class="without-fee-option" v-model="withoutFee" text="ohne Stornogebühr"/> 

                <div class="cash-payment-container" v-if="paymentMethod(ticket) === PAYMENT_METHOD.CASH">                   
                    <CheckboxInput class="refund-confirmation" v-model="confirmRefund" text="Erstattungsbetrag gegeben"/>
                </div>
                

                <div class="ec-payment-container" v-if="paymentMethod(ticket) === PAYMENT_METHOD.TERMINAL">
                    <label class="info">Erstattung über Karten-Terminal gestartet</label>
                    <label class="info">Bitte beachten Sie die Anweisungen auf dem Karten-Terminal</label>

                    <label class="status">Aktueller Status:</label>
                    <h2>{{ hws.data.currentPaymentStatus }}</h2>
                </div>


            </div>

            <div class="actions">
                <button class="highlight refund" v-if="paymentMethod(ticket) !== PAYMENT_METHOD.TERMINAL" :disabled="!canRefund"
                    @click="handleCancelTicket(ticket)">
                    <fa-icon icon="fa-regular fa-money-bill-1" />
                    <label>Stornieren</label>
                </button>
            </div>
        </div>        
    </div>
</template>

<script>

import CheckboxInput from '@components/elements/CheckboxInput.vue'

const PAYMENT_METHOD = {
    EXTERNAL: 999,
    TERMINAL: 11,
    CASH: 22
}
const EMIT_CANCELLED_TICKET = 'cancelled-ticket'

export default {
    name: 'cancellation-display',
    inject: ['hws', 'util', 'session', 'globalState'],
    emits: [EMIT_CANCELLED_TICKET],
    props: {
        ticket: {
            type: Object,
            required: true
        }     
    },
    data() {
        return {
            PAYMENT_METHOD,
            originalPaymentMethod: 0,
            confirmRefund: false,
            withoutFee: false        
        }
    }, 
    computed: {
        canRefund() {
            if (this.originalPaymentMethod === PAYMENT_METHOD.CASH) {
                return this.confirmRefund
            }

            return true
        },
        refundSum() {
            return this.ticket.price - this.cancellationFee
        },
        cancellationFee() {
            if (this.withoutFee) {
                return 0
            } else {
                return this.ticket.cancellationFee
            }
        }
    },
    methods: {
        handleCancelTicket(ticket) {
            ticket.withoutFee = this.withoutFee
            ticket.cancellationFee = this.cancellationFee
            ticket.refundSum = this.refundSum
            this.$emit(EMIT_CANCELLED_TICKET, ticket)
        },
        paymentMethod(ticket) {
                this.originalPaymentMethod = ticket.paymentMethod
                if (ticket.paymentMethod === PAYMENT_METHOD.TERMINAL || ticket.paymentMethod === PAYMENT_METHOD.CASH) {
                    return ticket.paymentMethod
                } else {
                    return PAYMENT_METHOD.EXTERNAL
                }
        },
        async initEcRefund() {
            return this.hws.initRefund(this.refundSum)
            .then(data => {
                const cancelledTicket = this.ticket
                cancelledTicket.paymentData  = data
                this.handleCancelTicket(cancelledTicket)
            })
            .catch(err => {
                this.$toast.error(err.message || err.errorMessage || err)
            })
        },  
        cancelEcPayment() {
            this.hws.cancelCurrentPayment()
        },                            
    },
    watch: {
        refundSum(newValue) {
            if (this.ticket.paymentMethod === PAYMENT_METHOD.TERMINAL) {
                if (newValue) {
                    this.cancelEcPayment()                
                    this.initEcRefund()                
                }            
            }    
        }
    },  
    components: {
        CheckboxInput
    }
}

</script>
<style lang="scss" scoped>
.cancellation-wrapper {
    display: flex;
    flex-direction: column;

    >.refund-content {
        flex: 1;
        display: flex;
        flex-direction: column;

        >.refund-method-selector-wrapper {
            width: 100%;
            margin: 0 4px;
            display: flex;
            justify-content: space-between;

            >label {
                flex: 1;
                margin: 0 4px;
                box-shadow: 0 2px 8px #00000022;
                padding: 8px 4px;
                border-radius: 8px;
                width: 120px;
                height: 65px;
                display: flex;
                // flex-direction: column;
                justify-content: center;
                align-items: center;

                &.selected {
                    background-color: #63a615;
                    color: #fff;
                }

                &:hover:not([disabled="true"]) {
                    &:not(.selected) {
                        background-color: #00000022;
                    }

                    &,
                    :deep(*) {
                        cursor: pointer;
                    }
                }

                &[disabled="true"] {
                    opacity: 0.5;
                }

                >svg {
                    height: 20px;
                }

                >span {
                    margin-left: 8px;
                    font-size: 1.5em;
                    font-weight: bold;
                }
            }

            >input {
                display: none;
            }
        }
        >.without-fee-option {
                width: 60%;
                margin: 0 auto;
            }  
        >.cash-payment-container {

            >.refund-confirmation {
                width: 60%;
                margin: 0 auto;
            }          
            
        }

        >.ec-payment-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            >.info,
            >.status {
                font-size: 1.5em;

                &.status {
                    margin-top: 20px;
                }
            }

            >h2 {
                margin: 0;
            }
        }

        >.payment-type-container {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 20px;

            >.payment-type-wrapper {
                font-size: 1.5em;
            }
            >.info {
                font-size: 1em;

                &.info {
                    margin-bottom: 20px;
                }
            }            
        }
        > .refund-calculator {
            margin-top: 0px;
            margin-bottom: 10px;
            font-weight: normal;
            text-align: left;
            > .refund-value {
                font-weight: bold;
            }
        }        
    }

    >.actions {
        margin-top: 20px;
        >.refund {
            width: 100%;
            height: 50px;
            margin: 0;
        }
    }
}
</style>