<template>
    <div class="user-management-page-wrapper">
        <div class="left">
            <div class="user-management">
                <div class="entry-header">
                    <label class="entry-title">Nutzer</label>
                    <button class="highlight" @click="openNewUser">
                        <fa-icon icon="fa-solid fa-plus"/>
                        <label>Nutzer Hinzufügen</label>
                    </button>
                </div>

                <div class="entry-content">
                    <div class="filter">
                        <TextInput label="Suche..." v-model="filter.search"/>
                    </div>

                    <div class="user-list-wrapper">
                        <div class="header">
                            <label class="id">Personalnr.</label>
                            <label class="email">Email</label>
                            <label class="name">Name</label>
                            <label class="groups">Gruppen</label>
                            <button :disabled="true"></button>
                        </div>
                        <div class="user-list">
                            <ListUserDepiction
                                v-for="(user, idx) in filteredUsers" 
                                :ref="`user-${idx}`"
                                :key="idx" 
                                :user="user"
                                :group-options="groupOptions"
                                @remove-group="group => removeUserFromGroup(user, group)"
                                @add-group="group => addUserToGroup(user, group)"
                                @open-groups="handleOpenGroups"
                                @open-actions="handleOpenActions"
                                @delete-user="openDeleteUser"
                                @reset-password="openResetPassword"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <PopUp class="new-user-wrapper" @close="handleNewUserClose" :open="showNewUser" title="Neuen Nutzer anlegen" submit-label="Anlegen" cancel-label="Abbrechen">
            <TextInput label="Personalnummer" v-model="newUser.user.staffNo" />
            <TextInput label="Vorname" v-model="newUser.user.firstName" />
            <TextInput label="Nachname" v-model="newUser.user.lastName" />
            <TextInput label="Email" v-model="newUser.user.email" />
            <TextInput label="Temporäres Passwort" type="password" v-model="newUser.password" />
            <TextInput label="Temporäres Passwort wiederholen" type="password" v-model="newUser.passwordConfirmation" :error="newUser.passwordError" errorText="Die angegebenen Passwörter stimmen nicht überein!" />
        </PopUp>

        <PopUp class="delete-user" @close="handleDeleteUserClose" :open="showDeleteUser" title="Nutzer löschen" submit-label="Löschen" cancel-label="Abbrechen">
            <div class="hint">
                <label>Sind Sie sicher, dass Sie den folgenden Nutzer löschen wollen?</label>
            </div>
            <table class="delete-user-table">
                <tr>
                    <td>Personalnr.</td>
                    <td>{{ userToDelete.staffNo }}</td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>{{ userToDelete.email }}</td>
                </tr>
                <tr>
                    <td>Vorname</td>
                    <td>{{ userToDelete.firstName }}</td>
                </tr>
                <tr>
                    <td>Nachname</td>
                    <td>{{ userToDelete.lastName }}</td>
                </tr>
            </table>
        </PopUp>

        <PopUp class="reset-password-wrapper" @close="handleResetPasswordClose" :open="resetPassword.show" title="Nutzer passwort zurücksetzen" submit-label="Passwort zurücksetzen" cancel-label="Abbrechen">
            <div class="hint">
                <label>Sind Sie sicher, dass sie das Passwort des Nutzers zurücksetzen wollen?</label>
                <label>Der Nutzer wird beim nächsten Login aufgefordert ein neues Passwort zu vergeben.</label>
            </div>

            <div class="user-info">
                <label>Nutzer:</label>
                <label>{{ resetPassword.user.email }}</label>
            </div>

            <div class="temporary-password">
                <TextInput label="Temporäres Passwort" type="password" v-model="resetPassword.temporaryPassword"/>
                <TextInput label="Temporäres Passwort wiederholen" type="password" v-model="resetPassword.temporaryPasswordConfirmation" :error="resetPassword.passwordError" errorText="Die angegebenen Passwörter stimmen nicht überein!"/>
            </div>
        </PopUp>
    </div>
</template>

<script>
import axios from 'axios'

import PopUp from '@components/elements/PopUp.vue'
import TextInput from '@elements/TextInput.vue'
import ListUserDepiction from '@components/ListUserDepiction.vue'

export default {
    name: 'user-management-page',
    inject: [ 'util' ],
    data() {
        return {
            filter: {
                search: ''
            },
            groupSelectionOpen: true,
            userList: [],
            groupOptions: [],
            showNewUser: false,
            newUser: {
                user: {
                    staffNo: undefined,
                    firstName: undefined,
                    lastName: undefined,
                    email: undefined
                },
                password: undefined,
                passwordConfirmation: undefined,
                passwordError: false
            },
            resetPassword: {
                show: false,
                user: {},
                temporaryPassword: undefined,
                temporaryPasswordConfirmation: undefined,
                passwordError: false
            },
            userToDelete: {},
            showDeleteUser: false,
        }
    },
    mounted() {
        this.init()
    },
    computed: {
        filteredUsers() {
            return this.userList.filter(user => {
                const searchTerm = this.filter.search?.toLowerCase()

                if (!searchTerm || searchTerm.trim() === '') {
                    return true
                }

                const staffNoMatch   = user.staffNo.includes(searchTerm)
                const emailMatch     = user.email.toLowerCase().includes(searchTerm)
                const firstNameMatch = user.firstName?.toLowerCase().includes(searchTerm) || false
                const lastNameMatch  = user.lastName?.toLowerCase().includes(searchTerm) || false
                const groupMatch     = user.groups.find(entry => entry.displayname.toLowerCase().includes(searchTerm))

                return staffNoMatch || emailMatch || firstNameMatch || lastNameMatch || groupMatch
            })
        }
    },
    methods: {
        handleOpenGroups() {
            // close all other dialogs
            Object.keys(this.$refs).forEach(key => {
                if (key.startsWith('user-')) {
                    const userDisplay = this.$refs[key]
                    const activeUser = userDisplay?.at(0)
                    const groupSelect = activeUser?.$refs['group-select']
                    if (groupSelect) groupSelect.closeOptions()
                }
            })
        },
        handleOpenActions() {
            // close all other dialogs
            Object.keys(this.$refs).filter(key => key.startsWith('user')).forEach(key => {
                const userDisplay = this.$refs[key]
                const activeUser = userDisplay?.at(0)
                if (activeUser) activeUser.actionsOpen = false
            })
        },
        async init() {
            // async calls, we do not need them to wait for each other
            this.getUsers()
            this.getGroups()
        },
        getUsers() {
            let usersUrl = this.util.middleware()
            usersUrl += '/users'
            usersUrl += '?with-groups=1'

            axios.get(usersUrl)
            .then(response => {
                // map user groups to be selectable in the UI
                this.userList = response.data.map(user => {
                    const selectableGroups = user.groups.map(group => this.mapGroupToSelectable(group))
                    user.groups = selectableGroups
                    return user
                })
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        getGroups() {
            let groupsUrl = this.util.middleware()
            groupsUrl += '/users/available-groups'

            axios.get(groupsUrl)
            .then(response => {
                this.groupOptions = response.data.map(group => this.mapGroupToSelectable(group))
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        removeUserFromGroup(user, group) {
            let removeUserUrl = this.util.middleware()
            removeUserUrl += '/users/' + user.id
            removeUserUrl += '/groups/' + group.original.id

            axios.delete(removeUserUrl)
            .then(() => {
                // TODO: load all users/update single user?
                return this.init()
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        addUserToGroup(user, group) {
            let addUserUrl = this.util.middleware()
            addUserUrl += '/users/' + user.id
            addUserUrl += '/groups/' + group.original.id

            axios.put(addUserUrl)
            .then(() => {
                // TODO: load all users/update single user?
                return this.init()
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        mapGroupToSelectable(group) {
            return {
                displayid:   group.id,
                displayname: group.name,
                original:    group
            }
        },
        openNewUser() {
            this.resetNewUser()
            this.showNewUser = true
        },
        handleResetPasswordClose(submit) {
            if (!submit) {
                return this.hideResetPassword()
            }

            const passwordsOk = !!this.resetPassword.temporaryPassword && !!this.resetPassword.temporaryPasswordConfirmation && (this.resetPassword.temporaryPassword === this.resetPassword.temporaryPasswordConfirmation)

            if (!passwordsOk) {
                this.resetPassword.passwordError = true
                return
            }

            this.requestResetUserPassword(this.resetPassword.user, this.resetPassword.temporaryPassword)
            .then(() => {
                this.$toast.info('Passwort erfolgreich zurückgesetzt')
                this.hideResetPassword()
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        hideResetPassword() {
            this.resetPassword.user = {}
            this.resetPassword.show = false
        },
        handleNewUserClose(submit) {
            if (!submit) {
                return this.hideNewUser()
            }

            const passwordsOk = !!this.newUser.password && !!this.newUser.passwordConfirmation && (this.newUser.password === this.newUser.passwordConfirmation)

            if (!passwordsOk) {
                this.newUser.passwordError = true
                return
            }

            this.createUser(this.newUser.user, this.newUser.password)
            .then(() => {
                this.init()
                this.hideNewUser()
            })
            .catch(err => {
                this.$toast.error(err.response?.data?.userMessage || err.message)
            })
        },
        resetNewUser() {
            this.newUser.passwordError = false
            this.newUser = {
                user : {
                    staffNo:   undefined,
                    firstName: undefined,
                    lastName:  undefined,
                    email:     undefined
                },
                password: undefined,
                passwordConfirmation: undefined
            }
        },
        hideNewUser() {
            this.resetNewUser()
            this.showNewUser = false
        },
        async createUser(user, password) {
            let createUrl = this.util.middleware()
            createUrl += '/users'

            const body = {
                user,
                password
            }

            return axios.post(createUrl, body)
        },
        async requestResetUserPassword(user, temporaryPassword) {
            let resetUrl = this.util.middleware()
            resetUrl += '/users/' + user.id
            resetUrl += '/actions/require-new-password'

            const resetBody = {
                temporaryPassword
            }

            return axios.put(resetUrl, resetBody)
        },
        openDeleteUser(user) {
            this.userToDelete   = user
            this.showDeleteUser = true
        },
        openResetPassword(user) {
            this.resetPassword.user = user
            this.resetPassword.show = true
        },
        handleDeleteUserClose(submit) {
            if (submit) {
                this.deleteUser(this.userToDelete)
                .then(() => {
                    this.init()
                    this.hideDeleteUser()
                })
                .catch(err => {
                    this.$toast.error(err.response?.data?.userMessage || err.message)
                })
            } else {
                this.hideDeleteUser()
            }
        },
        async deleteUser(user) {
            let deleteUrl = this.util.middleware()
            deleteUrl += '/users/' + user.id

            return axios.delete(deleteUrl)
        },
        hideDeleteUser() {
            this.userToDelete = {}
            this.showDeleteUser = false
        }
    },
    components: {
        TextInput,
        PopUp,
        ListUserDepiction
    }
}
</script>

<style lang="scss" scoped>
.user-management-page-wrapper {
    display: flex;
    height: calc(100% - 100px);
    padding-bottom: 16px;

    > .left {
        flex: 1;
        height: 100%;

        > div {
            height: 100%;
            background-color: #fff;
            box-shadow: 0 2px 8px #00000022;
            border-radius: 8px;
            min-height: 300px;
        }
    }

    > .new-user-wrapper {
        :deep(.content-wrapper) {
            min-width: 500px;
        }
    }

    > .reset-password-wrapper {
        :deep(.content-wrapper) {
            max-width: 500px;

            .hint {
                text-align: left;
            }

            .user-info {
                margin-top: 8px;

                > label {
                    &:first-of-type {
                        text-align: left;
                    }

                    &:last-of-type {
                        margin-left: 8px;
                        text-align: right;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    > .delete-user {
        :deep(.delete-user-table) {
            margin-top: 20px;
            width: 100%;

            > tr {
                > td:first-of-type {
                    text-align: left;
                }

                > td:last-of-type {
                    font-weight: bold;
                    text-align: right;
                }
            }
        }
    }
}

.left {
    .entry-content {
        height: calc(100% - 58px);
        display: flex;
        flex-direction: column;
        padding: 0 16px 16px 16px;

        > .user-list-wrapper {
            $filter-height: 63px;
            height: calc(100% - $filter-height);
            position: relative;

            > .header {
                border-bottom: 1px solid #064A6C;
                margin: 20px 0 8px 0;
                padding-bottom: 8px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 16px;

                > label {
                    color: #999;
                    font-size: 1.25em;
                    flex: 1;
                    flex-basis: 100%;
                    flex-grow: 0;
                    display: block;
                    text-align: left;
                    padding: 0 4px;

                    &.id {
                        max-width: 100px;
                    }

                    &.email {
                        max-width: 300px;
                    }

                    &.name {
                        max-width: 300px;
                    }
                }

                > button {
                    margin-left: 12px;
                    width: 45px;

                    &:disabled {
                        box-shadow: none;
                    }
                }
            }

            > .user-list {
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                $header-height: 50px;
                height: calc(100% - $header-height);
            }
        }
    }

    .entry-header {
        padding: 8px;
        padding-bottom: 0;
        display: flex;
        justify-content: space-between;

        > .entry-title {
            margin: 8px;
            font-weight: bold;
            font-size: 2em;
        }
    }
}
</style>